import {ReactNode} from 'react';

import {BrandConstantsModel} from '@/entities/brand';
import {useHotjar} from '@/entities/hotjar';
import {useFacebookPixel, useRedditPixel, useSnapchatPixel} from '@/entities/pixels';

export interface Props {
  children: ReactNode;
  constants: BrandConstantsModel;
}

export const AppScripts = (props: Props) => {
  const { children, constants } = props;

  useHotjar(constants?.hotjar_id);
  useFacebookPixel(constants?.facebook_pixel_id);
  useRedditPixel(constants?.reddit_pixel_id);
  useSnapchatPixel(constants?.snapchat_pixel_id);

  return <>{children}</>;
};
