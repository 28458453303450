import {ComponentPropsWithoutRef, useMemo} from 'react';

import {Thumb as PrimitiveThumb} from '@radix-ui/react-slider';

import {cn} from '@/shared/libs/utils';

import {useSliderContext} from '../slider.context';
import './styles.css';

export interface Props extends ComponentPropsWithoutRef<typeof PrimitiveThumb> {
  showValue?: boolean;
}

export const Thumb = (props: Props) => {
  const { className, showValue, ...restProps } = props;

  const { value, defaultValue } = useSliderContext();

  const thumbValue = useMemo(() => {
    if (value && typeof value[0] === 'number') {
      return value[0];
    }

    if (defaultValue && typeof defaultValue[0] === 'number') {
      return defaultValue[0];
    }

    return null;
  }, [value, defaultValue]);

  return (
    <PrimitiveThumb
      className={cn(
        'slider-thumb',
        'size-6 rounded-full transition-all flex items-center justify-center relative',
        // 'shadow border-2 border-slider-thumb  bg-background ring-offset-background',
        'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
        className,
      )}
      {...restProps}
    >
      {showValue && thumbValue && (
        <span className="absolute inline-flex items-center justify-center -bottom-[20px] inset-x-0 text-primary-font font-semibold text-xs text-center">
          {thumbValue}
        </span>
      )}
    </PrimitiveThumb>
  );
};
