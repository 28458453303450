import {PaymentGatewayForm} from '@/features/modals/form/payment-gateway-form';
import {PaymentGatewaySchema} from '@/features/modals/form/payment-gateway-form/payment-gateway-form.controller';

import {cn} from '@/shared/libs/utils';
import {Modal} from '@/shared/ui';

export interface Props {
  onSubmitPayment?: (data: PaymentGatewaySchema) => void;

  onSuccessPayment?: () => void;

  className?: string;
}

export const PaymentGatewayModal = (props: Props) => {
  const { className, onSubmitPayment, ...restProps } = props;

  return (
    <Modal.Root className={cn('md:max-w-[512px]', className)} fullWidth {...restProps}>
      <Modal.Header label="Checkout" />
      <Modal.Content className="min-h-[200px] px-6 pb-6 transition-[height]">
        <PaymentGatewayForm onSubmitPayment={onSubmitPayment} />
      </Modal.Content>
    </Modal.Root>
  );
};
