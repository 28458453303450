import {CURRENCY_CODE, CURRENCY_DICTIONARY} from '@/shared/constants';

export const USDollar = new Intl.NumberFormat(CURRENCY_DICTIONARY.USD.locale, {
  style: 'currency',
  currency: CURRENCY_DICTIONARY.USD.code,
});

export const INR = new Intl.NumberFormat(CURRENCY_DICTIONARY.INR.locale, {
  style: 'currency',
  currency: CURRENCY_DICTIONARY.INR.code,
});

export const currencyConverter = (code = CURRENCY_CODE.USD) => {
  return new Intl.NumberFormat(CURRENCY_DICTIONARY[code].locale, {
    style: 'currency',
    currency: CURRENCY_DICTIONARY[code].code,
  });
};
