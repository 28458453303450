import {HTMLAttributes} from 'react';

import {cn} from '@/shared/libs/utils';
import {Icon, Typography} from '@/shared/ui';
import {useBrandFeatures} from "@/entities/brand";

export interface Props extends HTMLAttributes<HTMLDivElement> {
}

export const ModalBlurredInfo = (props: Props) => {
  const {className, ...restProps} = props;
  const features = useBrandFeatures();
  return (
    <div
      className={cn(
        'absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center gap-6 sm:hidden',
        className,
      )}
      {...restProps}
    >
      <div className="flex flex-col justify-center items-center absolute bottom-0 w-full left-0 h-3/4">
        {features?.modal_blurred_info.icon && <Icon k="eye-crossed" className="text-5xl"/>}
        <div className="pt-4 flex flex-col justify-center items-center gap-1.5 text-center max-w-[300px]">
          <Typography as="span" weight="bold" className="text-white text-[28px]">
            {features?.modal_blurred_info.title}
          </Typography>
          <Typography as="span" weight="semibold" variant="base" className="text-white">
            {features?.modal_blurred_info.text}
          </Typography>
        </div>
      </div>
    </div>
  );
};
