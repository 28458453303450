import {HTTP_DICTIONARY} from '@/core/constants';
import {httpClient} from '@/core/services';

import type {BrandConfigModel} from './models';

export const getBrandConfig = async (query?: string) => {
  return httpClient
    .get<BrandConfigModel>(HTTP_DICTIONARY.brandConfigWeb, {
      params: {
        ...(query && { orig: query }),
      },
      headers: {
        withAuth: false,
      },
    })
    .then((r) => r.data);
};

export const brandRepository = {
  getBrandConfig,
} as const;
