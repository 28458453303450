import {useForm} from 'react-hook-form';

import {HttpError} from '@/core/services';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';

import {useResetPasswordMutation} from '@/entities/auth';
import {useBrandConfig} from '@/entities/brand';

export const resetPasswordSchema = z.object({
  email: z
    .string({required_error: 'The specified email address is invalid'})
    .email({message: 'The specified email address is invalid'}),
});

export type ResetPasswordSchema = z.infer<typeof resetPasswordSchema>;

const defaultValues: Partial<ResetPasswordSchema> = {
  email: '',
};

export interface ResetPasswordFormControllerParams {
  onSuccess?: (email: string) => void;
  onError?: (error: unknown) => void;
}

export const useResetPasswordFormController = (params?: ResetPasswordFormControllerParams) => {
  const {onSuccess, onError} = params || {};

  const {mutateAsync: resetPassword, isPending} = useResetPasswordMutation();

  const brandConfig = useBrandConfig();

  const {
    control,
    formState: {errors, isSubmitting},
    setError,
    handleSubmit,
    reset,
  } = useForm<ResetPasswordSchema>({
    resolver: zodResolver(resetPasswordSchema),
    defaultValues,
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await resetPassword({...data, client_token: brandConfig?.constants?.api_token as string});
      onSuccess?.(data.email);
      reset();
    } catch (error) {
      if (HttpError.is(error)) {
        setError('email', {message: error.message});
      }
      onError?.(error);
      // Analytic.loginError(error);
      return Promise.reject(error);
    }
  });

  const isPendingResetPassword = isPending || isSubmitting;

  return {
    control,
    errors,
    isPendingResetPassword,
    onSubmit,
  };
};
