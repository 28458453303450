import './polyfills';

import ReactDOM from 'react-dom/client'

import {LocalStorageKey, localStorageService} from '@/core/services/storage';

import {App} from '@/app/app';

import {authStorageGetters} from '@/entities/auth';

import {createApiClient} from '@/shared/api/client';

import '@repo/ui-kit/styles'

import './index.css';

localStorageService.setItem(LocalStorageKey.Consent, true);

createApiClient(authStorageGetters.getToken);

const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(<App/>);
}
