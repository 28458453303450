import {HTMLAttributes, MouseEventHandler, ReactNode} from 'react';

import {renderFromTemplate} from '@/shared/libs/parsers';
import {cn, randomUuid} from '@/shared/libs/utils';
import {Button, Typography} from '@/shared/ui';

import './styles.css';
import {useBrandFeatures} from "@/entities/brand";

export interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  description?: ReactNode;

  btnTitle?: string;

  onClickBtn?: MouseEventHandler<HTMLButtonElement>;
}

export const SubscriptionBanner = (props: Props) => {
  const {className, description, btnTitle = 'Subscribe', onClickBtn, ...restProps} = props;

  const features = useBrandFeatures();

  const templateBanner = renderFromTemplate(
    {vars: {}, value: features?.credit_modal.banner.title ?? ''},
    {
      bold: (el) => (
        <Typography key={randomUuid()} weight="bold">
          {el}
        </Typography>
      ),
    },
  );

  return (
    <div
      className={cn('subscription-banner flex items-center justify-between gap-2 p-4  rounded-lg  bg-cover', className)}
      {...restProps}
    >
      {description !== undefined ? (
        description
      ) : (
        <div className="text-banner-gradient-content text-xs">
          <div className="!text-banner-gradient-content text-2sm font-medium">{templateBanner}</div>
        </div>
      )}
      <Button onClick={onClickBtn} className="basis-1/3" variant="light" size="md">
        {btnTitle}
      </Button>
    </div>
  );
};
