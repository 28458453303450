import {HTMLAttributes, ReactNode} from 'react';

import {cn} from '@/shared/libs/utils';

import {NavItem as NavItemType} from '../../libs';
import {NavItem} from '../nav-item';
import {Header} from './header';
import {NavList} from './nav-list';
import './styles.css';
import {Trigger} from './trigger';

export interface Props extends HTMLAttributes<HTMLElement> {
  navItem?: ReactNode | ((item: NavItemType) => ReactNode);
  navList: NavItemType[];
}

export const Navbar = (props: Props) => {
  const { className, navItem, navList, ...restProps } = props;

  return (
    <aside className={cn('navbar', className)} {...restProps}>
      <Header />
      <Trigger />
      <NavList>
        {navList.map((item) => {
          if (navItem) return typeof navItem === 'function' ? navItem(item) : navItem;

          return <NavItem item={item} key={item.to} />;
        })}
      </NavList>
    </aside>
  );
};
