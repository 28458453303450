import {HTMLAttributes} from 'react';

import {cn} from '@/shared/libs/utils';
import {ScrollArea} from '@/shared/ui/scroll-area';

export interface Props extends HTMLAttributes<HTMLDivElement> {}

export const DrawerContent = (props: Props) => {
  const { className, children, ...restProps } = props;

  return (
    <div
      className={cn(
        'flex flex-col grow shrink-0 scrollbar-none h-[calc(100%_-_var(--drawer-header-height))]',
        className,
      )}
      {...restProps}
    >
      <ScrollArea className="flex flex-col grow h-px">{children}</ScrollArea>
    </div>
  );
};
