import { useForm } from 'react-hook-form';

import { HttpError } from '@/core/services';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { useSignUpMutation } from '@/entities/auth';

import { Analytic } from '@/shared/services/analytic';

export const signUpSchema = z.object({
  email: z
    .string({ required_error: 'The specified email address is invalid' })
    .email({ message: 'The specified email address is invalid' }),
  password: z
    .string({ required_error: 'Password must not be empty' })
    .min(8, { message: 'The value must be 8 or more characters' }),
});

export type SignUpSchema = z.infer<typeof signUpSchema>;

const defaultValues: SignUpSchema = {
  email: '',
  password: '',
};

export interface SignUpFormControllerParams {
  onSuccess?: (data: SignUpSchema) => void;
  onError?: (error: unknown) => void;
  analyticData?: any;
}

export const useSignUpFormController = (params?: SignUpFormControllerParams) => {
  const { onSuccess, onError, analyticData } = params || {};

  const {
    control,
    formState: { errors, isSubmitSuccessful },
    setError,
    handleSubmit,
    reset,
    getValues,
  } = useForm<SignUpSchema>({
    resolver: zodResolver(signUpSchema),
    defaultValues,
  });

  const { mutateAsync: signUp, isPending } = useSignUpMutation();

  const onSubmit = handleSubmit(async (data) => {
    Analytic.signUpCreateAccountClick(analyticData);

    try {
      await signUp(data);
      onSuccess?.(data);
      reset();
    } catch (error) {
      if (HttpError.is(error)) {
        setError('email', { message: error.message });
      }
      onError?.(error);
      return Promise.reject(error);
    }
  });

  return {
    control,
    errors,
    isPending,
    isSubmitSuccessful,
    getValues,
    onSubmit,
  };
};
