import {HTTP_DICTIONARY} from '@/core/constants';
import {httpClient} from '@/core/services/http';

import {charactersAdapter} from '@/entities/character/libs/repository/character.adapters';

import {CONTENT_TYPE} from '@/shared/constants';

import {
  CharacterAdapted,
  CharacterOverride,
  CreateCharacterDto,
  GenerateCharacterPhotoDTO,
  UpdateCharacterParams,
  UpdateCharacterSchema,
  UploadPhotoResponse,
} from './models';

const getCharacters = async (signal?: AbortSignal) => {
  return httpClient
    .get<CharacterAdapted[]>(HTTP_DICTIONARY.characters, { signal })
    .then((r) => charactersAdapter(r.data));
};

const updateCharacter = async (params: UpdateCharacterParams) => {
  const { character, chatId } = params;

  const data = UpdateCharacterSchema.parse(character);

  const { character: characterOpts, ...restData } = data;

  return httpClient
    .post<CharacterOverride>(HTTP_DICTIONARY.replaceParams(HTTP_DICTIONARY.updateCharacter, { chatId }), {
      ...restData,
      ...characterOpts,
    })
    .then((r) => r.data);
};

const resetCharacter = async (chatId: string) => {
  return httpClient
    .post<CharacterOverride>(HTTP_DICTIONARY.replaceParams(HTTP_DICTIONARY.resetCharacter, { chatId }))
    .then((r) => r.data);
};

const uploadCharacterPhoto = async (image: File | Blob) => {
  const formData = new FormData();

  formData.append('file', image);

  return httpClient
    .post<UploadPhotoResponse>(HTTP_DICTIONARY.uploadPhoto, formData, {
      headers: { 'Content-Type': CONTENT_TYPE.FORM_DATA },
    })
    .then((r) => r.data);
};

const createCharacter = (dto: CreateCharacterDto) => {
  return httpClient.post(HTTP_DICTIONARY.characters, dto).then((r) => r.data);
};

const generateCharacterPhoto = (dto: GenerateCharacterPhotoDTO) => {
  return httpClient.post(HTTP_DICTIONARY.generateCharacterPhoto, dto).then((r) => r.data);
};

export const characterRepository = {
  getCharacters,
  updateCharacter,
  resetCharacter,
  uploadCharacterPhoto,
  createCharacter,
  generateCharacterPhoto,
} as const;
