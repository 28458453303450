import {memo} from 'react';

import {useNavigate} from '@tanstack/react-router';
import {ChevronLeftIcon} from 'lucide-react';

import {CharacterChatBadge} from '@/features/character-chat-badge';
import {BuyCreditsModalTrigger} from '@/features/modals';

import {useCharacterById} from '@/entities/character';
import {ConversationLayout, useConversationDrawerContext, useGetConversationById} from '@/entities/conversation';

import {Analytic} from '@/shared/services/analytic';
import {Button} from '@/shared/ui';

export interface Props {
  chatId: string;
}

export const ChatHeader = memo((props: Props) => {
  const { chatId } = props;

  const { onToggle, isOpen } = useConversationDrawerContext();

  const navigate = useNavigate();

  const { conversation } = useGetConversationById(chatId);

  const { character } = useCharacterById(conversation?.character_id);

  const handleClickAvatar = () => {
    if (!isOpen) {
      Analytic.personaProfileView({ persona_id: character?.id, persona_name: character?.name });
    }
    onToggle();
  };

  const onClickBack = async () => {
    await navigate({ to: '/conversations' });
  };

  return (
    <ConversationLayout.Header className="basis-[69px] h-[69px]">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <Button onClick={onClickBack} className="md:hidden y" size="icon" variant="outline">
            <ChevronLeftIcon className="size-5" />
          </Button>
          {character && conversation && (
            <CharacterChatBadge
              onClick={handleClickAvatar}
              name={character.name}
              imgSrc={character.picture_url}
              status={conversation.status}
            />
          )}
        </div>
        {character && <BuyCreditsModalTrigger characterName={character.name} characterId={character.id} />}
      </div>
    </ConversationLayout.Header>
  );
});
