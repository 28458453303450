import {Document, DocumentProps, Page, pdfjs} from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import {cn} from '@/shared/libs/utils';

import {usePDFViewer, withPDFViewerContext} from './_providers';
import {Pagination} from './_ui';
import './styles.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/legacy/build/pdf.worker.min.js', import.meta.url).toString();

export interface Props extends DocumentProps {
  width?: number;

  height?: number;

  pageClassName?: string;
}

const _PDFViewer = (props: Props) => {
  const { className, width, height, pageClassName, ...restProps } = props;

  const { pagesCount, pageNumber, onLoadSuccess } = usePDFViewer();

  return (
    <Document
      loading={<div className="hidden" />}
      className={cn(
        'relative overflow-y-auto scrollbar-w-4 scrollbar scrollbar-thumb-brand-default scrollbar-track-slate-300/10',
        className,
      )}
      onLoadSuccess={onLoadSuccess}
      {...restProps}
    >
      <Page
        renderTextLayer={false}
        pageNumber={pageNumber}
        width={width}
        height={height}
        className={cn(
          'overflow-y-auto scrollbar-w-4 scrollbar scrollbar-thumb-brand-default scrollbar-track-slate-300/10',
          pageClassName,
        )}
      />
      {pagesCount > 1 && <Pagination className="absolute z-2 bottom-4 inset-x-0" />}
    </Document>
  );
};

export const PDFViewer = withPDFViewerContext(_PDFViewer);
