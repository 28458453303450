import {ElementType, HTMLAttributes} from 'react';

import {cva, VariantProps} from 'class-variance-authority';

import {cn} from '@/shared/libs/utils';

type ElType = Extract<ElementType, 'p' | 'span' | 'div' | 'code' | 'pre' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'>;

const typography = cva('', {
  variants: {
    variant: {
      '5xl': 'text-5xl',
      '4xl': 'text-4xl',
      '3xl': 'text-3xl',
      '2xl': 'text-2xl',
      xl: 'text-xl',
      base: 'text-base',
      '2sm': 'text-2sm',
      sm: 'text-sm',
      '2xs': 'text-2xs',
      xs: 'text-xs',
    },
    weight: {
      normal: 'font-normal',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold',
    },

    select: {
      true: 'select-auto',
      false: 'select-none',
    },

    truncate: {
      true: 'truncate',
      false: '',
    },

    lineClamp: {
      0: '',
      1: 'line-clamp-1',
      2: 'line-clamp-2',
      3: 'line-clamp-3',
      4: 'line-clamp-4',
      5: 'line-clamp-5',
    },
  },
  defaultVariants: {
    variant: 'base',
    weight: 'normal',
    select: true,
    truncate: false,
    lineClamp: 0,
  },
});

export interface Props<T extends ElType = 'p'> extends HTMLAttributes<T>, VariantProps<typeof typography> {
  as?: T;
}

export const Typography = <T extends ElType>(props: Props<T>) => {
  const {
    as: Comp = 'p',
    className,
    variant = 'base',
    weight = 'normal',
    lineClamp = 0,
    truncate = false,
    select = true,
    ...restProps
  } = props;

  return (
    <Comp
      {...(restProps as any)}
      className={cn(
        typography({
          variant,
          weight,
          lineClamp,
          truncate,
          select,
        }),
        className,
      )}
    />
  );
};
