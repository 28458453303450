import {HTMLAttributes, useMemo} from 'react';

import audioAnimation from '@/assets/lottie/audio-animation.json';
import photoAnimation from '@/assets/lottie/photo-animation.json';
import typingAnimation from '@/assets/lottie/typing-animation.json';
import {CharacterStatus} from '@/core/constants';

import {StatusAnimation} from '@/shared/ui/';

export interface Props extends HTMLAttributes<HTMLSpanElement> {
  status: CharacterStatus;
}

export const ConversationItemStatus = (props: Props) => {
  const { status, className, ...restProps } = props;

  const statusComponent = useMemo(() => {
    switch (status) {
      case CharacterStatus.TYPING:
        return <StatusAnimation animationData={typingAnimation} loop={true} text="typing" />;
      case CharacterStatus.SENDING_PHOTO:
        return <StatusAnimation animationData={photoAnimation} loop={true} text="sending photo" />;
      case CharacterStatus.RECORDING_VOICE:
        return <StatusAnimation animationData={audioAnimation} loop={true} text="recording voice" />;

      default:
        return null;
    }
  }, [status]);

  return (
    <span className={className} {...restProps}>
      {statusComponent}
    </span>
  );
};
