import {CSSProperties, type HTMLAttributes, useCallback} from 'react';

import {DIALOG_KEYS} from '@/core/constants';

import {BuySubscriptionModal} from '@/features/modals';

import {useBrandFeatures} from '@/entities/brand';
import {useGetMeQuery} from '@/entities/viewer';

import {renderFromTemplate} from '@/shared/libs/parsers';
import {cn, randomUuid} from '@/shared/libs/utils';
import {Analytic} from '@/shared/services/analytic';
import {dialog, Typography} from '@/shared/ui';

import {Layout, Timer} from './_ui';

export interface Props extends HTMLAttributes<HTMLElement> {
  analyticData?: any;
}

export const DiscountBanner = (props: Props) => {
  const {data: viewer} = useGetMeQuery();
  const {className, analyticData, style, ...restProps} = props;
  const features = useBrandFeatures();
  const bgUrl = features?.discount_banner?.background;

  const styles: CSSProperties = {
    ...(bgUrl && {backgroundImage: `url(${bgUrl})`}),
    ...style,
  };

  const templateDiscountLabel = renderFromTemplate(
    {vars: {}, value: features?.discount_banner?.label ?? ''},
    {
      bold: (el) => (
        <Typography key={randomUuid()} weight="bold" className="text-4xl px-1">
          {el}
        </Typography>
      ),
    },
  );

  const onOpenSubscription = useCallback(() => {
    dialog.open({
      key: DIALOG_KEYS.BUY_SUBSCRIPTION,
      component: <BuySubscriptionModal analyticData={analyticData}/>,
      beforeOpen: () => {
        Analytic.subscribeFromMainScreenClick();
      },
    });
  }, []);

  return (
    <Layout
      {...restProps}
      style={styles}
      className={cn('cursor-pointer', className)}
      label={templateDiscountLabel}
      timer={
        <Timer
          createdAt={viewer?.created_at ?? ''}
          cycleDurationSeconds={features?.discount_banner?.cycle_duration_seconds ?? 86400}
        />
      }
      onClick={onOpenSubscription}
    />
  );
};
