import {Target, TargetAndTransition} from 'framer-motion';

const initialBackdrop: Target = {
  backdropFilter: 'blur(0px)',
  backgroundColor: 'rgba(0, 0, 0, 0)',
};

const animateBackdrop: TargetAndTransition = {
  backdropFilter: 'blur(3px)',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',

  transition: {
    duration: 0.1,
    ease: 'linear',
  },
};

const exitBackdrop: TargetAndTransition = {
  ...initialBackdrop,
};

export const backdrop = {
  initial: initialBackdrop,
  animate: animateBackdrop,
  exit: exitBackdrop,
};

const initialBody: Target = {
  transform: 'translateY(100vh)',
  opacity: 0,
};

const animateBody: TargetAndTransition = {
  transform: 'translateY(0%)',
  opacity: 1,
};

const exitBody: TargetAndTransition = {
  ...initialBody,
};

export const body = {
  initial: initialBody,
  animate: animateBody,
  exit: exitBody,
};
