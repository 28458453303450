import ReactGA from 'react-ga4';

import * as amplitude from '@amplitude/analytics-browser';
import {Identify} from '@amplitude/analytics-browser';

import type {
  AddToCart,
  PaywallView,
  Persona,
  Placement,
  ProcessPayment,
  PurchaseFailed,
  PurchaseSuccessful,
  SendMessage,
} from './dto';
import {EVENT_COLLECTION, type EventCollectionName} from './event-collection';

export type AnalyticServiceParams = {
  amplitudeKey?: string;
  googleTrackingId?: string;
};

export class AnalyticService {
  static amplitude = amplitude;

  static init = ({ googleTrackingId, amplitudeKey }: AnalyticServiceParams) => {
    return Promise.all([this.initAmplitude(amplitudeKey), this.initGoogle(googleTrackingId)]);
  };

  static firstLaunch = () => {
    this.dispatch(EVENT_COLLECTION.first_launch);
    this.firstTouch(window.location.href);
  };

  static setUserId = (id: string) => {
    if (id === this.amplitude.getUserId()) return;
    amplitude.setUserId(id);
  };

  static onboardingScreen = (step: number) => {
    this.dispatch(this.replaceDynamicEvent(EVENT_COLLECTION.quiz_onboarding_step_view, step));
  };

  static onboardingPersonaReady = (dto: Persona) => {
    this.dispatch(EVENT_COLLECTION.quiz_onboarding_persona_ready_view, dto);
  };

  static conversationListView = () => {
    this.dispatch(EVENT_COLLECTION.dialogs_list_screen_view);
  };

  static subscribeFromMainScreenClick = () => {
    this.dispatch(EVENT_COLLECTION.subscribe_from_main_screen_click);
  };

  static conversationClick = (dto: Persona) => {
    this.dispatch(EVENT_COLLECTION.dialog_click, dto);
  };

  static conversationDetailView = (dto: Persona) => {
    this.dispatch(EVENT_COLLECTION.chat_screen_view, dto);
  };

  static chatCouponsBalanceCLick = () => {
    this.dispatch(EVENT_COLLECTION.chat_coupons_balance_click);
  };

  static photoUnblurClick = (dto: Persona) => {
    this.dispatch(EVENT_COLLECTION.photo_unblur_click, dto);
  };

  static sendMessageClick = (dto: SendMessage) => {
    this.dispatch(EVENT_COLLECTION.send_message_click, dto);
  };

  static subscribeFromCoinsPaywallClick = () => {
    this.dispatch(EVENT_COLLECTION.subscribe_from_coins_paywall_click);
  };

  static personaProfileView = (dto: Persona) => {
    this.dispatch(EVENT_COLLECTION.persona_profile_view, dto);
  };

  static editProfileCLick = () => {
    this.dispatch(EVENT_COLLECTION.edit_profile_click);
  };

  static personaEditProfileView = (dto: Persona) => {
    this.dispatch(EVENT_COLLECTION.persona_edit_profile_view, dto);
  };

  static addFaceClick = () => {
    this.dispatch(EVENT_COLLECTION.add_face_click);
  };

  static finishEditProfileClick = (dto: Persona) => {
    this.dispatch(EVENT_COLLECTION.finish_edit_profile_click, dto);
  };

  static paywallScreenView = (dto: PaywallView) => {
    this.dispatch(EVENT_COLLECTION.paywall_screen_view, dto);
  };

  static paywallSkipClick = (dto: Placement) => {
    this.dispatch(EVENT_COLLECTION.paywall_skip_click, dto);
  };

  static checkoutEmailSentSuccess = () => {
    this.dispatch(EVENT_COLLECTION.checkout_success_email_sent_click);
  };

  static addToCart = (dto: AddToCart) => {
    this.dispatch(EVENT_COLLECTION.add_to_cart, dto);
  };

  static beginCheckout = (dto: Placement) => {
    this.dispatch(EVENT_COLLECTION.begin_checkout, dto);
  };

  static processPaymentClick = (dto: ProcessPayment) => {
    this.dispatch(EVENT_COLLECTION.process_payment_click, dto);
  };

  static closeCheckoutClick = () => {
    this.dispatch(EVENT_COLLECTION.close_checkout_click);
  };

  static discoveryScreenView = () => {
    this.dispatch(EVENT_COLLECTION.discover_screen_view);
  };

  static purchaseSuccessful = (dto: PurchaseSuccessful) => {
    this.dispatch(EVENT_COLLECTION.purchase, dto);
  };

  static purchaseFailed = (dto: PurchaseFailed) => {
    this.dispatch(EVENT_COLLECTION.purchase_failed, dto);
  };

  static discoverNoProfilesLeft = () => {
    this.dispatch(EVENT_COLLECTION.discover_no_profiles_left);
  };

  static discoverClick = () => {
    this.dispatch(EVENT_COLLECTION.discover_click);
  };

  static settingsClick = () => {
    this.dispatch(EVENT_COLLECTION.settings_click);
  };

  static dialogsListClick = () => {
    this.dispatch(EVENT_COLLECTION.dialogs_list_click);
  };

  static createAiClick = () => {
    this.dispatch(EVENT_COLLECTION.create_ai_click);
  };

  static checkoutSuccess = () => {
    this.dispatch(EVENT_COLLECTION.checkout_success);
  };

  static cancelSubscriptionClick = () => {
    this.dispatch(EVENT_COLLECTION.cancel_subscription_click);
  };

  static settingsScreenView = () => {
    this.dispatch(EVENT_COLLECTION.settings_screen_view);
  };

  static subscribeFromSettingsScreenClick = () => {
    this.dispatch(EVENT_COLLECTION.subscribe_from_settings_screen_click);
  };

  static quizDeleteProgressView = () => {
    this.dispatch(EVENT_COLLECTION.quiz_onboarding_delete_progress_view);
  };

  static abTest = (test_group: string) => {
    this.identify(EVENT_COLLECTION.ab_test, test_group);
  };

  static signUpScreenView = (dto?: Persona) => {
    this.dispatch(EVENT_COLLECTION.signup_screen_view, dto);
  };

  static signUpCreateAccountClick = (dto?: Persona) => {
    this.dispatch(EVENT_COLLECTION.signup_create_account_click, dto);
  };

  static loginScreenView = (dto?: Persona) => {
    this.dispatch(EVENT_COLLECTION.login_screen_view, dto);
  };

  static loginClick = (dto?: Persona) => {
    this.dispatch(EVENT_COLLECTION.login_click, dto);
  };

  static loginError = (error: unknown) => {
    this.dispatch(EVENT_COLLECTION.login_error, { error });
  };

  static createAiStepView = (step: number) => {
    this.dispatch(this.replaceDynamicEvent(EVENT_COLLECTION.create_ai_step_view, step));
  };

  static createAiStepContinueClick = (step: number) => {
    this.dispatch(this.replaceDynamicEvent(EVENT_COLLECTION.create_ai_step_continue_click, step));
  };

  static createAiPersonaReadyView = () => {
    this.dispatch(EVENT_COLLECTION.create_ai_persona_ready_view);
  };

  static createAiPersonaReadyContinueClick = () => {
    this.dispatch(EVENT_COLLECTION.create_ai_persona_ready_continue_click);
  };

  static createAiPersonaInProgressView = () => {
    this.dispatch(EVENT_COLLECTION.create_ai_persona_inprogress_view);
  };

  static pushNotificationEnabled = () => {
    const identify = new Identify();
    identify.setOnce(EVENT_COLLECTION.push_notification_enabled, 'enabled');
    amplitude.identify(identify);
  };

  static pushNotificationDisabled = () => {
    const identify = new Identify();
    identify.setOnce(EVENT_COLLECTION.push_notification_disabled, 'disabled');
    amplitude.identify(identify);
  };

  static pushNotificationOpen = () => {
    this.dispatch(EVENT_COLLECTION.push_open);
  };

  static somethingWentWrong = (error: unknown) => {
    this.amplitudeTrack(EVENT_COLLECTION.something_went_wrong, { error });
  };

  static firstTouch = (url: string) => {
    const identify = new Identify();
    identify.setOnce(EVENT_COLLECTION.first_touch_url, url);
    amplitude.identify(identify);
    ReactGA.gtag('set', 'user_properties', { [EVENT_COLLECTION.first_touch_url]: url });
  };

  private static initAmplitude = (amplitudeKey: AnalyticServiceParams['amplitudeKey']) => {
    return new Promise((resolve) => {
      if (amplitudeKey && amplitudeKey !== 'empty') {
        amplitude.init(amplitudeKey, { defaultTracking: true });
      }
      resolve(null);
    });
  };

  private static initGoogle = (googleTrackingId: AnalyticServiceParams['googleTrackingId']) => {
    return new Promise((resolve) => {
      if (googleTrackingId && googleTrackingId !== 'empty') {
        ReactGA.initialize([{ trackingId: googleTrackingId }]);
      }
      resolve(null);
    });
  };

  private static amplitudeTrack = (event: EventCollectionName, params?: Record<string, unknown>) => {
    amplitude.track(event, params);
  };

  private static reactGaTrack = (event: EventCollectionName, params?: Record<string, unknown>) => {
    ReactGA.event(event, params);
  };

  private static dispatch = (event: EventCollectionName, params?: Record<string, unknown>) => {
    this.amplitudeTrack(event, params);
    this.reactGaTrack(event, params);
  };

  private static replaceDynamicEvent = <T extends string | number>(event: EventCollectionName, value: T) => {
    return event.replace(/{{.*}}/gm, `${value}`) as EventCollectionName;
  };

  private static identify = (event: EventCollectionName, value: string) => {
    const identify = new Identify();
    identify.set(event, value);
    amplitude.identify(identify);
  };
}
