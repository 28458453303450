import type {
  ColorGradientSchemaSchema,
  ColorsSchema,
  ElementPropertySchema,
  FontSizeSchema,
  RoundedSchema,
  ThemeSchema,
} from '../models';
import {colorsSchema, elementSchema, roundedSchema, themeSchema, typographySchema} from '../models';

class ThemeService {
  private theme: ThemeSchema;

  constructor(theme: ThemeSchema) {
    this.theme = themeSchema.parse(theme);
  }

  public createRootMap = () => {
    const { colors, rounded, typography, elements } = this.setup();

    return `
        :root {
          ${this.toString(colors)}
          ${this.toString(rounded)}
          ${this.toString(typography)}
          ${this.toString(elements)}
        }  
    `;
  };

  public injectStyleTag = () => {
    const style = document.createElement('style');
    style.appendChild(document.createTextNode(this.createRootMap()));
    document.head.appendChild(style);
  };

  private setup = () => {
    const colors = this.processColors();
    const rounded = this.processRounded();
    const typography = this.processTypography();
    const elements = this.processElements();

    return {
      colors,
      rounded,
      typography,
      elements,
    };
  };

  private toString = (value: Record<string, string | number>) => {
    return Object.entries(value).reduce((acc, [key, value]) => {
      acc += `${key}: ${value};`;
      return acc;
    }, '');
  };

  private processElements = () => {
    const elements = elementSchema.parse(this.theme.elements);

    const value = Object.entries(elements).reduce(
      (acc, [elementKey, elementValue]) => {
        Object.entries(elementValue).forEach(([variantKey, variantValue]) => {
          Object.entries(variantValue).forEach(([propertyKey, propertyValue]) => {
            acc = {
              ...acc,
              ...this.getElementProperty(propertyValue, {
                elementKey,
                variantKey,
                propertyKey,
              }),
            };
          });
        });
        return acc;
      },
      {} as Record<string, string | number>,
    );

    return value;
  };

  private processTypography = () => {
    const font = typographySchema.safeParse(this.theme.typography);

    const propertyKey = 'typography';

    if (!font.success) {
      throw font.error;
    }

    return {
      [`--${propertyKey}-font-family`]: Array.isArray(font.data.family) ? font.data.family.join(',') : font.data.family,
      [`--${propertyKey}-default-size`]: `${font.data.default_size}px`,
      ...Object.entries(font.data.sizes).reduce(
        (acc, [key, value]) => {
          const { 0: fz, 1: lh } = this.getFontValue(value);
          return {
            ...acc,
            [`--${propertyKey}-${this.replaceSplash(key)}-fz`]: fz,
            [`--${propertyKey}-${this.replaceSplash(key)}-lh`]: lh,
          };
        },
        {} as Record<string, string>,
      ),
    };
  };

  private processRounded = () => {
    const rounded = roundedSchema.safeParse(this.theme.rounded);

    const propertyKey = 'rounded';

    if (!rounded.success) {
      throw rounded.error;
    }

    return Object.entries(rounded.data).reduce(
      (acc, [key, value]) => {
        return {
          ...acc,
          [`--${propertyKey}-${this.replaceSplash(key)}`]: this.getRoundedValue(value),
        };
      },
      {} as Record<string, string | number>,
    );
  };

  private processColors = () => {
    const colors = colorsSchema.safeParse(this.theme.colors);

    const propertyKey = 'color';

    if (!colors.success) {
      throw colors.error;
    }

    return Object.entries(colors.data).reduce(
      (acc, [key, value]) => {
        const colorValue = this.getColorValue(value);
        acc = {
          ...acc,
          ...(colorValue ? { [`--${propertyKey}-${this.replaceSplash(key)}`]: colorValue } : {}),
        };

        return acc;
      },
      {} as Record<string, string>,
    );
  };

  private getElementProperty = (
    value: ElementPropertySchema | string | number,
    keys: { elementKey: string; variantKey?: string; propertyKey: string },
  ) => {
    const { elementKey, variantKey, propertyKey } = keys;

    const _propertyKey = this.replaceSplash(propertyKey);
    const _elementKey = this.replaceSplash(elementKey);

    const _variantKey = variantKey && variantKey !== 'default' ? `-${variantKey}` : '';

    if (typeof value === 'string' || typeof value === 'number') {
      return {
        [`--${_propertyKey}-${_elementKey}${_variantKey}`]: value,
      };
    }

    if (value.type === 'colors') {
      const colorVal = this.getColorValue(this.theme.colors[value.default], true);
      const colorValActive = value.active ? this.getColorValue(this.theme.colors[value.active], true) : null;

      return {
        ...(colorVal ? { [`--${_propertyKey}-${_elementKey}${_variantKey}`]: colorVal ?? value.default } : {}),
        ...(colorValActive
          ? { [`--${propertyKey}-${_elementKey}${_variantKey}-active`]: colorValActive ?? value.active }
          : {}),
      };
    }

    if (value.type === 'rounded') {
      const roundedVal = this.getRoundedValue(this.theme.rounded[value.default]);
      const roundedValActive = value.active ? this.getRoundedValue(this.theme.rounded[value.active]) : null;
      return {
        ...(roundedVal ? { [`--${_propertyKey}-${_elementKey}${_variantKey}`]: roundedVal } : {}),
        ...(roundedValActive ? { [`--${propertyKey}-${_elementKey}${_variantKey}-active`]: roundedValActive } : {}),
      };
    }

    return {};
  };

  private getFontValue = (value: FontSizeSchema[keyof FontSizeSchema]) => {
    return [this.rem(value[0]), this.rem(value[1])];
  };

  private getRoundedValue = (value: RoundedSchema[keyof RoundedSchema]) => {
    return this.rem(value);
  };

  private getColorValue = (value: ColorsSchema[keyof ColorsSchema], asRgb = false) => {
    if (!value) return;

    if (typeof value === 'string') {
      return asRgb ? `rgb(${value})` : value;
    }

    return this.createLinearGradient(value);
  };

  private replaceSplash = (str: string) => {
    return str.replace(/_/g, '-');
  };

  private createLinearGradient = (gradient: ColorGradientSchemaSchema) => {
    const { angle, colors } = gradient;

    const gradientValue = Object.entries(colors).reduce((acc, [key, value]) => {
      acc += `${value} ${key}%, `;
      return acc;
    }, `linear-gradient(${angle}deg, `);

    return gradientValue.slice(0, -2) + ');';
  };

  private rem = (px: number): string => {
    return `${px / this.theme.typography.default_size}rem`;
  };
}

export interface IThemeService extends ThemeService {}

export const createTheme = (theme: ThemeSchema) => new ThemeService(theme);
