export const QUERY_KEYS = {
  Token: 'token',
  Characters: 'characters',
  GetMe: 'get-me',
  UpdateMe: 'update-me',
  Conversations: 'conversations',
  Messages: 'messages',
  CouponPacks: 'coupon-packs',
  SubscriptionList: 'subscription-list',
  SubscriptionPayment: 'subscription-payment',
  CreatePaymentIntent: 'create-payment-intent',
  FintmPacks: 'fintm-packs',
  FintmPrices: 'fintm-prices',
  CreditPacks: 'credit-packs',
  BrandConfig: 'brand-config',
  ConstantposPacks: 'constantpos-packs',
  ConstantposPrices: 'constantpos-prices',
  PaymentGatewayPacks: 'payment-gateway-packs',
  PaymentGatewayPrices: 'payment-gateway-prices',
  EpochPacks: 'epoch-packs',
  EpochPrices: 'epoch-prices',
} as const;
