import {BaserQueryOptions, queryClient} from '@/core/config/react-query';
import {QUERY_KEYS} from '@/core/constants';
import {useMutation, useQuery} from '@tanstack/react-query';

import {Viewer, viewerRepository} from '../repository';

export const useGetMeQuery = (options?: BaserQueryOptions<Viewer>) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GetMe],
    queryFn: ({ signal }) => {
      return viewerRepository.getMe(signal);
    },
    ...options,
  });
};

export const invalidateGetMeQuery = () => {
  return queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GetMe] });
};

export const useUpdateMeMutation = () => {
  return useMutation({
    mutationFn: viewerRepository.updateMe,
  });
};

export const viewerQuerySelectors = {
  getIsSubscribed: (viewer: Viewer) => viewer?.is_subscribed ?? false,
} as const;
