import {type Nullable, ObjectKeys} from '../../types';

export const STORAGE_EVENT_NAME = 'storage-event';

export type StorageActions = 'get' | 'set' | 'remove' | 'clear';

export type Listener<Schema extends Record<string, any>, K extends ObjectKeys<Schema> = ObjectKeys<Schema>> = (dto: {
  value: Nullable<Exclude<Schema[K], null>>;
  action: StorageActions;
}) => void;

export class StorageService<Schema extends Record<string, any>> {
  public listeners: Map<ObjectKeys<Schema>, Set<Listener<Schema>>> = new Map();

  constructor(private readonly storage: Storage) {}

  public getItem<K extends ObjectKeys<Schema>>(key: K, initialValue: Schema[K] | (() => Schema[K])): Schema[K] {
    const data = this.storage.getItem(key as string);

    if (data === null) {
      return initialValue instanceof Function ? initialValue() : initialValue;
    }

    return JSON.parse(data);
  }

  public setItem<K extends ObjectKeys<Schema>>(key: K, value: Schema[K]): void {
    this.storage.setItem(key as string, JSON.stringify(value));
    this.notifyListeners(key, value, 'set');
  }

  public removeItem<K extends ObjectKeys<Schema>>(key: K): void {
    this.storage.removeItem(key as string);
    this.notifyListeners(key, null, 'remove');
  }

  public clear(): void {
    this.storage.clear();
    for (const key of this.listeners.keys()) {
      this.notifyListeners(key, null, 'clear');
    }
  }

  public subscribe<K extends ObjectKeys<Schema>>(key: K, listener: Listener<Schema, K>): void {
    if (!this.listeners.has(key)) {
      this.listeners.set(key, new Set());
    }
    this.listeners.get(key)!.add(listener);
  }

  public unsubscribe<K extends ObjectKeys<Schema>>(key: K, listener: Listener<Schema>): void {
    const listeners = this.listeners.get(key);
    if (listeners) {
      listeners.delete(listener);
      if (listeners.size === 0) {
        this.listeners.delete(key);
      }
    }
  }

  private notifyListeners<K extends ObjectKeys<Schema>>(
    key: K,
    value: Nullable<Exclude<Schema[K], null>>,
    action: StorageActions,
  ): void {
    const listeners = this.listeners.get(key);
    if (listeners) {
      for (const listener of listeners) {
        listener({
          value,
          action,
        });
      }
    }
  }
}
