import loadable from '@loadable/component';
import { useCreatePaymentIntentQuery } from '@/entities/subscription';
import { Modal, SpinnerIcon } from '@/shared/ui';
import { ScrollArea } from '@/shared/ui/scroll-area';

export interface Props {
  creditsType: string;

  onSuccessPayment?: () => void;

  onFailedPayment?: (err: string | undefined) => void;
}

const CheckoutForm = loadable(() => import('../form'), {
  resolveComponent: (module) => module.CheckoutForm,
});

export const CreditsCheckoutModal = (props: Props) => {
  const { creditsType, onSuccessPayment, onFailedPayment } = props;

  const { data: paymentIntent, isLoading } = useCreatePaymentIntentQuery(
    { pack: creditsType },
    {
      refetchOnMount: 'always',
    },
  );

  return (
    <Modal.Root className="md:max-w-[512px]" fullWidth>
      <Modal.Header label="Checkout" />
      <Modal.Content className="min-h-[200px] px-6 pb-6 transition-[height]">
        <ScrollArea className="flex flex-col h-full grow pb-2">
          {!isLoading && paymentIntent && (
            <CheckoutForm
              onSuccessPayment={onSuccessPayment}
              onFailedPayment={onFailedPayment}
              clientSecret={paymentIntent.clientSecret}
            />
          )}
        </ScrollArea>
      </Modal.Content>
      {!paymentIntent && (
        <span className="absolute w-full h-full bg-black/10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center justify-center">
          <SpinnerIcon className="text-primary size-5" />
        </span>
      )}
    </Modal.Root>
  );
};
