import {HTMLAttributes, Ref, useCallback, useRef} from 'react';

import {useMergeRefs, useUncontrolled} from '@/shared/hooks';
import {cn, mergeComponents} from '@/shared/libs/utils';

import {ConversationDrawerContextValue, ConversationDrawerProvider} from '../../libs';
import {Drawer} from './drawer';
import {DrawerContent} from './drawer-content';
import {DrawerHeader} from './drawer-header';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  isOpen?: boolean;

  innerRef?: Ref<HTMLDivElement>;

  onChangeOpen?: (isOpen: boolean) => void;
}

const _ConversationDrawer = (props: Props) => {
  const { className, children, isOpen, innerRef, onChangeOpen, ...restProps } = props;

  const rootRef = useRef<HTMLDivElement>(null);

  const rootRefs = useMergeRefs(innerRef, rootRef);

  const [_isOpen, _onChangeOpen] = useUncontrolled({
    value: isOpen,
    finalValue: false,
    onChange: onChangeOpen,
  });

  const onOpen = useCallback(() => {
    _onChangeOpen(true);
  }, [_onChangeOpen]);

  const onClose = useCallback(() => {
    _onChangeOpen(false);
  }, [_onChangeOpen]);

  const onToggle = useCallback(() => {
    _onChangeOpen(!_isOpen);
  }, [_onChangeOpen, isOpen]);

  const value: ConversationDrawerContextValue = { isOpen: _isOpen, rootRef, onOpen, onClose, onToggle };

  return (
    <ConversationDrawerProvider value={value}>
      <div
        ref={rootRefs}
        className={cn('group/drawer flex relative max-w-full transition-max-w', className)}
        data-open={isOpen}
        {...restProps}
      >
        {children}
      </div>
    </ConversationDrawerProvider>
  );
};

export const ConversationDrawer = mergeComponents(() => {}, {
  Root: _ConversationDrawer,
  Drawer,
  Header: DrawerHeader,
  Content: DrawerContent,
});
