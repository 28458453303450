import * as React from 'react';

import * as LabelPrimitive from '@radix-ui/react-label';
import {cva, type VariantProps} from 'class-variance-authority';

import {cn} from '@/shared/libs/utils';

const labelVariants = cva(
  'text-sm font-semibold leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
  {
    variants: {
      colorSchema: {
        primary: 'text-grey-500',
      },
    },
    defaultVariants: {
      colorSchema: 'primary',
    },
  },
);

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>
>(({ className, ...props }, ref) => (
  <LabelPrimitive.Root ref={ref} className={cn(labelVariants(), className)} {...props} />
));
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
