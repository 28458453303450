import {BaserQueryOptions} from '@/core/config/react-query';
import {QUERY_KEYS, SubscriptionTier} from '@/core/constants';
import {queryOptions, useMutation, UseMutationOptions, useQuery} from '@tanstack/react-query';

import {
  ConstantposPack,
  ConstantposSubscription,
  CreatedSubscriptionModel,
  CreatePaymentIntentDto,
  CreateSubscriptionDto,
  EpochPack,
  EpochSubscription,
  type FintmPack,
  type FintmSubscription,
  PaymentGatewayPack,
  PaymentGatewaySubscription,
  SubscriptionModel,
  type SubscriptionQuery,
  subscriptionRepository,
} from '../repositories';

export const getSubscriptionListOptions = (
  subscriptionType: SubscriptionTier,
  options?: BaserQueryOptions<SubscriptionModel[]>,
) => {
  return queryOptions({
    queryKey: [QUERY_KEYS.SubscriptionList, subscriptionType],
    queryFn: ({ signal }) => subscriptionRepository.getSubscriptionList(subscriptionType, signal),
    ...options,
  });
};

export const getFintmPacksOptions = (options?: BaserQueryOptions<FintmPack[]>) => {
  return queryOptions({
    queryKey: [QUERY_KEYS.FintmPacks, QUERY_KEYS.CreditPacks],
    queryFn: () => subscriptionRepository.getFintmPacks(),
    ...options,
  });
};

export const useCreateFintmPayment = () => {
  return useMutation({
    mutationFn: subscriptionRepository.createFintmPayment,
  });
};

export const useGetFintmPacks = (options?: BaserQueryOptions<FintmPack[]>) => {
  return useQuery(getFintmPacksOptions(options));
};

export const getFintmSubscriptionsOptions = (
  query?: SubscriptionQuery,
  options?: BaserQueryOptions<FintmSubscription[]>,
) => {
  const lookupKeys = query?.lookup_keys?.join(',');
  return queryOptions({
    queryKey: [QUERY_KEYS.FintmPrices, lookupKeys],
    queryFn: () => subscriptionRepository.getFintmSubscriptions(query),
    ...options,
  });
};

export const useGetFintmSubscriptions = (
  query?: SubscriptionQuery,
  options?: BaserQueryOptions<FintmSubscription[]>,
) => {
  return useQuery(getFintmSubscriptionsOptions(query, options));
};

export const getConstantPacksOptions = (options?: BaserQueryOptions<ConstantposPack[]>) => {
  return queryOptions({
    queryKey: [QUERY_KEYS.FintmPacks, QUERY_KEYS.CreditPacks],
    queryFn: () => subscriptionRepository.getConstantposPacks(),
    ...options,
  });
};
export const useGetConstantposPacks = (options?: BaserQueryOptions<ConstantposPack[]>) => {
  return useQuery(getConstantPacksOptions(options));
};

export const useCreateConstantposUpiPayment = () => {
  return useMutation({
    mutationFn: subscriptionRepository.createConstantposUpiPayment,
  });
};

export const useCreateConstantposSubscriptionPayment = () => {
  return useMutation({
    mutationFn: subscriptionRepository.createConstantposSubscriptionPayment,
  });
};

export const getConstantposSubscriptionsOptions = (
  query?: SubscriptionQuery,
  options?: BaserQueryOptions<ConstantposSubscription[]>,
) => {
  const lookupKeys = query?.lookup_keys?.join(',');
  return queryOptions({
    queryKey: [QUERY_KEYS.FintmPrices, lookupKeys],
    queryFn: () => subscriptionRepository.getConstantposSubscriptions(query),
    ...options,
  });
};

export const useGetConstantposSubscriptions = (
  query?: SubscriptionQuery,
  options?: BaserQueryOptions<ConstantposSubscription[]>,
) => {
  return useQuery(getConstantposSubscriptionsOptions(query, options));
};

export const getPaymentGatewayPacksOptions = (options?: BaserQueryOptions<PaymentGatewayPack[]>) => {
  return queryOptions({
    queryKey: [QUERY_KEYS.PaymentGatewayPacks, QUERY_KEYS.CreditPacks],
    queryFn: () => subscriptionRepository.getPaymentGatewayPacks(),
    ...options,
  });
};

export const useGetPaymentGatewayPacks = (options?: BaserQueryOptions<PaymentGatewayPack[]>) => {
  return useQuery(getPaymentGatewayPacksOptions(options));
};

export const getPaymentGatewaySubscriptionsOptions = (
  query?: SubscriptionQuery,
  options?: BaserQueryOptions<PaymentGatewaySubscription[]>,
) => {
  const lookupKeys = query?.lookup_keys?.join(',');
  return queryOptions({
    queryKey: [QUERY_KEYS.PaymentGatewayPrices, lookupKeys],
    queryFn: () => subscriptionRepository.getPaymentGatewaySubscriptions(query),
    ...options,
  });
};

export const useGetPaymentGatewaySubscriptions = (
  query?: SubscriptionQuery,
  options?: BaserQueryOptions<PaymentGatewaySubscription[]>,
) => {
  return useQuery(getPaymentGatewaySubscriptionsOptions(query, options));
};

export const useCreatePaymentGateway = () => {
  return useMutation({
    mutationFn: subscriptionRepository.createPaymentGateway,
  });
};

export const getEpochPacksOptions = (options?: BaserQueryOptions<EpochPack[]>) => {
  return queryOptions({
    queryKey: [QUERY_KEYS.EpochPacks, QUERY_KEYS.CreditPacks],
    queryFn: () => subscriptionRepository.getEpochPacks(),
    ...options,
  });
};

export const useGetEpochPacks = (options?: BaserQueryOptions<EpochPack[]>) => {
  return useQuery(getEpochPacksOptions(options));
};

export const getEpochSubscriptionsOptions = (
  query?: SubscriptionQuery,
  options?: BaserQueryOptions<EpochSubscription[]>,
) => {
  const lookupKeys = query?.lookup_keys?.join(',');
  return queryOptions({
    queryKey: [QUERY_KEYS.EpochPrices, lookupKeys],
    queryFn: () => subscriptionRepository.getEpochSubscriptions(query),
    ...options,
  });
};

export const useGetEpochSubscriptions = (
  query?: SubscriptionQuery,
  options?: BaserQueryOptions<EpochSubscription[]>,
) => {
  return useQuery(getEpochSubscriptionsOptions(query, options));
};

export const useCreateEpochPaymentIntent = () => {
  return useMutation({
    mutationFn: subscriptionRepository.createEpochPaymentIntent,
  });
};

export const useGetSubscriptionList = (
  subscriptionType: SubscriptionTier,
  options?: BaserQueryOptions<SubscriptionModel[]>,
) => {
  return useQuery(getSubscriptionListOptions(subscriptionType, options));
};

export const useCreateSubscriptionMutation = (
  options?: UseMutationOptions<CreatedSubscriptionModel, unknown, Omit<CreateSubscriptionDto, 'customerId'>>,
) => {
  return useMutation({
    mutationFn: (dto) => subscriptionRepository.processCreateSubscription(dto.priceId),
    ...options,
  });
};

// const generateUniqueKey = (pack: string) => `usePacksPaymentIntent_${pack}_${randomUuid()}`;
export const useCreatePaymentIntentQuery = (
  dto: CreatePaymentIntentDto,
  options?: BaserQueryOptions<CreatedSubscriptionModel>,
) => {
  // const uniqueKey = useMemo(() => `usePacksPaymentIntent_${pack}_${randomUuid()}`, [dto.pack]);
  return useQuery({
    queryKey: [QUERY_KEYS.CreatePaymentIntent, dto.pack],
    queryFn: () => subscriptionRepository.createPaymentIntent(dto),
    staleTime: 0,
    ...options,
  });
};
