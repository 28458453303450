import {ReactNode} from 'react';

export interface Props {
  children?: ReactNode;
}

export const NavList = (props: Props) => {
  const { children } = props;

  return (
    <nav className="pt-6">
      <ul className="flex flex-col gap-1">{children}</ul>
    </nav>
  );
};
