import {CSSProperties, HTMLAttributes} from 'react';

import {cva, VariantProps} from 'class-variance-authority';

import {cn} from '@/shared/libs/utils';

const characterCard = cva('flex flex-col shrink-0', {
  variants: {
    perspective: {
      rectangle:
        'justify-end h-[150px] w-[100px] p-3 rounded-xl overflow-hidden  bg-cover bg-center grayscale-0 ring-0 transition-all',
      circle: 'items-center gap-2',
    },
  },

  defaultVariants: {
    perspective: 'rectangle',
  },
});

export interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'>, VariantProps<typeof characterCard> {
  name: string;

  imgSrc: string;

  isActive?: boolean;
}

export const CharacterCard = (props: Props) => {
  const { name, imgSrc, className, isActive, perspective = 'rectangle', style, ...restProps } = props;

  const styles: CSSProperties = {
    ...(perspective === 'rectangle' && { backgroundImage: `url(${imgSrc})` }),
    ...style,
  };

  const circleStyles: CSSProperties = {
    backgroundImage: `url(${imgSrc})`,
  };

  return (
    <div
      className={characterCard({
        perspective,
        className: cn(
          'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-brand-primary/80',
          {
            'ring-2 ring-brand-primary': isActive && perspective === 'rectangle',
          },
          className,
        ),
      })}
      style={styles}
      {...restProps}
    >
      {perspective === 'circle' && (
        <div
          style={circleStyles}
          className={cn(
            'w-[98px] h-[98px] basis-[98px] aspect-square relative bg-cover bg-center rounded-full',
            'focus-visible:before:ring-2 focus-visible:before:ring-brand-primary',
            {
              'outline outline-offset-2 outline-2 outline-brand-primary': isActive,
            },
          )}
        />
      )}
      <h6 className="text-white font-bold text-base line-clamp-1">{name}</h6>
    </div>
  );
};
