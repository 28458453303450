import {HTMLAttributes, useMemo} from 'react';

import {DIALOG_KEYS} from '@/core/constants';
import {PlusIcon} from 'lucide-react';

import {authModalAction} from '@/features/modals';

import {authStorageGetters, useWithAuth} from '@/entities/auth';
import {CreditSingleImg} from '@/entities/subscription/ui/credit-icon-packs/packs';
import {useGetMeQuery, useHasSubscription} from '@/entities/viewer';

import {cn} from '@/shared/libs/utils';
import {Analytic} from '@/shared/services/analytic';
import {Button, dialog, Typography} from '@/shared/ui';

import {BuyCreditsModal} from './BuyCreditsModal';
import {useBrandFeatures} from "@/entities/brand";

export interface Props extends HTMLAttributes<HTMLDivElement> {
  characterId?: number;

  characterName?: string;

  btnType?: 'simple-btn' | 'with-count';
}

export const BuyCreditsModalTrigger = (props: Props) => {
  const {characterId, characterName, className, btnType = 'with-count', ...restProps} = props;

  const {data: viewer} = useGetMeQuery();

  const isAuthenticated = authStorageGetters.getAuth().isAuthenticated;

  const withSubscription = useHasSubscription();

  const isAuth = useWithAuth();

  const features = useBrandFeatures();

  const onCreditModal = () => {
    dialog.open({
      key: DIALOG_KEYS.BUY_CURRENCY,
      component: <BuyCreditsModal characterId={characterId} characterName={characterName}/>,
    });
  };

  const handleClick = () => {
    Analytic.chatCouponsBalanceCLick();

    if (isAuth() && !withSubscription && !isAuthenticated) {
      authModalAction.open(
        {
          onSuccess: onCreditModal,
        },
        {
          afterClose: onCreditModal,
        },
      );
      return;
    }

    onCreditModal();
  };

  const creditIcon = (CreditSingleImg as any)[features?.credit_modal.credit_icon_type as string];
  const btnContent = useMemo(() => {
    if (typeof features?.credit_button_content === 'string') {
      return features?.credit_button_content;
    }

    if (features?.credit_button_content?.type === 'icon') {
      return <PlusIcon/>;
    }

    return <PlusIcon/>;
  }, [features]);

  if (btnType === 'simple-btn') {
    return (
      <Button size="sm" onClick={handleClick}>
        Get coupons
      </Button>
    );
  }

  return (
    <div className={cn('flex rounded-md border border-grey-600 pl-2.5 gap-2', className)} {...restProps}>
      <div className="flex items-center gap-2 py-2.5">
        <div className="size-6 flex items-center justify-center">
          <img width={24} height={24} src={creditIcon} alt="Credit Icon"/>
        </div>
        <Typography as="span" weight="semibold" className="text-primary-font">
          {viewer?.credits}
        </Typography>
      </div>
      <Button
        onClick={handleClick}
        size="icon"
        rounded="sm"
        className={cn({
          'w-auto': typeof features?.credit_button_content === 'string',
        })}
      >
        {btnContent}
      </Button>
    </div>
  );
};
