import * as Sentry from '@sentry/react';
import env from '@beam-australia/react-env';
import axios, {InternalAxiosRequestConfig} from 'axios';

import {authStorageGetters} from '@/entities/auth';

import {CONTENT_TYPE} from '@/shared/constants';

import {HttpError} from './http-error';

const BASE_URL = env('API_URL');

const httpClient = axios.create({
  baseURL: BASE_URL,
});

const requestFulfilledInterceptor = (config: InternalAxiosRequestConfig<any>) => {
  const contentType = config.headers['Content-Type'] ? config.headers['Content-Type'] : CONTENT_TYPE.JSON;

  const withAuth: boolean = config.headers.withAuth ?? true;

  config.headers['Access-Control-Allow-Credentials'] = true;
  config.headers['Content-Type'] = contentType;

  if (withAuth) {
    const token = authStorageGetters.getToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

const rejectedInterceptor = (error: any) => {
  const err = HttpError.create(error);
  Sentry.captureException(err.toJson());
  return Promise.reject(err);
};

httpClient.interceptors.request.use(requestFulfilledInterceptor, rejectedInterceptor);

httpClient.interceptors.response.use((response) => {
  return response;
}, rejectedInterceptor);

// const refreshAuth = () => {
//   return Promise.resolve('new generated token');
// };
//
// createAuthRefreshInterceptor(httpClient, refreshAuth, {
//   statusCodes: [403], // default: [ 401 ]
//   pauseInstanceWhileRefreshing: true,
// });

export { httpClient };
