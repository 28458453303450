import {AxiosError} from 'axios';

export class HttpError extends Error {
  constructor(
    public message: string,
    public code?: string,
    public status?: number,
    public url?: string,
  ) {
    super(message);
  }

  static create = (err: unknown) => {
    if (this.isAxiosError(err)) {
      let msg = 'Unknown error';

      if ((err.response?.data as any).detail && typeof (err.response?.data as any).detail === 'string') {
        msg = (err.response?.data as any).detail;
      } else if ((err.response?.data as any).error) {
        msg = (err?.response?.data as any).error;
      }

      return new HttpError(msg, err.code, err.response?.status, err.response?.config?.url);
    }

    if (this.isNativeError(err)) {
      return new HttpError(err.message, '', 400);
    }

    return new HttpError('Unknown error', 'Unknown error', 400);
  };

  static is = (err: unknown): err is HttpError => err instanceof HttpError;

  private static isNativeError = (err: unknown): err is Error => {
    return err instanceof Error;
  };

  private static isAxiosError = (err: unknown): err is AxiosError => {
    return err instanceof AxiosError;
  };

  public toJson = () => {
    return JSON.stringify({
      message: this.message,
      code: this.code,
      status: this.status,
      url: this.url,
    });
  };
}
