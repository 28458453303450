import {BaserQueryOptions, queryClient} from '@/core/config/react-query';
import {QUERY_KEYS} from '@/core/constants';
import {queryOptions, useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

import type {CharacterAdapted} from '@/entities/character';

import {Undefined} from '@/shared/types';

import {ConversationPreview, conversationRepository, CouponPack, Message} from '../repositories';

export const useGetConversations = (options?: BaserQueryOptions<ConversationPreview[]>) => {
  return useQuery({
    queryKey: [QUERY_KEYS.Conversations],
    queryFn: ({ signal }) => conversationRepository.getConversations(signal),
    ...options,
  });
};

export const invalidateGetConversations = () => {
  return queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.Conversations] });
};

export const useGetConversationById = (chatId: Undefined<string>) => {
  const { data, isLoading } = useGetConversations();

  return {
    conversation: data?.find((conversation) => conversation.id.toString() === chatId) ?? null,
    isLoading,
  };
};

export const getConversationMessagesQueryOptions = (
  chatId: string | undefined,
  options?: BaserQueryOptions<Message[]>,
) => {
  return queryOptions({
    enabled: Boolean(chatId),
    staleTime: 0,
    queryKey: [QUERY_KEYS.Messages, chatId],
    queryFn: () => conversationRepository.getConversationMessages(chatId as string),
    ...options,
  });
};

export const useGetConversationMessagesQuery = (chatId: string | undefined, options?: BaserQueryOptions<Message[]>) => {
  return useQuery(getConversationMessagesQueryOptions(chatId, options));
};

export const getCouponPacksQueryOptions = (options?: BaserQueryOptions<CouponPack[]>) => {
  return queryOptions({
    queryKey: [QUERY_KEYS.CouponPacks],
    queryFn: ({ signal }) => conversationRepository.getCouponPacks(signal),
    ...options,
  });
};

export const useGetCouponPacksQuery = (options?: BaserQueryOptions<CouponPack[]>) => {
  return useQuery(getCouponPacksQueryOptions(options));
};

export const useSendMessageMutation = () => {
  return useMutation({
    mutationFn: conversationRepository.sendMessage,
  });
};

export const useSendRandomMessageMutation = () => {
  return useMutation({
    mutationFn: conversationRepository.sendRandomMessage,
  });
};

export const useCreateConversationMutation = () => {
  return useMutation({
    mutationFn: conversationRepository.createConversation,
    onSuccess: async (data) => {
      await queryClient.setQueryData([QUERY_KEYS.Characters], (prev: CharacterAdapted[] = []) => {
        return prev.map((c) => {
          if (c.id === data.character_id)
            return {
              ...c,
              chat_id: data.id,
            };
          return c;
        });
      });
      await invalidateGetConversations();
    },
  });
};

export const useEditMessageMutation = () => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: conversationRepository.editMessage,

    onSuccess: (editedMessage) => {
      qc.setQueryData([QUERY_KEYS.Messages, editedMessage.chat_id.toString()], (prev: Message[] | undefined) => {
        const _prev = prev ?? [];
        return _prev.map((message) => (message.id === editedMessage.id ? editedMessage : message));
      });
    },
  });
};
