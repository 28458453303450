import {HTMLAttributes} from 'react';

import {cn} from '@/shared/libs/utils';

export interface Props extends HTMLAttributes<HTMLDivElement> {}

export const Slide = (props: Props) => {
  const { className, ...restProps } = props;

  return <div className={cn('min-w-0 grow shrink-0', className)} {...restProps} />;
};
