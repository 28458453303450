import {ComponentPropsWithoutRef, ElementRef, forwardRef} from 'react';

import {Root} from '@radix-ui/react-slider';

import {cn, mergeComponents} from '@/shared/libs/utils';

import {Range} from './range';
import {type SliderContextValue, SliderProvider} from './slider.context';
import {Thumb} from './thumb';
import {Track} from './track';

export interface Props extends ComponentPropsWithoutRef<typeof Root> {}

const _Slider = forwardRef<ElementRef<typeof Root>, Props>((props, ref) => {
  const { className, value, defaultValue, ...restProps } = props;

  const contextValue: SliderContextValue = {
    value,
    defaultValue,
  };

  return (
    <SliderProvider value={contextValue}>
      <Root
        ref={ref}
        className={cn('relative flex w-full touch-none select-none items-center', className)}
        value={value}
        defaultValue={defaultValue}
        {...restProps}
      />
    </SliderProvider>
  );
});

export const UiSlider = mergeComponents(_Slider, { Track, Thumb, Range, displayName: Root.displayName });
