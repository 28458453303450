import env from '@beam-australia/react-env';
import {type ApiInstance, initClient} from '@repo/api';

import {Nullable} from '@/shared/types';

export let client: ApiInstance;

export const createApiClient = (tokenExtractor?: () => Nullable<string>) => {
  const createdClient = initClient(env('API_URL'), {
    tokenExtractor,
  });

  client = createdClient;
  return createdClient;
};
