import {HeartBeat3D, HeartBeat3DKey} from './heart-beat-3d';
import {LineWobble, LineWobbleKey} from './line-wobble';
import {Simple, SimpleKey} from './simple';
import type {SpinnerProps} from './types';

export type SpinnerKey = typeof HeartBeat3DKey | typeof SimpleKey | typeof LineWobbleKey;

export interface Props extends SpinnerProps {
  k: SpinnerKey;
}

export const Spinner = (props: Props) => {
  const { k, ...restProps } = props;

  switch (k) {
    case HeartBeat3DKey:
      return <HeartBeat3D {...restProps} />;
    case SimpleKey:
      return <Simple {...restProps} />;
    case LineWobbleKey:
      return <LineWobble {...restProps} />;
    default:
      return null;
  }
};
