import {ButtonHTMLAttributes, ReactNode, useMemo} from 'react';

import {CURRENCY_CODE} from '@/shared/constants';
import {currencyConverter} from '@/shared/libs/currency-converters';
import {cn} from '@/shared/libs/utils';
import {Typography} from '@/shared/ui';

import './styles.css';
import {useBrandFeatures} from "@/entities/brand";

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  price: number;

  value: number;

  discount?: number;

  isBest?: boolean;

  isActive?: boolean;

  creditImg?: string | ReactNode;

  withSubscription?: boolean;

  currencyConverter?: (price: number) => string;
}

export const CreditItem = (props: Props) => {
  const {
    className,
    price,
    value,
    isBest,
    isActive,
    discount = 0,
    creditImg = null,
    withSubscription = false,
    ...restProps
  } = props;

  const creditValue = withSubscription ? value * 2 : value;

  const features = useBrandFeatures();

  const currencyCode = useMemo(() => {
    if (!features?.currency_code) {
      return CURRENCY_CODE.USD;
    }

    return features?.currency_code;
  }, [features?.currency_code]);

  const originalPrice = useMemo(() => {
    const decimalDiscount = discount / 100;
    return currencyConverter(currencyCode).format(price / 100 / (1 - decimalDiscount));
  }, [discount, price]);

  const discountPlacement = features?.credit_modal?.discount_placement ?? 'top';

  const discountEl = useMemo(() => {
    const formatterDiscount = discount > 0 ? `-${discount}%` : null;

    if (!formatterDiscount) {
      return null;
    }

    return (
      <div
        className={cn({
          'pb-3': discountPlacement === 'top',
          'pt-3': discountPlacement === 'bottom',
        })}
      >
        <Typography as="span" variant="2sm" weight="semibold" className={cn('discount-badge px-2.5 py-0.5 rounded-sm')}>
          {formatterDiscount}
        </Typography>
      </div>
    );
  }, [discount]);

  return (
    <button
      className={cn(
        'relative flex flex-col items-center p-3 bg-transparent border-2 border-grey-500 rounded-xl transition-all',
        {
          'border-brand-default': isActive,
          'hover:border-brand-default/50': !isActive,
        },
        className,
      )}
      {...restProps}
    >
      <span
        className={cn(
          'absolute inline-block top-0 left-1/2 -translate-y-1/2 -translate-x-1/2 text-xs font-medium py-0.5 px-2 rounded-full invisible opacity-0 transition-all',
          'bg-brand-default text-brand-default-content',
          {
            'visible opacity-1': isBest,
          },
        )}
      >
        Popular
      </span>

      {typeof creditImg === 'string' ? (
        <div className="size-14 flex items-center justify-center">
          <img src={creditImg} className="size-14" alt=""/>
        </div>
      ) : (
        <div className="h-[55px] flex items-center justify-center">{creditImg}</div>
      )}
      <Typography as="span" variant="3xl" weight="semibold" className="text-primary-font py-3">
        {creditValue}
      </Typography>
      {discountPlacement === 'top' && discountEl}
      <Typography as="p" className="inline-flex flex-col gap-1 text-primary-font">
        {discount > 0 && (
          <Typography as="span" variant="2sm" className="line-through">
            {originalPrice}
          </Typography>
        )}
        <Typography as="span" variant="sm" weight="semibold">
          {currencyConverter(currencyCode).format(price / 100)}
        </Typography>
      </Typography>
      {discountPlacement === 'bottom' && discountEl}
    </button>
  );
};
