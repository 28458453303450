import {memo} from 'react';

import {OnboardingStepHeader, type OnboardingStepHeaderProps, useOnboardingContext} from '@/entities/onboarding';

export interface Props extends Partial<OnboardingStepHeaderProps> {
}

export const OnboardingStageHeader = memo((props: Props) => {
  const {...restProps} = props;

  const {currentStep, totalSteps, availablePrevStep, prevStep} = useOnboardingContext();

  return (
    <OnboardingStepHeader
      currentStep={currentStep}
      totalSteps={totalSteps}
      hiddenPrevBtn={!availablePrevStep}
      onClickPrev={prevStep}
      {...restProps}
    />
  );
});
