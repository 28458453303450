import {create} from 'zustand';

import {createSelectors} from '@/shared/libs/zustand-helpers';

type State = {
  isPending: boolean;
  isReady: boolean;

  shared: Record<string, unknown>;
};

type Actions = {
  startLoad: (shared?: Record<string, unknown>) => void;
  finishLoad: (shared?: Record<string, unknown>) => void;
  patchShared: (shared: Record<string, unknown>) => void;
  reset: () => void;
};

const defaultState: State = {
  isPending: false,
  isReady: false,
  shared: {},
};

export const useReadyStateBase = create<State & Actions>()((set) => ({
  ...defaultState,
  startLoad: (shared?: Record<string, unknown>) =>
    set((state) => ({
      isPending: true,
      isReady: false,
      shared: { ...state.shared, ...shared },
    })),
  finishLoad: (shared?: Record<string, unknown>) =>
    set((state) => ({
      ...state,
      isPending: false,
      isReady: true,
      shared: { ...state.shared, ...shared },
    })),
  patchShared: (shared: Record<string, unknown>) => {
    set((state) => ({
      ...state,
      shared: { ...state.shared, ...shared },
    }));
  },
  reset: () => set(defaultState),
}));

export const useReadyState = createSelectors(useReadyStateBase);
