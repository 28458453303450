import {SubscriptionPlan} from '@/core/constants';
import {z} from 'zod';

import {Nullable} from '@/shared/types';

export type Viewer = {
  id: number;
  sub: string;
  created_at: string;
  credits: number;
  language: string;
  subscription_plan: SubscriptionPlan;
  subscription_expires_at: Nullable<string>;
  email: Nullable<string>;
  aud: Nullable<string>;
  auth_time: Nullable<string>;
  text_usage: number;
  voice_usage: number;
  image_usage: number;
  registration_id: Nullable<string>;
  has_deposits: false;
  app_version: string;
  split_test_groups: [];
  settings: ViewerSettings;
  is_subscribed: boolean;
  is_registered: boolean;
  rtkclickid_store: string;
};

export type ViewerSettings = {
  force_nsfw: boolean;
  onboarded: boolean;
};

export const UpdateMeSchema = z.object({
  email: z
    .string()
    .min(1, { message: 'Email must not be empty.' })
    .email({ message: 'Invalid email format.' })
    .optional(),
  registration_id: z.string().optional(),
  rtkclickid_store: z.string().optional(),
});

export type UpdateMeDto = z.infer<typeof UpdateMeSchema>;
