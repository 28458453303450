import {useState} from 'react';
import {useForm} from 'react-hook-form';

import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';

import {useGetMeQuery} from '@/entities/viewer';

export const paymentGatewaySchema = z.object({
  email: z
    .string({ required_error: 'The email field is required' })
    .email({ message: 'The specified email address is invalid' }),
});

export type PaymentGatewaySchema = z.infer<typeof paymentGatewaySchema>;

export interface PaymentGatewayFormControllerParams {
  onSubmitPayment?: (data: PaymentGatewaySchema) => Promise<void> | void;

  onFailedPayment?: (err?: string | unknown) => Promise<void> | void;

  email?: string;
}

export const usePaymentGatewayFormController = (params?: PaymentGatewayFormControllerParams) => {
  const { onSubmitPayment, onFailedPayment } = params || {};
  const [isPending, setIsPending] = useState(false);
  const me = useGetMeQuery();
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
  } = useForm<PaymentGatewaySchema>({
    resolver: zodResolver(paymentGatewaySchema),
    defaultValues: {
      email: me?.data?.email ?? '',
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    setIsPending(true);
    try {
      await onSubmitPayment?.(data);
      setIsPending(false);
      reset();
    } catch (error) {
      setIsPending(false);
      await onFailedPayment?.(error);
      return Promise.reject(error);
    }
  });

  const setEmailValue = (email: string) => {
    setValue('email', email);
  };

  return {
    control,
    errors,
    onSubmit,
    isPending,
    setEmailValue,
  };
};
