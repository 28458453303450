import {ComponentPropsWithoutRef, type CSSProperties, ElementRef, forwardRef} from 'react';

import * as ProgressPrimitive from '@radix-ui/react-progress';

import {cn} from '@/shared/libs/utils';

const Progress = forwardRef<
  ElementRef<typeof ProgressPrimitive.Root>,
  ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>
>((props, ref) => {
  const { className, value, style, ...restProps } = props;

  const styles: CSSProperties = {
    ...style,
    transform: `translateX(-${100 - (value ?? 0)}%)`,
  };

  return (
    <ProgressPrimitive.Root
      ref={ref}
      className={cn('relative h-1.5 w-full overflow-hidden rounded-full bg-grey-700', className)}
      {...restProps}
    >
      <ProgressPrimitive.Indicator
        className="h-full w-full flex-1 bg-grey-400 transition-transform duration-[660ms] ease-[cubic-bezier(0.65, 0, 0.35, 1)]"
        style={styles}
      />
    </ProgressPrimitive.Root>
  );
});

Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
