import {ReactNode} from 'react';

import AutoPlay from 'embla-carousel-autoplay';

import {
  Character,
  CharacterOverviewText,
  FullSizeCharacterContainer,
  FullSizeCharacterContainerProps,
} from '@/entities/character';

import {cn} from '@/shared/libs/utils';
import {Nullable} from '@/shared/types';
import {Carousel, PaginationProps} from '@/shared/ui';

export interface Props extends FullSizeCharacterContainerProps {
  character: Nullable<Character>;

  paginationVariant?: PaginationProps['variant'];

  renderInfo?: (character: Character) => ReactNode;
}

const PLUGINS = [AutoPlay({playOnInit: true, delay: 2000, stopOnInteraction: false})];

export const CharacterPreviewCarousel = (props: Props) => {
  const {character, className, paginationVariant = 'dash', renderInfo, ...restProps} = props;

  if (!character) return null;

  const images =
    character.public_pic_urls && character.public_pic_urls.length > 0
      ? character.public_pic_urls.map((url) => new URL(url).href)
      : [character.picture_url];

  return (
    <FullSizeCharacterContainer
      className={cn('flex flex-col justify-end rounded-xl overflow-hidden', className)}
      withShadowBottom
      {...restProps}
    >
      <div className="w-full h-full relative flex flex-col justify-end">
        <Carousel loop plugins={PLUGINS}>
          <Carousel.SlidesContainer className="absolute bottom-0 inset-x-0 w-full h-full " size="full" gap="none">
            {images.map((image) => {
              return (
                <Carousel.Slide key={image} className="grow-0 shrink-0 basis-full">
                  <div className="w-full h-full bg-center bg-cover" style={{backgroundImage: `url(${image})`}}/>
                </Carousel.Slide>
              );
            })}
          </Carousel.SlidesContainer>
          <div className="relative z-[2] px-4 pb-4 flex flex-col gap-4">
            {renderInfo ? (
              renderInfo(character)
            ) : (
              <CharacterOverviewText age={character.age} interests={character.interests ?? []} name={character.name}/>
            )}
            <Carousel.Pagination variant={paginationVariant}/>
          </div>
        </Carousel>
      </div>
    </FullSizeCharacterContainer>
  );
};
