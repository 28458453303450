import { useFeaturesBookContext } from './context';
import { useRef, useState } from 'react';
import { useLatest } from '../../hooks/use-latest';
import { useIsomorphicEffect } from '../../hooks/use-isomorphic-effect';

export const useInvalidateFeaturesBook = () => {
  const { invalidate } = useFeaturesBookContext();

  return invalidate;
};

export const useFeatures = () => {
  const { features } = useFeaturesBookContext();

  return features;
};

export function useFeature<T>(key: string, defaultValue?: T): T {
  const features = useFeatures();

  const [val, setVal] = useState<T>(defaultValue!);

  const getter = useLatest(() => {
    if (Object.keys(features).length === 0 || !(key in features)) {
      return defaultValue;
    }

    return features?.[key].value ?? defaultValue;
  });

  useIsomorphicEffect(() => {
    setVal(getter.current());
  }, [features]);

  return val;
}

export function useFeatureGetter<T>(key: string, defaultValue?: T): () => T {
  const features = useFeatures();

  const val = useRef<T>();

  const getter = useLatest(() => {
    if (Object.keys(features).length === 0 || !(key in features)) {
      return defaultValue;
    }

    if (val.current !== undefined) return val.current;

    val.current = features?.[key].value;

    return val.current ?? defaultValue;
  });

  return getter.current as () => T;
}

export function useFeaturesBookStatus() {
  const { status } = useFeaturesBookContext();
  return status;
}
