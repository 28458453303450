import {ExtractParams} from '@/shared/types';

const createPath = <T extends string>(root: T) => {
  return {
    root: `/${root}`,
    append: <P extends string>(path: P) => {
      return `${root}${path}` as `/${T}${P}`;
    },
  } as const;
};

const brandConfig = createPath('brand-config');
const stripe = createPath('stripe');
const fintm = createPath('fintm');
const constantpos = createPath('constantpos');
const paymentGateway = createPath('payment_gateway');
const epoch = createPath('epoch');
const user = createPath('user');
const chat = createPath('chat');
const auth = createPath('auth');
const system = createPath('system');
const character = createPath('character');
const message = createPath('message');
const constructor = createPath('constructor');

export const HTTP_DICTIONARY = {
  // ------------- auth
  token: auth.append('/token'),
  singUp: auth.append('/email/connect'),
  signIn: auth.append('/email/login'),
  resetPassword: auth.append('/email/reset'),
  confirm: auth.append('/email/confirm'),
  // ------------- brand config
  brandConfigWeb: brandConfig?.append('/web'),
  // ------------- character
  characters: character.root,
  generateCharacterPhoto: constructor.append('/photo'),
  // ------------- user
  getMe: user.append('/me'),
  updateMe: user.append('/update'),
  // ------------- chat
  conversations: chat.root,
  messages: chat.append('/:chatId/message'),
  sendMessage: chat.append('/:chatId/message'),
  editMessage: message.append('/:messageId/edit'),
  updateCharacter: chat.append('/:chatId/character'),
  resetCharacter: chat.append('/:chatId/character/reset'),
  randomMessage: chat.append('/:chatId/message/random'),
  // ------------- system
  uploadPhoto: system.append('/uploadphoto'),
  // ------------- stripe
  stripe: stripe.root,
  createSubscription: stripe.append('/create-subscription'),
  createCustomer: stripe.append('/create-customer'),
  couponPacks: stripe.append('/packs'),
  stripePrices: stripe.append('/prices'),
  paymentIntent: stripe.append('/create'),
  // ------------- fintm
  fintmPacks: fintm.append('/packs'),
  fintmPrices: fintm.append('/prices'),
  createFintmPayment: fintm.append('/create'),
  // ------------- constantpos
  constantposPacks: constantpos.append('/packs'),
  constantposPrices: constantpos.append('/prices'),
  createConstantposSubscriptionPayment: constantpos.append('/create_subscription_payment'),
  createConstantposUpiPayment: constantpos.append('/create_upi_payment'),
  // ------------- paymentgateway
  paymentGatewayPacks: paymentGateway.append('/packs'),
  paymentGatewayPrices: paymentGateway.append('/prices'),
  paymentGatewayCreate: paymentGateway.append('/create'),
  // ------------- epoch
  epochCredits: epoch.append('/credit-packs'),
  epochSubscription: epoch.append('/subscription-packs'),
  epochCreatePaymentIntent: epoch.append('/create-payment-intent'),

  replaceParams: <T extends string>(url: T, params: ExtractParams<T>) => {
    return Object.entries(params).reduce((acc: string, [key, value]) => acc.replace(`:${key}`, value as string), url);
  },
} as const;
