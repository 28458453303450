import {Children, type ReactNode} from 'react';

export interface Props<T> {
  each: T[];
  render: (item: T, index: number, arr: T[]) => ReactNode;
}

export const For = <T,>(props: Props<T>) => {
  const { each, render } = props;

  return Children.toArray(each.map((item, index, arr) => render(item, index, arr)));
};
