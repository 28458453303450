import {HTTP_DICTIONARY} from '@/core/constants';
import {httpClient} from '@/core/services/http';

import {UpdateMeDto, UpdateMeSchema, Viewer} from './models';

const getMe = (signal?: AbortSignal) => {
  return httpClient.get<Viewer>(HTTP_DICTIONARY.getMe, { signal }).then(({ data }) => data);
};

const updateMe = async (params: UpdateMeDto) => {
  const dto = UpdateMeSchema.parse(params);
  return httpClient.post<Viewer>(HTTP_DICTIONARY.replaceParams(HTTP_DICTIONARY.updateMe, {}), dto).then((r) => r.data);
};

export const viewerRepository = {
  getMe,
  updateMe,
} as const;
