import {CSSProperties} from 'react';

import Lottie from 'lottie-react';

import {cn} from '@/shared/libs/utils';

import './styles.scss';

type StatusAnimationProps = {
  animationData: any;
  loop: boolean;
  style?: CSSProperties;
  text: string;
};

export const StatusAnimation = (props: StatusAnimationProps) => {
  const { animationData, loop, style, text } = props;
  return (
    <div className="flex gap-1 items-center">
      <Lottie
        animationData={animationData}
        loop={loop}
        className={cn('lottie-animation h-6 w-6 background-brand-default')}
        style={style}
      />
      <span className="text-primary-font text-[11px] xs:text-base">{text}</span>
    </div>
  );
};
