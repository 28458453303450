import {StorageService} from '@/shared/services/storage';
import {Nullable} from '@/shared/types';

export enum SessionStorageKey {
  TOKEN = 'token',
}

interface ISessionStorage {
  [SessionStorageKey.TOKEN]: Nullable<string>;
}

export const sessionStorageService = new StorageService<ISessionStorage>(window.sessionStorage);
