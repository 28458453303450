import {createFileRoute, redirect} from '@tanstack/react-router';

export const Route = createFileRoute('/*')({
  component: () => <div></div>,
  beforeLoad: () => {
    throw redirect({
      to: '/404',
    });
  },
});
