import {useLayoutEffect} from 'react';

import {createFileRoute, useNavigate, useRouterState} from '@tanstack/react-router';

import {useCharactersQuery} from '@/entities/character';
import {useCreateConversationMutation} from '@/entities/conversation';
import {useBrandFeatures} from "@/entities/brand";

export const Route = createFileRoute('/chats/$characterId')({
  component: ChatIdComponent,
});

function ChatIdComponent() {
  const navigate = useNavigate();
  const {mutateAsync: createConversation} = useCreateConversationMutation();
  const {data: characters, isPending} = useCharactersQuery();

  const features = useBrandFeatures();

  const selected = useRouterState({
    select: (state) => state.location,
  });

  const {characterId} = Route.useParams();

  useLayoutEffect(() => {
    if (features?.deep_link && characters) {
      const character = characters?.find((character) => character.id === +characterId);
      const chatId = character?.chat_id;

      if (!chatId) {
        createConversation(characterId.toString(), {
          onSuccess: (conversation) => {
            if (!isPending) {
              navigate({to: '/conversations/$chatId', params: {chatId: conversation.id.toString()}});
            }
          },
          onError: () => {
            navigate({to: '/conversations'});
          },
        });
      }

      if (selected.pathname.includes('/chats') && chatId && !isPending) {
        navigate({to: '/conversations/$chatId', params: {chatId: chatId.toString()}});
      }
    }
  }, [characters]);
}
