import type {HTMLAttributes, ReactNode} from 'react';

import {cn} from '@/shared/libs/utils';

import {ScrollArea} from '../../scroll-area';
import {SplashScreen} from '../../splash-screen';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  isScrollable?: boolean;

  isLoading?: boolean;

  footer?: ReactNode;
}

export const Body = (props: Props) => {
  const { className, children, isLoading, footer, isScrollable, ...restProps } = props;

  const innerChildren = isScrollable ? (
    <ScrollArea className="flex flex-col h-px grow">{children}</ScrollArea>
  ) : (
    children
  );

  return (
    <div
      className={cn(
        'section-view-body relative grow',
        {
          'flex flex-col': isScrollable,
        },
        className,
      )}
      {...restProps}
    >
      {isLoading ? <SplashScreen className="absolute" spinnerKey="line-wobble" /> : innerChildren}
      {footer}
    </div>
  );
};
