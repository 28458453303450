import {SubscriptionPlan} from '@/core/constants';

import {useGetMeQuery} from './use-queries';

export const useHasSubscription = () => {
  const { data: viewer } = useGetMeQuery();
  return viewer?.is_subscribed || false;
};

export const useHasPremiumSubscription = () => {
  const { data: viewer } = useGetMeQuery();
  return viewer?.subscription_plan === SubscriptionPlan.PRO_PLUS || false;
};

export const useGetSubscriptionPlan = () => {
  const { data: viewer } = useGetMeQuery();
  return viewer?.subscription_plan ?? SubscriptionPlan.BASIC;
};
