import {CharacterPreviewCarousel} from '@/features/character-preview-carousel';

import {Character} from '@/entities/character';

import {Nullable} from '@/shared/types';
import {Button, Icon, Typography} from '@/shared/ui';
import {useBrandFeatures} from "@/entities/brand";

export interface Props {
  character: Nullable<Character>;

  onClickEdit?: () => void;
}

export const Preview = (props: Props) => {
  const {character, onClickEdit} = props;

  const features = useBrandFeatures();

  return (
    <div className="h-full flex flex-col gap-4 md:py-2 md:px-3">
      <CharacterPreviewCarousel
        character={character ?? null}
        className="relative -mt-[68px] tablet:mt-0 z-[-1] md:z-1
        rounded-tr-none rounded-tl-none tablet:rounded-xl
        h-[600px] md:h-[380px] lg:h-[520px] ring-0 grow"
      />
      <div className="flex flex-col gap-2 px-3 tablet:px-0">
        <div className="flex items-center justify-between">
          <Typography as="h5" weight="semibold" className="text-primary-font">
            Bio
          </Typography>

          {features?.change_face_btn && (
            <Button onClick={onClickEdit} w="auto" rounded="full" size="icon-sm" variant="outline">
              <Icon k="pen" className="text-[24px]"/>
            </Button>
          )}
        </div>
        <Typography as="p" className="text-secondary">
          {character?.bio}
        </Typography>
      </div>
    </div>
  );
};
