import {memo, useCallback, useMemo} from 'react';

import {DIALOG_KEYS} from '@/core/constants';
import {Link} from '@tanstack/react-router';
import sortBy from 'lodash.sortby';

import {ConversationItem} from '@/features/conversation-item';
import {authModalAction, BuySubscriptionModal} from '@/features/modals';

import {authStorageGetters, useWithAuth} from '@/entities/auth';
import {ConversationPreview, useConversationSidebarContext} from '@/entities/conversation';
import {useGetConversations} from '@/entities/conversation/libs/hooks';
import {useHasSubscription} from '@/entities/viewer';

import {cn} from '@/shared/libs/utils';
import {Analytic} from '@/shared/services/analytic';
import {dialog, SpinnerIcon} from '@/shared/ui';
import {ScrollArea} from '@/shared/ui/scroll-area';
import {useBrandFeatures} from "@/entities/brand";

export const CharacterConversationsList = memo(() => {
  const {data: conversations, isLoading} = useGetConversations();

  const userHasSubscription = useHasSubscription();

  const isAuth = useWithAuth();

  const isAuthenticated = authStorageGetters.getAuth().isAuthenticated;

  const features = useBrandFeatures();

  const {onCloseSidebar} = useConversationSidebarContext();

  const sortedConversationList = useMemo(
    (): ConversationPreview[] =>
      sortBy(conversations, [(o: ConversationPreview) => o.last_message?.created_at]).reverse(),
    [conversations],
  );

  const onOpenSubscription = useCallback(() => {
    dialog.open({
      key: DIALOG_KEYS.BUY_SUBSCRIPTION,
      component: <BuySubscriptionModal analyticData={{placement: 'pro_dialogs', type: 'subscription'}}/>,
      beforeOpen: () => {
        Analytic.subscribeFromMainScreenClick();
      },
    });
  }, []);

  const handleClickItem = useCallback(
    (conversation: ConversationPreview) => {
      Analytic.conversationClick({persona_id: conversation?.character_id, persona_name: conversation?.name});

      if (!isAuthenticated && isAuth() && !userHasSubscription && conversation.is_premium) {
        authModalAction.open(
          {
            onSuccess: onOpenSubscription,
            analyticData: {persona_id: conversation?.character_id, persona_name: conversation?.name},
          },
          {
            afterClose: onOpenSubscription,
          },
        );
        return;
      }

      if (!userHasSubscription && conversation.is_premium) {
        onOpenSubscription();
      } else {
        onCloseSidebar();
      }
    },
    [isAuthenticated, features, userHasSubscription],
  );

  return (
    <ScrollArea className="flex flex-col h-px grow">
      {isLoading && <SpinnerIcon className="absolute inset-0 m-auto text-primary"/>}
      <ul>
        {sortedConversationList.map((conversation) => {
          const isNotAllowed = !userHasSubscription && conversation.is_premium;
          return (
            <li key={conversation.id} className="relative">
              <Link
                disabled={isNotAllowed}
                to="/conversations/$chatId"
                params={{chatId: conversation.id.toString()}}
                onClick={() => handleClickItem(conversation)}
              >
                {({isActive}) => {
                  return (
                    <ConversationItem
                      status={conversation.status}
                      characterId={conversation.character_id}
                      lastMessage={conversation.last_message}
                      unreadCount={conversation.unread_count}
                      className={cn({'bg-grey-700': isActive})}
                    />
                  );
                }}
              </Link>
            </li>
          );
        })}
      </ul>
    </ScrollArea>
  );
});
