import {useForm} from 'react-hook-form';

import {zodResolver} from '@hookform/resolvers/zod';

import {useUpdateMeMutation} from '@/entities/viewer';
import {ViewerSchema, viewerSchema} from '@/entities/viewer/libs/schemas';

export interface Props {
  onSubmitted?: () => void;
}

export const useEmailFormController = (props: Props) => {
  const { onSubmitted } = props;

  const {
    handleSubmit,
    formState: { isDirty, errors, isValid },
    reset,
    control,
  } = useForm<ViewerSchema>({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(viewerSchema),
  });

  const { mutateAsync: updateMe } = useUpdateMeMutation();

  const onSubmit = handleSubmit(async ({ email }) => {
    try {
      await updateMe({
        email: email,
      });
      reset();
      onSubmitted?.();
      return await Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  });

  const isDisabledSendBtn = !isDirty || !isValid;

  return {
    control,
    isDisabledSendBtn,
    onSubmit,
    errors,
  };
};
