export enum DIALOG_KEYS {
  BUY_CURRENCY = 'BUY_CURRENCY',
  CREDITS_CHECKOUT = 'CREDITS_CHECKOUT',
  BUY_SUBSCRIPTION = 'BUY_SUBSCRIPTION',
  SUBSCRIPTION_CHECKOUT = 'SUBSCRIPTION_CHECKOUT',
  CONSTANT_PAYMENTS_CHECKOUT = 'CONSTANT_PAYMENTS_CHECKOUT',
  PAYMENT_GATEWAY_CHECKOUT = 'PAYMENT_GATEWAY_CHECKOUT',
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  PAYMENT_FAILURE = 'PAYMENT_FAILURE',
  NO_INTERNET = 'NO_INTERNET',
  RESET_CHARACTER_EDIT = 'RESET_CHARACTER_EDIT',
  PREVENT_PROGRESS_DELETE = 'PREVENT_PROGRESS_DELETE',
  AUTH = 'AUTH',
  EMAIL_CONFIRM = 'EMAIL_CONFIRM',
  PHOTO_VIEW = 'PHOTO_VIEW',
  WARNING_MODAL = 'WARNING_MODAL',
  RESET_PASSWORD = 'RESET_PASSWORD',
  SUCCESS_RESET_PASSWORD = 'SUCCESS_RECOVER_PASSWORD',
}
