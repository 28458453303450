import {HTTP_DICTIONARY} from '@/core/constants';
import {httpClient} from '@/core/services';

import {
  BaseConversation,
  ConversationPreview,
  CouponPack,
  EditMessageDto,
  Message,
  SendMessageDto,
  SendMessageSchema,
} from './models';

const getConversations = async (signal?: AbortSignal) => {
  return httpClient.get<ConversationPreview[]>(HTTP_DICTIONARY.conversations, { signal }).then((r) => r.data);
};

const getConversationMessages = async (chatId: string, signal?: AbortSignal) => {
  return httpClient
    .get<Message[]>(HTTP_DICTIONARY.replaceParams(HTTP_DICTIONARY.messages, { chatId }), { signal })
    .then((r) => r.data);
};

const getCouponPacks = async (signal?: AbortSignal) => {
  return httpClient.get<CouponPack[]>(HTTP_DICTIONARY.couponPacks, { signal }).then((r) => r.data);
};

const sendMessage = async (params: SendMessageDto) => {
  const dto = SendMessageSchema.parse(params);
  return httpClient
    .post<Message>(
      HTTP_DICTIONARY.replaceParams(HTTP_DICTIONARY.sendMessage, { chatId: params.chat_id.toString() }),
      dto,
    )
    .then((r) => r.data);
};

const sendRandomMessage = async (chatId: string) => {
  return httpClient
    .post<Message>(HTTP_DICTIONARY.replaceParams(HTTP_DICTIONARY.randomMessage, { chatId: chatId }))
    .then((r) => r.data);
};

const createConversation = async (characterId: string) => {
  return httpClient
    .post<BaseConversation>(HTTP_DICTIONARY.conversations, { character_id: characterId })
    .then((r) => r.data);
};

const editMessage = async (dto: EditMessageDto) => {
  return httpClient
    .post<Message>(
      HTTP_DICTIONARY.replaceParams(HTTP_DICTIONARY.editMessage, { messageId: dto.message_id.toString() }),
      dto,
    )
    .then((r) => r.data);
};

export const conversationRepository = {
  getConversations,
  getConversationMessages,
  getCouponPacks,
  sendMessage,
  sendRandomMessage,
  createConversation,
  editMessage,
} as const;
