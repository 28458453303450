import {Character, CharacterAdapted} from './models';

export const characterAdapter = (character: Character): CharacterAdapted => {
  if (!character.override) {
    return {
      ...character,
      public_pic_urls: character.public_pic_urls ?? [],
      interests: character.interests ?? [],
      character: {
        sexy_meter: 5,
        fun_meter: 5,
        mystic_meter: 5,
      },
    };
  }
  const { name, age, picture_url, bio, public_pic_urls = [], override, interests = [], ...restCharacter } = character;

  const {
    age: overrideAge,
    bio: overrideBio,
    name: overrideName,
    picture_url: overridePictureUrl,
    public_pic_urls: overridePublicPicUrls,
    interests: overrideInterests,
    ...restOverride
  } = override ?? {};

  return {
    ...restCharacter,
    override,
    interests: overrideInterests ?? (interests || []),
    public_pic_urls: overridePublicPicUrls ?? (public_pic_urls || []),
    bio: overrideBio ?? bio,
    name: overrideName ?? name,
    picture_url: overridePictureUrl ?? picture_url,
    age: overrideAge ?? age,
    character: {
      sexy_meter: 5,
      fun_meter: 5,
      mystic_meter: 5,
      ...(restOverride ?? {}),
    },
  };
};

export const charactersAdapter = (characters: Character[]) => {
  return characters.map((c) => characterAdapter(c));
};
