import {StorageService} from '@/shared/services/storage';
import {Nullable} from '@/shared/types';

export enum LocalStorageKey {
  Token = 'token',
  Consent = 'consent',
  IsPassOnboarding = 'isUserPassOnboarding',
  IsShowedPaymentModal = 'isShowedPaymentModal',
  AlreadyLaunched = 'alreadyLaunched',
  UserSub = 'userSub',
  Authenticated = 'authenticatedUser',
  AppSettings = 'appSettings',
  IsWarningModalShown = 'isWarningModalShown',
}

export interface AuthenticatedStorageDTO {
  isAuthenticated: boolean;
  timestamp: number;
}

export interface AppSettingsDTO {
  sentFirstMessage: boolean;
}

export interface ILocalStorageValues {
  [LocalStorageKey.Token]: Nullable<string>;
  [LocalStorageKey.Consent]: boolean;
  [LocalStorageKey.IsPassOnboarding]: boolean;
  [LocalStorageKey.IsShowedPaymentModal]: boolean;
  [LocalStorageKey.UserSub]: Nullable<string>;
  [LocalStorageKey.AlreadyLaunched]: boolean;
  [LocalStorageKey.Authenticated]: Nullable<AuthenticatedStorageDTO>;
  [LocalStorageKey.AppSettings]: Nullable<AppSettingsDTO>;
  [LocalStorageKey.IsWarningModalShown]: boolean;
}

export const localStorageService = new StorageService<ILocalStorageValues>(window.localStorage);
