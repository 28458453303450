import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryObserverOptions,
  UndefinedInitialDataOptions,
} from '@tanstack/react-query';

import {HttpError} from '../../services/http';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
    mutations: {
      retry: 0,
    },
  },

  queryCache: new QueryCache(),

  mutationCache: new MutationCache(),
});

export type QueryError = HttpError;

export type QueryOptions<TQueryFnData, TData = TQueryFnData, TError = HttpError> = Partial<
  QueryObserverOptions<TQueryFnData, TError, TData>
>;

export type BaserQueryOptions<TQueryFnData, TData = TQueryFnData, TError = HttpError> = Partial<
  UndefinedInitialDataOptions<TQueryFnData, TError, TData>
>;
