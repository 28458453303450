import {cloneElement, ReactNode, useLayoutEffect, useRef} from 'react';

import {cva, VariantProps} from 'class-variance-authority';
import {AlertTriangleIcon, BadgeCheck, Info} from 'lucide-react';

import {cn} from '@/shared/libs/utils';
import {ObjectKeys} from '@/shared/types';
import {Typography} from '@/shared/ui/common';

import {Button, ButtonProps} from '../../../new-button';
import {useDialogContext} from '../../libs';
import {CloseBtn} from '../_close-btn';
import {DialogLayout, type DialogLayoutProps} from '../_dialog-layout';

const alertType = {
  error: {
    icon: <AlertTriangleIcon />,
  },
  success: {
    icon: <BadgeCheck />,
  },
  warning: {
    icon: <AlertTriangleIcon />,
  },
  info: {
    icon: <Info />,
  },
} as const;

const alert = cva('w-full px-2 box-border', {
  variants: {
    size: {
      sm: 'max-w-[308px]',
      md: 'max-w-[408px]',
      lg: 'max-w-[508px]',
      xl: 'max-w-[608px]',
    },
  },

  defaultVariants: {
    size: 'md',
  },
});

export interface Props extends DialogLayoutProps, VariantProps<typeof alert> {
  type?: ObjectKeys<typeof alertType>;

  icon?: ReactNode;

  title: string;

  description?: string;

  footer?: ReactNode;

  withCloseButton?: boolean;

  /**
   * Delay before closing the alert
   * time in seconds
   *
   * Example:
   * 5 seconds === 5000
   * 60 seconds === 60000
   */
  closeDelay?: number;

  onOkClick?: () => void;

  onCancelClick?: () => void;

  onOkTitle?: string;

  onCancelTitle?: string;

  okBtnProps?: Omit<ButtonProps, 'onClick'>;

  cancelBtnProps?: Omit<ButtonProps, 'onClick'>;

  withActions?: boolean;
}

export const Alert = (props: Props) => {
  const {
    type = 'info',
    icon,
    className,
    size,
    title,
    description,
    footer,
    withCloseButton = true,
    closeDelay,
    onOkTitle,
    onCancelTitle,
    okBtnProps,
    cancelBtnProps,
    withActions,
    onOkClick,
    onCancelClick,
    onEventClose,
    children,
    ...restProps
  } = props;

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { onClose } = useDialogContext();

  const handleEventClose = () => {
    onCancelClick?.();
    onEventClose?.();
  };

  const footerComponent = () => {
    if (footer) return footer;

    if (!withActions) return null;

    return (
      <div className="flex items-center w-full gap-3">
        <Button className="grow" onClick={onCancelClick} variant="secondary" size="lg" {...cancelBtnProps}>
          {onCancelTitle ?? 'Cancel'}
        </Button>
        <Button className="grow" onClick={onOkClick} size="lg" {...okBtnProps}>
          {onOkTitle ?? 'Ok'}
        </Button>
      </div>
    );
  };

  useLayoutEffect(() => {
    if (typeof closeDelay === 'number' && closeDelay > 0) {
      timeoutRef.current = setTimeout(() => {
        onClose();
      }, closeDelay * 1000);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <DialogLayout
      onEventClose={handleEventClose}
      alwaysCentered
      fullWidth
      className={cn(alert({ size: size }), 'z-alert', className)}
      {...restProps}
    >
      <div className="flex flex-col items-center p-4 pt-8">
        {withCloseButton && <CloseBtn className="absolute right-3 top-3" variant="grey" iconSize="md" />}
        <div className="text-brand-default">
          {icon !== undefined ? icon : cloneElement(alertType[type].icon, { size: 56 })}
        </div>
        <div
          className={cn('flex flex-col text-center', {
            'pt-4': icon !== null,
            'pb-4': !footer,
          })}
        >
          <Typography as="h6" weight="semibold" variant="3xl" className="text-center">
            {title}
          </Typography>
          {description && (
            <Typography as="p" className="text-grey-200 text-center pt-4">
              {description}
            </Typography>
          )}
        </div>
        {footerComponent()}
        {children}
      </div>
    </DialogLayout>
  );
};
