import {ReactNode, useEffect} from 'react';

import useEmblaCarousel from 'embla-carousel-react';

import {mergeComponents} from '@/shared/libs/utils';

import {CarouselContextValue, CarouselOptions, CarouselPlugins, CarouselProvider} from './carousel.context';
import {Pagination} from './pagination';
import {Slide} from './slide';
import {SlidesContainer} from './slides-container';

export interface Props {
  axis?: CarouselOptions['axis'];
  align?: CarouselOptions['align'];
  dragFree?: CarouselOptions['dragFree'];
  loop?: CarouselOptions['loop'];
  options?: Omit<CarouselOptions, 'axis' | 'dragFree' | 'align' | 'loop'>;
  plugins?: CarouselPlugins;
  children?: ReactNode;
}

const _Carousel = (props: Props) => {
  const { options, plugins, axis = 'x', dragFree, align = 'start', loop, children } = props;

  const _options: CarouselOptions = {
    axis,
    dragFree,
    align,
    loop,
    ...options,
  };

  const { 0: emblaRef, 1: emblaApi } = useEmblaCarousel({ ..._options }, plugins);

  const value: CarouselContextValue = {
    options: _options,
    carouselApi: emblaApi,
    carouselRef: emblaRef,
  };

  useEffect(() => {}, [emblaApi]);

  return <CarouselProvider value={value}>{children}</CarouselProvider>;
};

export const Carousel = mergeComponents(_Carousel, {
  SlidesContainer,
  Slide,
  Pagination,
});
