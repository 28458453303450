import {useLayoutEffect} from 'react';

import {BuyCredits} from '@/features/modals/components';

import {useBrandFeatures} from '@/entities/brand';
import {ModalPreviewImg} from '@/entities/modals';

import {Analytic} from '@/shared/services/analytic';
import {Modal, ModalRootProps, useDialogContext} from '@/shared/ui';

export interface Props extends ModalRootProps {
  characterId?: number;

  characterName?: string;

  currencyList?: any[];

  onClickBanner?: () => void;
}

export const BuyCreditsModal = (props: Props) => {
  const {characterId, characterName, onClickBanner, ...restProps} = props;

  const features = useBrandFeatures();

  const {onClose} = useDialogContext();

  useLayoutEffect(() => {
    Analytic.paywallScreenView({placement: 'chat_coupons', persona_id: characterId, persona_name: characterName});
  }, []);

  return (
    <Modal.Root bodyClassName="sm:h-[508px] sm:max-w-[740px] w-full h-full" {...restProps}>
      <Modal.Content className="flex flex-col sm:flex-row h-full">
        <Modal.CloseBtn className="absolute top-4 left-4"/>
        <ModalPreviewImg
          imgSrc={features?.credit_modal.image_url ?? ''}
          className="absolute z-[1] w-full h-[calc(100%-405px)] sm:w-1/2 sm:h-full"
        />
        <div className="basis-2/3  sm:basis-1/2 grow"/>
        <BuyCredits onClose={onClose} isBannerVisible={true} onClickBanner={onClickBanner} characterId={characterId}/>
      </Modal.Content>
    </Modal.Root>
  );
};
