import {ConstantPaymentsForm} from '@/features/modals/form';

import {cn} from '@/shared/libs/utils';
import {Modal} from '@/shared/ui';

import {ConstantPaymentSchema} from '../../form/constant-payments-form/constant-payments-form.controller';

export interface Props {
  onSubmitPayment?: (data: ConstantPaymentSchema) => void;

  onSuccessPayment?: () => void;

  className?: string;
}

export const ConstantPaymentsModal = (props: Props) => {
  const { className, onSubmitPayment, onSuccessPayment, ...restProps } = props;

  return (
    <Modal.Root className={cn('md:max-w-[512px]', className)} fullWidth {...restProps}>
      <Modal.Header label="Checkout" />
      <Modal.Content className="min-h-[200px] px-6 pb-6 transition-[height]">
        <ConstantPaymentsForm onSubmitPayment={onSubmitPayment} onSuccessPayment={onSuccessPayment} />
      </Modal.Content>
    </Modal.Root>
  );
};
