import type {ObjectValues} from '@/shared/types';

export const EVENT_COLLECTION = {
  first_launch: 'first_launch',
  discover_screen_view: 'discover_screen_view',
  dialogs_list_click: 'dialogs_list_click',
  settings_click: 'settings_click',
  quiz_onboarding_step_view: 'quiz_onboarding_{{step}}_view',
  quiz_onboarding_delete_progress_view: 'quiz_onboarding_delete_progress_view',
  quiz_onboarding_persona_ready_view: 'quiz_onboarding_persona_ready_view',
  dialogs_list_screen_view: 'dialogs_list_screen_view',
  dialog_click: 'dialog_click',
  discover_click: 'discover_click',
  checkout_success: 'checkout_success',
  checkout_success_email_sent_click: 'checkout_success_email_sent_click',
  subscribe_from_main_screen_click: 'subscribe_from_main_screen_click',
  subscribe_from_settings_screen_click: 'subscribe_from_settings_screen_click',
  settings_screen_view: 'settings_screen_view',
  cancel_subscription_click: 'cancel_subscription_click',
  chat_screen_view: 'chat_screen_view',
  something_went_wrong: 'something_went_wrong',
  chat_coupons_balance_click: 'chat_coupons_balance_click',
  photo_unblur_click: 'photo_unblur_click',
  send_message_click: 'send_message_click',
  subscribe_from_coins_paywall_click: 'subscribe_from_coins_paywall_click',
  persona_profile_view: 'persona_profile_view',
  edit_profile_click: 'edit_profile_click',
  persona_edit_profile_view: 'persona_edit_profile_view',
  add_face_click: 'add_face_click',
  finish_edit_profile_click: 'finish_edit_profile_click',
  paywall_screen_view: 'paywall_screen_view',
  paywall_skip_click: 'paywall_skip_click',
  add_to_cart: 'add_to_cart',
  begin_checkout: 'begin_checkout',
  process_payment_click: 'process_payment_click',
  close_checkout_click: 'close_checkout_click',
  purchase: 'purchase',
  purchase_failed: 'purchase_failed',
  discover_no_profiles_left: 'discover_no_profiles_left',
  ab_test: 'ab_test',
  signup_screen_view: 'signup_screen_view',
  signup_create_account_click: 'signup_create_account_click',
  login_screen_view: 'login_screen_view',
  login_click: 'login_click',
  login_error: 'login_error',
  first_touch_url: 'first_touch_url',
  create_ai_click: 'create_ai_click',
  create_ai_step_view: 'create_ai_{{step}}_view',
  create_ai_step_continue_click: 'create_ai_{{step}}_continue_click',
  create_ai_persona_ready_view: 'create_ai_persona_ready_view',
  create_ai_persona_ready_continue_click: 'create_ai_persona_ready_continue_click',
  create_ai_persona_inprogress_view: 'create_ai_persona_inprogress_view',
  push_notification_enabled: 'push_notification_enabled',
  push_notification_disabled: 'push_notification_disabled',
  push_open: 'push_open',
} as const;

export type EventCollectionName = ObjectValues<typeof EVENT_COLLECTION>;
