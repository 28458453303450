import {MouseEventHandler} from 'react';

import {DIALOG_KEYS} from '@/core/constants';

import {authModalAction} from '@/features/modals';

import {authStorageGetters, useWithAuth} from '@/entities/auth';
import {useHasSubscription} from '@/entities/viewer';

import {Button, ButtonProps, dialog, Icon} from '@/shared/ui';

import {BuySubscriptionModal, Props as BuyCreditsModalProps} from './BuySubscriptionModal';
import {useBrandFeatures} from "@/entities/brand";

export interface Props extends ButtonProps {
  subscriptionVariant?: BuyCreditsModalProps['subscriptionVariant'];

  placementScreen?: string;

  label?: string;

  analyticData?: BuyCreditsModalProps['analyticData'];
}

export const BuySubscriptionModalTrigger = (props: Props) => {
  const {subscriptionVariant, label = 'Subscribe', analyticData, onClick, ...restProps} = props;

  const isAuthenticated = authStorageGetters.getAuth().isAuthenticated;

  const withSubscription = useHasSubscription();

  const isAuth = useWithAuth();

  const features = useBrandFeatures();

  const onOpenSubscriptionModal = () => {
    dialog.open({
      key: DIALOG_KEYS.BUY_SUBSCRIPTION,
      component: <BuySubscriptionModal subscriptionVariant={subscriptionVariant} analyticData={analyticData}/>,
    });
  };

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (isAuth() && !withSubscription && !isAuthenticated) {
      authModalAction.open(
        {
          onSuccess: onOpenSubscriptionModal,
        },
        {
          afterClose: onOpenSubscriptionModal,
        },
      );
    } else {
      onOpenSubscriptionModal();
    }
    onClick?.(event);
  };

  const icon = features?.subscription_btn_icon?.icon ?? null;

  return (
    <Button w="auto" onClick={handleClick} {...restProps}>
      <span className="flex items-center gap-1">
        {icon && <Icon k={icon} className="text-3xl"/>} {label}
      </span>
    </Button>
  );
};
