import type {HTMLAttributes, ReactNode} from 'react';

import {cn} from '@/shared/libs/utils';
import {Typography} from '@/shared/ui';

import '../../styles.scss';

export interface Props extends HTMLAttributes<HTMLElement> {
  label: ReactNode;

  timer?: ReactNode;

  onClick: () => void;
}

export const Layout = (props: Props) => {
  const {label, timer, onClick, className, style, ...restProps} = props;

  return (
    <div
      onClick={onClick}
      className={cn('bg-cover bg-no-repeat flex justify-between py-2 px-3 items-center', className)}
      style={style}
      {...restProps}
    >
      <div className="discount-banner-label flex items-center w-fit h-10 rounded-md px-3">
        <Typography as="span" weight="semibold" variant="2xl" className="flex items-center">
          {label}
        </Typography>
      </div>
      {timer && <div className="discount-timer w-[144px] h-10 flex items-center p-3 rounded-md">{timer}</div>}
    </div>
  );
};
