import {HTMLAttributes} from 'react';

import {cn} from '@/shared/libs/utils';

export interface Props extends HTMLAttributes<HTMLDivElement> {}

export const Footer = (props: Props) => {
  const { className, ...restProps } = props;

  return <div className={cn('pb-3 px-3 bg-inherit min-h-[56px]', className)} {...restProps} />;
};
