import {useMemo, useState} from 'react';

import Ed from '@/assets/icons/edit_outline.svg?react';
import {DIALOG_KEYS, SubscriptionTier} from '@/core/constants';

import {BuySubscriptionModal} from '@/features/modals';

import {useCharacterByChatId, useResetCharacterMutation} from '@/entities/character';
import {useConversationDrawerContext} from '@/entities/conversation';
import {useHasPremiumSubscription} from '@/entities/viewer';

import {useDisclosure} from '@/shared/hooks';
import {nextRenderTick} from '@/shared/libs/utils';
import {Analytic} from '@/shared/services/analytic';
import {Alert, dialog} from '@/shared/ui';


interface Props {
  chatId?: string;
}

export const useChatDrawerController = (props?: Props) => {
  const {chatId} = props ?? {};

  const {
    0: isEdit,
    1: {onToggle, onClose: onExitEdit},
  } = useDisclosure();

  const {0: isDirtyEdit, 1: setIsDirtyEdit} = useState(false);

  const {onClose} = useConversationDrawerContext();

  const hasPremiumSubscription = useHasPremiumSubscription();

  const {character} = useCharacterByChatId(chatId);

  const {mutateAsync: resetCharacter} = useResetCharacterMutation();

  const reset = async () => {
    if (!chatId) return;
    await resetCharacter(chatId);
    dialog.close({key: DIALOG_KEYS.RESET_CHARACTER_EDIT});
    nextRenderTick(onExitEdit, 500);
  };

  const handleClickReset = () => {
    dialog.open({
      key: DIALOG_KEYS.RESET_CHARACTER_EDIT,
      component: (
        <Alert
          icon={null}
          title="Reset settings"
          description="Are you sure you want to reset your profile settings?"
          onOkClick={reset}
          onCancelClick={() => dialog.close({key: DIALOG_KEYS.RESET_CHARACTER_EDIT})}
          withActions
        />
      ),
    });
  };

  const handleClickEdit = async () => {
    if (!isEdit) {
      Analytic.editProfileCLick();
      onToggle();
      return;
    }

    if (!chatId) return;

    onExitEdit();
  };

  const handleCancelClick = () => {
    if (!hasPremiumSubscription) {
      dialog.open({
        key: DIALOG_KEYS.BUY_SUBSCRIPTION,
        component: <BuySubscriptionModal subscriptionVariant={SubscriptionTier.PRO_PLUS}/>,
      });
    }
    dialog.close({key: DIALOG_KEYS.RESET_CHARACTER_EDIT});
  };

  const handleOkClick = () => {
    dialog.close({key: DIALOG_KEYS.RESET_CHARACTER_EDIT});
    nextRenderTick(onExitEdit, 500);
  };

  const handleCloseFromEdit = () => {
    if (isDirtyEdit) {
      dialog.open({
        key: DIALOG_KEYS.RESET_CHARACTER_EDIT,
        component: (
          <Alert
            type="warning"
            title="Your changes will not be saved"
            description="Are you really sure to leave?"
            withActions
            onCancelClick={handleCancelClick}
            onOkClick={handleOkClick}
          />
        ),
      });
    } else {
      onExitEdit();
    }
  };

  const handleClose = () => {
    if (isEdit) {
      handleCloseFromEdit();
    } else {
      onClose();
    }
  };

  const btnLabel = useMemo(() => {
    if (!isEdit) return 'Edit Profile';

    return 'Reset';
  }, [character, isEdit]);

  const btnDisabled = useMemo(() => {
    if (!isEdit) return false;

    return !Boolean(character?.default);
  }, [isEdit, character]);

  const btnChevron = useMemo(() => {
    if (!isEdit) return <Ed className="size-5 mr-1"/>;

    return null;
  }, [isEdit]);

  return {
    character,
    isDirtyEdit,
    setIsDirtyEdit,
    isEdit,
    btnLabel,
    btnChevron,
    btnDisabled,
    onExitEdit,
    handleClose,
    handleClickEdit,
    handleClickReset,
  };
};
