import {ComponentProps} from 'react';

import {cn} from '@/shared/libs/utils';

import './styles.css';

export const SpinnerIcon = ({ className }: ComponentProps<'svg'>) => (
  <svg className={cn('spinner relative size-5', className)} viewBox="0 0 50 50">
    <circle className="path" stroke="currentColor" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
  </svg>
);
