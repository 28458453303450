import {SubscriptionTier} from '@/core/constants';

import {useTestGroupPaywallGifEnabled} from '@/entities/ab_test';
import type {BrandFeaturesModel, SubscriptionBenefitModel} from '@/entities/brand';
import {useGetMeQuery} from '@/entities/viewer';
import {SUBSCRIPTIONS_VIEW} from "@/entities/subscription";

import {PremiumBadge} from '@/shared/ui';

import {cva} from "class-variance-authority";

const mapBenefits = (benefits: SubscriptionBenefitModel[]) => {
  return benefits.map((benefit) => {
    return {
      key: benefit.key,
      icon: benefit.icon,
      label: benefit.label,
    };
  });
};

export const subscriptionsContent = (features?: BrandFeaturesModel) => {
  const { data: viewer } = useGetMeQuery();

  const isGifEnabled = useTestGroupPaywallGifEnabled(viewer?.split_test_groups) && features?.ab_test_gif_on_paywall;

  return {
    [SubscriptionTier.PRO]: {
      key: SubscriptionTier.PRO,
      picture: isGifEnabled ? features?.subscription_gif?.pro : features?.subscription_image?.pro,
      title: {
        [SUBSCRIPTIONS_VIEW.DEFAULT]:
          features?.subscription_appearance_variant === 'badge' ? (
          <>
            Unlock <PremiumBadge size="lg" label="PRO" /> Features
          </>
        ) : (
          'Unlock Premium Features'
        ),
        [SUBSCRIPTIONS_VIEW.SQUARE]: 'Get Premium Access'
      },
      benefits: mapBenefits(features?.subscription_benefits?.PRO ?? []),
      bottomText: features?.subscription_bottom_text?.pro,
      button: {
        [SUBSCRIPTIONS_VIEW.DEFAULT]: 'Continue',
        [SUBSCRIPTIONS_VIEW.SQUARE]: 'Get Premium',
      },
      isDiscountBanner: {
        [SUBSCRIPTIONS_VIEW.DEFAULT]: true,
        [SUBSCRIPTIONS_VIEW.SQUARE]: false
      }
    },
    [SubscriptionTier.PRO_PLUS]: {
      key: SubscriptionTier.PRO_PLUS,
      picture: isGifEnabled ? features?.subscription_gif?.pro_plus : features?.subscription_image?.pro_plus,
      title: {
        [SUBSCRIPTIONS_VIEW.DEFAULT]:
          features?.subscription_appearance_variant === 'badge' ? (
          <>
            Unlock <PremiumBadge size="lg" label="VIP" intent="secondary" /> Features
          </>
        ) : (
          'Unlock VIP Access'
        ),
        [SUBSCRIPTIONS_VIEW.SQUARE]: 'Get Premium Access'
      },
      benefits: mapBenefits(features?.subscription_benefits?.PRO_PLUS ?? []),
      bottomText: features?.subscription_bottom_text?.pro_plus,
      button: {
        [SUBSCRIPTIONS_VIEW.DEFAULT]: 'Continue',
        [SUBSCRIPTIONS_VIEW.SQUARE]: 'Get Vip',
      },
      isDiscountBanner: {
        [SUBSCRIPTIONS_VIEW.DEFAULT]: true,
        [SUBSCRIPTIONS_VIEW.SQUARE]: false
      }
    },
  };
};

export const radioGroupStyles = cva(
  'flex gap-2',
  {
    variants: {
      subscriptionView: {
        [SUBSCRIPTIONS_VIEW.DEFAULT]: 'flex-col',
        [SUBSCRIPTIONS_VIEW.SQUARE]: 'flex-row grow-0  py-5',
      },
    },
    defaultVariants: {
      subscriptionView: SUBSCRIPTIONS_VIEW.DEFAULT,
    },
  }
);

export const titleStyles = cva(
  'px-3 flex gap-1 items-center text-white',
  {
    variants: {
      subscriptionView: {
        [SUBSCRIPTIONS_VIEW.DEFAULT]: 'text-primary-font',
        [SUBSCRIPTIONS_VIEW.SQUARE]: 'text-4xl',
      },
    },
    defaultVariants: {
      subscriptionView: SUBSCRIPTIONS_VIEW.DEFAULT,
    },
  }
);
