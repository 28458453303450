import {HTMLAttributes} from 'react';

import * as Avatar from '@radix-ui/react-avatar';
import {CharacterStatus} from '@/core/constants';

import {ConversationItemStatus} from '@/entities/conversation';

import {cn} from '@/shared/libs/utils';
import {Typography} from '@/shared/ui';

export interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  name: string;

  imgSrc?: string;

  status: CharacterStatus;
}

export const CharacterChatBadge = (props: Props) => {
  const {className, name, imgSrc, status, ...restProps} = props;

  return (
    <div className={cn('flex items-center gap-2.5 cursor-pointer', className)} {...restProps}>
      <Avatar.Root className="rounded-avatar-xs size-10 overflow-hidden">
        <Avatar.Image className="h-full w-full rounded-avatar-xs object-cover " src={imgSrc} alt={name}/>
        <Avatar.Fallback className="flex items-center justify-center">{name.slice(0, 2).toUpperCase()}</Avatar.Fallback>
      </Avatar.Root>
      <div className="flex flex-col justify-between gap-1">
        <Typography as="span" weight="semibold" className="text-primary-font leading-none">
          {name}
        </Typography>
        {status > CharacterStatus.ONLINE ? (
          <ConversationItemStatus status={status}/>
        ) : (
          <Typography as="span" weight="medium" variant="2sm" className="text-primary-font/70">
            Online
          </Typography>
        )}
      </div>
    </div>
  );
};
