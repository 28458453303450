import { useLayoutEffect, useState } from 'react';
import loadable from '@loadable/component';
import { useCreateSubscriptionMutation } from '@/entities/subscription';
import { cn } from '@/shared/libs/utils';
import { Modal, ModalRootProps, SpinnerIcon } from '@/shared/ui';
import { ScrollArea } from '@/shared/ui/scroll-area';

const CheckoutForm = loadable(() => import('../../form'), {
  resolveComponent: (module) => module.CheckoutForm,
});

export interface Props extends ModalRootProps {
  priceId: string;

  onSuccessPayment?: () => void;

  onFailedPayment?: (err: string | undefined) => void;
}

export const SubscriptionCheckoutModal = (props: Props) => {
  const { priceId, className, onSuccessPayment, onFailedPayment, ...restProps } = props;

  const [clientSecret, setClientSecret] = useState<string | null>(null);

  const createSubscription = useCreateSubscriptionMutation();

  const onInit = async () => {
    try {
      const res = await createSubscription.mutateAsync({ priceId });
      setClientSecret(res.clientSecret);
    } catch (err) {
      if (onFailedPayment) {
        onFailedPayment(err as string);
      }
    }
  };

  useLayoutEffect(() => {
    onInit();
  }, []);

  return (
    <Modal.Root className={cn('md:max-w-[512px]', className)} fullWidth {...restProps}>
      <Modal.Header label="Checkout" />
      <Modal.Content className="min-h-[200px] px-6 pb-6 transition-[height]">
        <ScrollArea className="flex flex-col h-full grow pb-2">
          {!createSubscription.isPending && clientSecret && (
            <CheckoutForm
              onSuccessPayment={onSuccessPayment}
              onFailedPayment={onFailedPayment}
              clientSecret={clientSecret}
            />
          )}
        </ScrollArea>
      </Modal.Content>
      {!clientSecret && (
        <span className="absolute w-full h-full bg-black/10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center justify-center">
          <SpinnerIcon className="text-primary size-5" />
        </span>
      )}
    </Modal.Root>
  );
};
