import {HTMLAttributes} from 'react';

import {cn} from '@/shared/libs/utils';

import {Typography} from '../../../../common';
import {CloseBtn} from '../../_close-btn';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  label?: string;
  withCloseBtn?: boolean;
}

export const Header = (props: Props) => {
  const { label, className, children, withCloseBtn = true, ...restProps } = props;

  return (
    <div className={cn('flex justify-between items-center px-6 pt-6', className)} {...restProps}>
      <div>
        {label && (
          <Typography variant="4xl" weight="bold">
            {label}
          </Typography>
        )}
        {children}
      </div>
      {withCloseBtn && <CloseBtn iconSize="md" variant="grey" />}
    </div>
  );
};
