import {BaserQueryOptions, queryClient} from '@/core/config/react-query';
import {QUERY_KEYS} from '@/core/constants';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

import {invalidateGetConversations} from '@/entities/conversation';

import {Undefined} from '@/shared/types';

import {CharacterAdapted, characterRepository} from '../repository';

// export const charactersQueryOptions = <T = CharacterAdapted[]>(options?: BaserQueryOptions<CharacterAdapted[], T>) =>
//   queryOptions();

export const useCharactersQuery = <T = CharacterAdapted[]>(options?: BaserQueryOptions<CharacterAdapted[], T>) => {
  return useQuery({
    queryKey: [QUERY_KEYS.Characters],
    queryFn: ({ signal }) => characterRepository.getCharacters(signal),
    ...options,
  });
};

export const useSelectCharacters = () => {
  const { data } = useCharactersQuery({
    enabled: false,
  });

  return data ?? [];
};

export const invalidateCharactersQuery = () => {
  return queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.Characters] });
};

export const useCharacterById = (characterId: Undefined<number>) => {
  const { data, isLoading } = useCharactersQuery();

  const character = characterId ? data?.find((c) => c.id === characterId) : null;

  return { character, isLoading };
};

export const useCharacterByChatId = (chatId: number | string | undefined) => {
  const { data: characters, isPending } = useCharactersQuery();

  const character = chatId
    ? characters?.find((c) => {
        if (!c.chat_id) return false;

        return c.chat_id.toString() === chatId;
      })
    : null;

  return {
    characters,
    character: character ?? null,
    isPending,
  };
};

export const characterQuerySelectors = {
  characterById: (characterId: number | string | undefined) => (characters: CharacterAdapted[]) => {
    return characters.find((c) => c.id.toString() === characterId) ?? null;
  },
} as const;

export const useResetCharacterMutation = () => {
  return useMutation({
    mutationFn: characterRepository.resetCharacter,
    onSuccess: async () => {
      await invalidateGetConversations();
      await invalidateCharactersQuery();
    },
  });
};

export const useUpdateCharacterMutation = () => {
  return useMutation({
    mutationFn: characterRepository.updateCharacter,
    onSuccess: async () => {
      await invalidateGetConversations();
      await invalidateCharactersQuery();
      // qc.setQueryData<CharacterAdapted[]>([QUERY_KEYS.Characters], (prevData) => {
      //   if (!prevData) return [];
      //   return prevData.map((character) => {
      //     if (!character.chat_id) return character;
      //     if (params.chatId === character.chat_id.toString()) {
      //       const { age, bio, name, interests, public_pic_urls, picture_url, ...restProps } = response;
      //       const override = { age, bio, name, interests, public_pic_urls, picture_url } ?? {};
      //       return {
      //         ...Object.assign(character, removeNullUndefined(override)),
      //         override,
      //         is_default: false,
      //         character: {
      //           ...character.character,
      //           ...restProps,
      //         },
      //       };
      //     }
      //     return character;
      //   });
      // });
    },
  });
};

export const useCreateCharacterMutation = () => {
  return useMutation({
    mutationFn: characterRepository.createCharacter,
  });
};

export const useGenerateCharacterPhotoMutation = () => {
  return useMutation({
    mutationFn: characterRepository.generateCharacterPhoto,
  });
};

export const useUploadCharacterPhotoMutation = () => {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: characterRepository.uploadCharacterPhoto,
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: [QUERY_KEYS.Characters] });
    },
  });
};
