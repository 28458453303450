import {HTMLAttributes} from 'react';

import {cn, mergeComponents} from '@/shared/libs/utils';
import {SpinnerIcon} from '@/shared/ui';

import {ConversationLayoutContent} from './content';
import {ConversationLayoutFooter} from './footer';
import {ConversationLayoutHeader} from './header';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  isLoading?: boolean;
}

const _ConversationLayout = (props: Props) => {
  const { className, isLoading, children, ...restProps } = props;

  return (
    <div
      className={cn('h-full flex flex-col bg-body justify-between grow [--header-height=65px]', className)}
      {...restProps}
    >
      {isLoading ? <SpinnerIcon className="m-auto text-accent-primary size-10" /> : children}
    </div>
  );
};

export const ConversationLayout = mergeComponents(_ConversationLayout, {
  Header: ConversationLayoutHeader,
  Content: ConversationLayoutContent,
  Footer: ConversationLayoutFooter,
});
