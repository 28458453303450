import {useCallback, useMemo} from 'react';

import * as RadioGroup from '@radix-ui/react-radio-group';
import { VariantProps } from 'class-variance-authority';

import { useBrandFeatures } from '@/entities/brand';
import {SUBSCRIPTION_PERIOD, SUBSCRIPTIONS_VIEW} from '@/entities/subscription';

import { CURRENCY_CODE } from '@/shared/constants';
import { currencyConverter } from '@/shared/libs/currency-converters';
import { cn } from '@/shared/libs/utils';
import { Typography } from '@/shared/ui';

import {
  bestValueStyles,
  priceRadioBtn,
  priceLabelStyles,
  radioBtnStyles,
  discountBadgeStyles,
  priceLabelItemStyles,
  priceStyles
} from "./price-radio-btn.config";
import './styles.css';

export interface Props extends RadioGroup.RadioGroupItemProps, VariantProps<typeof priceRadioBtn> {
  period: SUBSCRIPTION_PERIOD;

  price: number;

  discount: number;

  currencyCode: CURRENCY_CODE;

  subscriptionView: SUBSCRIPTIONS_VIEW;
}

export const PriceRadioBtn = (props: Props) => {
  const {className, isActive, isBest, period, price, discount = 0, currencyCode, subscriptionView, ...restProps} = props;

  const features = useBrandFeatures();

  const priceLabel = useMemo(() => {
    switch (period) {
      case SUBSCRIPTION_PERIOD.MONTHLY:
        return {
          [SUBSCRIPTIONS_VIEW.DEFAULT]: 'MONTHLY ACCESS',
          [SUBSCRIPTIONS_VIEW.SQUARE]: 'MONTHLY',
        }
      case SUBSCRIPTION_PERIOD.ANNUAL:
        return {
          [SUBSCRIPTIONS_VIEW.DEFAULT]: 'YEARLY ACCESS',
          [SUBSCRIPTIONS_VIEW.SQUARE]: 'YEARLY',
        }
      default:
       return {
         [SUBSCRIPTIONS_VIEW.DEFAULT]: 'UNKNOWN',
         [SUBSCRIPTIONS_VIEW.SQUARE]: 'UNKNOWN',
       }
    }
  }, [period, subscriptionView]);

  const monthlyPrice = useCallback(
    (currentPrice: number) => {
      if (features?.subscription_monthly_format && period === SUBSCRIPTION_PERIOD.ANNUAL) {
        return currentPrice / 12;
      }
      return currentPrice;
    },
    [period],
  );

  const originalPrice = useMemo(() => {
    const decimalDiscount = discount / 100;

    return currencyConverter(currencyCode).format(monthlyPrice(price) / 100 / (1 - decimalDiscount));
  }, [discount, price, currencyCode]);

  const discountEl = useMemo(() => {
    const formattedDiscount = discount > 0 ? `-${discount}%` : null;

    if (!formattedDiscount) {
      return null;
    }

    return (
      <span className={cn('pl-1')}>
        <Typography
          as="span"
          variant="2sm"
          weight="semibold"
          className={cn(discountBadgeStyles({subscriptionView}))}
        >
          {formattedDiscount}
        </Typography>
      </span>
    );
  }, [discount]);

  return (
    <RadioGroup.Item
      className={cn(priceRadioBtn({subscriptionView, isActive, isBest}),  className)}
      {...restProps}>
      <div className="flex items-center justify-between gap-2">
        <div className={cn(priceLabelStyles({subscriptionView}))}>
          <Typography as="h6" variant="base" weight="semibold" className="flex flex-wrap gap-2 items-center justify-center text-primary-font">
            <span className={cn(priceLabelItemStyles({subscriptionView}))}>{priceLabel[subscriptionView]}</span>
            {discountEl}
          </Typography>

          <Typography as="div" weight="semibold" className={cn(priceStyles({subscriptionView}))}>
            {discount > 0 && subscriptionView !== SUBSCRIPTIONS_VIEW.SQUARE && (
              <Typography as="div" variant="2xs" className="line-through pr-1.5">
                {originalPrice}
              </Typography>
            )}
            {currencyConverter(currencyCode).format(monthlyPrice(price) / 100)}
            {features?.subscription_monthly_format && '/month'}
          </Typography>
        </div>
        <div
          className={cn(radioBtnStyles({subscriptionView}))}
        >
          <RadioGroup.Indicator
            className={cn(
              'relative flex items-center justify-center w-full h-full transition-all',
              'after:inset-0 after:block after:w-[calc(100%-6px)] after:h-[calc(100%-6px)] after:rounded-full after:bg-brand-default',
            )}
          />
        </div>
      </div>
      {isBest && (
        <Typography
          as="span"
          weight="semibold"
          variant="2sm"
          className={cn(bestValueStyles({ subscriptionView }))}
        >
          Best value
        </Typography>
      )}
    </RadioGroup.Item>
  );
};
