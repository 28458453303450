import axios, { AxiosInstance, CreateAxiosDefaults } from 'axios';
import { rejectedInterceptor, requestFulfilledInterceptor, responseFulfilledInterceptor } from './_interceptors';

type Options = Omit<CreateAxiosDefaults, 'baseURL'> & {
  tokenExtractor?: () => string | null;
};

export interface ApiInstance extends AxiosInstance {}

let apiClient: AxiosInstance | null = null;

const initClient = (baseURL: string, options?: Options) => {
  const { tokenExtractor, ...otherOptions } = options ?? {};
  const client = axios.create({
    baseURL,
    ...otherOptions,
  }) as ApiInstance;

  apiClient = client;

  client.interceptors.request.use((config) => requestFulfilledInterceptor(config, tokenExtractor), rejectedInterceptor);
  client.interceptors.response.use(responseFulfilledInterceptor, rejectedInterceptor);

  return client;
};

const globalClient = axios;

const getClient = (): AxiosInstance => {
  if (!apiClient) {
    throw new Error('API client not initialized. Call initClient first.');
  }
  return apiClient;
};

export { initClient, globalClient, getClient };
