import { HTMLAttributes, useEffect, useMemo, useState } from 'react';
import { DIALOG_KEYS } from '@/core/constants';
import {
  BuySubscriptionModal,
  ConstantPaymentsModal,
  CreditsCheckoutModal,
  PaymentGatewayModal,
} from '@/features/modals';
import { ConstantPaymentSchema } from '@/features/modals/form/constant-payments-form/constant-payments-form.controller';
import { PaymentGatewaySchema } from '@/features/modals/form/payment-gateway-form/payment-gateway-form.controller';
import { useBrandFeatures } from '@/entities/brand';
import { useGetCouponPacksQuery } from '@/entities/conversation';
import {
  CreditIconPacks,
  CreditItem,
  internalPackViewListAdapter,
  SubscriptionBanner,
  useCreateConstantposUpiPayment,
  useCreateEpochPaymentIntent,
  useCreateFintmPayment,
  useCreatePaymentGateway,
  useGetConstantposPacks,
  useGetEpochPacks,
  useGetFintmPacks,
  useGetPaymentGatewayPacks,
} from '@/entities/subscription';
import { useGetMeQuery } from '@/entities/viewer';
import { CURRENCY_CODE, PAYMENT_SYSTEM } from '@/shared/constants';
import { cn, isEmptyArr, submitForm } from '@/shared/libs/utils';
import { Analytic } from '@/shared/services/analytic';
import { Alert, Button, dialog, SpinnerIcon, Typography } from '@/shared/ui';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  characterId?: number;

  onClickBanner?: () => void;

  onClose?: () => void;

  isBannerVisible?: boolean;
}

export const BuyCredits = (props: Props) => {
  const { className, onClickBanner, isBannerVisible, onClose, ...restProps } = props;

  const features = useBrandFeatures();

  const paymentSystem = features?.payment_system;

  // TODO: need to refactor (MOVE THAT TO STRIPE REPOSITORY)
  const stripePacks = useGetCouponPacksQuery({
    // TODO: Need to move to the repository
    enabled: features?.payment_system === PAYMENT_SYSTEM.STRIPE,
  });
  const fintmRequest = useGetFintmPacks({
    // TODO: Need to move to the repository
    enabled: features?.payment_system === PAYMENT_SYSTEM.FIN_TM,
  });

  const constantposRequest = useGetConstantposPacks({
    // TODO: Need to move to the repository
    enabled: features?.payment_system === PAYMENT_SYSTEM.CONSTANT_POS,
  });

  const paymentGatewayRequest = useGetPaymentGatewayPacks({
    // TODO: Need to move to the repository
    enabled: features?.payment_system === PAYMENT_SYSTEM.PAYMENT_GATEWAY,
  });

  const epochRequest = useGetEpochPacks({
    // TODO: Need to move to the repository
    enabled: features?.payment_system === PAYMENT_SYSTEM.EPOCH,
  });

  const creditPacks = useMemo(() => {
    if (paymentSystem === PAYMENT_SYSTEM.STRIPE) {
      return internalPackViewListAdapter(stripePacks.data ?? []);
    }
    if (paymentSystem === PAYMENT_SYSTEM.FIN_TM) {
      return internalPackViewListAdapter(fintmRequest.data ?? []);
    }
    if (paymentSystem === PAYMENT_SYSTEM.CONSTANT_POS) {
      return internalPackViewListAdapter(constantposRequest.data ?? []);
    }
    if (paymentSystem === PAYMENT_SYSTEM.PAYMENT_GATEWAY) {
      return internalPackViewListAdapter(paymentGatewayRequest.data ?? []);
    }
    if (paymentSystem === PAYMENT_SYSTEM.EPOCH) {
      return internalPackViewListAdapter(epochRequest.data ?? []);
    }
    return [];
  }, [
    stripePacks.data,
    fintmRequest.data,
    constantposRequest.data,
    paymentGatewayRequest.data,
    epochRequest.data,
    paymentSystem,
  ]);

  const { data: viewer } = useGetMeQuery();

  const { 0: selectedPriceId, 1: setSelectedPriceId } = useState<string | null>();

  const createFintmPayment = useCreateFintmPayment();
  const createConstantposPayment = useCreateConstantposUpiPayment();
  const createPaymentGateway = useCreatePaymentGateway();
  const createEpochPaymentIntent = useCreateEpochPaymentIntent();

  const isDisabledContinue = createEpochPaymentIntent.isPending || createFintmPayment.isPending;

  const creditImgPack = (CreditIconPacks as any)?.[features?.credit_modal.credit_icon_type as string];

  const analyticSuccessfulPurchase = () => {
    const selectedCouponPrice = creditPacks?.find((coupon) => coupon.id === selectedPriceId);

    if (selectedCouponPrice) {
      Analytic.purchaseSuccessful({
        item_id: selectedCouponPrice.id,
        currency: 'usd',
        price: selectedCouponPrice.price / 100,
        type: 'non_subscription',
        placement: 'main_screen',
        payment_provider: 'card',
      });
    }
  };

  const handleSuccessPayment = () => {
    analyticSuccessfulPurchase();

    dialog.close({ key: DIALOG_KEYS.CREDITS_CHECKOUT });
    onClose?.();
    dialog.open({
      key: DIALOG_KEYS.PAYMENT_SUCCESS,
      component: <Alert type="success" title="Your payment was successful" closeDelay={5} />,
      beforeOpen: Analytic.checkoutSuccess,
    });
  };

  const handleExternalSuccessPayment = (title: string) => {
    analyticSuccessfulPurchase();

    dialog.close({ key: DIALOG_KEYS.CONSTANT_PAYMENTS_CHECKOUT });
    dialog.close({ key: DIALOG_KEYS.BUY_SUBSCRIPTION });
    onClose?.();

    dialog.open({
      key: DIALOG_KEYS.PAYMENT_SUCCESS,
      component: <Alert type="success" title={title} closeDelay={5} />,
    });
  };

  const handleFailedPayment = (error: string | undefined) => {
    const selectedCouponPrice = creditPacks?.find((coupon) => coupon.id === selectedPriceId);

    if (selectedCouponPrice) {
      Analytic.purchaseFailed({
        item_id: selectedCouponPrice.id,
        currency: 'usd',
        price: selectedCouponPrice.price / 100,
        type: 'non_subscription',
        placement: 'main_screen',
        payment_provider: 'card',
        error_description: error ? error : '',
      });
    }

    dialog.close({ key: DIALOG_KEYS.CREDITS_CHECKOUT });
    dialog.close({ key: DIALOG_KEYS.CONSTANT_PAYMENTS_CHECKOUT });
    dialog.close({ key: DIALOG_KEYS.PAYMENT_GATEWAY_CHECKOUT });
    onClose?.();
    dialog.open({
      key: DIALOG_KEYS.PAYMENT_FAILURE,
      component: <Alert type="error" title="Payment failed" description={error} closeDelay={10} />,
    });
  };

  const analyticAddToCart = () => {
    const currentPack = creditPacks?.find((pack) => pack.id === selectedPriceId);
    if (currentPack) {
      Analytic.addToCart({
        item_id: currentPack.id,
        currency: features?.currency_code ?? CURRENCY_CODE.USD,
        price: currentPack.price / 100,
        type: 'subscription',
        placement: 'account_coupons',
      });
    }
  };

  const processPayment = async (paymentFunction: any, paymentData: any) => {
    try {
      const data = await paymentFunction.mutateAsync(paymentData);
      analyticAddToCart();
      if (data.url) window.open(data.url, '_self')?.focus();
      return data;
    } catch (error) {
      handleFailedPayment(String(error));
      console.log(error);
    }
  };

  const handleConstantPayment = async (data: ConstantPaymentSchema) => {
    if (!selectedPriceId) return;
    analyticAddToCart();
    const response = (await processPayment(createConstantposPayment, {
      email: data.email,
      account: data.account,
      type: 'upi',
      first_name: data.firstName,
      last_name: data.lastName,
      pack_id: selectedPriceId,
    })) as Record<string, any>;
    submitForm(response.transaction.form);
  };

  const handleCreatePaymentGateway = async (data: PaymentGatewaySchema) => {
    if (!selectedPriceId) return;
    analyticAddToCart();
    const response = (await processPayment(createPaymentGateway, {
      email: data.email,
      product_id: selectedPriceId,
    })) as Record<string, any>;
    if (response.order_url) {
      dialog.close({ key: DIALOG_KEYS.PAYMENT_GATEWAY_CHECKOUT });
      dialog.close({ key: DIALOG_KEYS.BUY_CURRENCY });
      window.open(response.order_url, '_self', '');
    } else {
      handleFailedPayment('Order url is null');
    }
  };

  const handleCreateEpochPaymentIntent = async () => {
    if (!selectedPriceId) return;
    analyticAddToCart();

    const response = (await processPayment(createEpochPaymentIntent, {
      pack: selectedPriceId,
    })) as Record<string, any>;
    if (response.redirectUrl) {
      dialog.close({ key: DIALOG_KEYS.BUY_CURRENCY });
      window.open(response.redirectUrl, '_self', '');
    } else {
      handleFailedPayment('Redirect url is null');
    }
  };

  const handleClickContinue = async () => {
    if (paymentSystem === PAYMENT_SYSTEM.FIN_TM && selectedPriceId) {
      await processPayment(createFintmPayment, {
        product_id: selectedPriceId,
        email: viewer?.email ?? null,
      });
      return;
    }

    if (paymentSystem === PAYMENT_SYSTEM.CONSTANT_POS) {
      dialog.open({
        key: DIALOG_KEYS.CONSTANT_PAYMENTS_CHECKOUT,
        component: (
          <ConstantPaymentsModal
            onSubmitPayment={handleConstantPayment}
            onSuccessPayment={() =>
              handleExternalSuccessPayment('Your order created. Loggin to your UPI app to continue the purchase')
            }
          />
        ),
      });
      return;
    }

    if (paymentSystem === PAYMENT_SYSTEM.PAYMENT_GATEWAY) {
      dialog.open({
        key: DIALOG_KEYS.PAYMENT_GATEWAY_CHECKOUT,
        component: <PaymentGatewayModal onSubmitPayment={handleCreatePaymentGateway} />,
      });
      return;
    }

    if (paymentSystem === PAYMENT_SYSTEM.EPOCH) {
      handleCreateEpochPaymentIntent();
      return;
    }

    dialog.open({
      key: DIALOG_KEYS.CREDITS_CHECKOUT,
      component: (
        <CreditsCheckoutModal
          onSuccessPayment={handleSuccessPayment}
          onFailedPayment={handleFailedPayment}
          creditsType={selectedPriceId as string}
        />
      ),
    });
  };

  const handleClickCredit = (creditId: string) => {
    if (creditId !== selectedPriceId) setSelectedPriceId(creditId);
  };

  const handleClickBanner = () => {
    dialog.open({
      key: DIALOG_KEYS.BUY_SUBSCRIPTION,
      component: (
        <BuySubscriptionModal
          analyticData={{
            placement: 'chat_coupons',
            type: 'subscription',
          }}
        />
      ),
      beforeOpen: Analytic.subscribeFromCoinsPaywallClick,
    });
    onClickBanner?.();
  };

  useEffect(() => {
    if (creditPacks && !isEmptyArr(creditPacks)) {
      const theBest = creditPacks.find((coupon) => coupon.isBest);
      setSelectedPriceId(theBest?.id ?? creditPacks[0]?.id);
    }
  }, [creditPacks]);

  return (
    <div
      className={cn(
        'relative z-[3] p-3',
        'flex flex-col justify-end basis-1/3 grow gap-5',
        'sm:justify-between sm:basis-1/2 ',
        className,
      )}
      {...restProps}
    >
      <Typography weight="bold" variant="4xl" className="text-primary-font sm:text-primary-font">
        {features?.credit_modal.title}
      </Typography>
      {!viewer?.is_subscribed && isBannerVisible && <SubscriptionBanner onClickBtn={handleClickBanner} />}
      <div className="grid grid-cols-3 gap-3">
        {creditPacks.map((item, idx) => {
          const Icon = creditImgPack[idx];
          return (
            <CreditItem
              isActive={selectedPriceId === item.id}
              key={item.id}
              price={item.price}
              value={item.value}
              discount={item.salePercentage}
              isBest={item.isBest}
              creditImg={Icon}
              withSubscription={viewer?.is_subscribed}
              onClick={() => handleClickCredit(item.id)}
            />
          );
        })}
      </div>
      <div className="flex flex-col gap-2">
        <Button className="w-full" size="lg" onClick={handleClickContinue} disabled={isDisabledContinue}>
          Continue {isDisabledContinue && <SpinnerIcon />}
        </Button>
        <span className="text-primary-font text-xs text-center">You can cancel your subscription at any time</span>
      </div>
    </div>
  );
};
