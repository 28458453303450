import * as Sentry from '@sentry/react';
import env from '@beam-australia/react-env';

const DSN = env('SENTRY_DSN');
const API_URL = env('API_URL');
const environment = env('ENV_MODE');

export class SentryService {
  static init = (router: any) => {
    if (window.location.hostname === 'localhost') return;
    Sentry.init({
      dsn: DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.tanstackRouterBrowserTracingIntegration(router),
      ],
      beforeSendSpan(span) {
        if (span.description?.includes('node_modules')) {
          return null;
        }
        return span;
      },
      environment: environment,
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^\//, API_URL],
      // Session Replay
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  };
}
