import {memo} from 'react';

import {cva, VariantProps} from 'class-variance-authority';

import {cn} from '@/shared/libs/utils';
import {Button, type ButtonProps, For} from '@/shared/ui';

const badgeWithText = cva('flex flex-col align-center justify-center text-center whitespace-normal px-4 gap-1 ', {
  variants: {
    badgeSize: {
      md: 'px-4 rounded-field text-base w-auto',
      lg: 'min-h-[70px] text-xs rounded-lg',
    },

    isActive: {
      true: 'bg-brand-light hover:bg-brand-light active:bg-brand-light text-black',
    },
  },
  defaultVariants: {
    badgeSize: 'lg',
    isActive: false,
  },
});

export interface Props extends ButtonProps, VariantProps<typeof badgeWithText> {
  text: string | string[];
}

export const BadgeWithText = memo((props: Props) => {
  const { className, text, isActive, badgeSize, ...restProps } = props;

  return (
    <Button
      type="button"
      variant="secondary"
      className={cn(
        className,
        badgeWithText({
          badgeSize,
          isActive,
        }),
      )}
      size={badgeSize}
      {...restProps}
    >
      <span className="inline-flex flex-col">
        {Array.isArray(text) ? <For each={text} render={(text, index) => <span key={index}>{text}</span>} /> : text}
      </span>
    </Button>
  );
});
