import {ComponentPropsWithoutRef, memo} from 'react';

import {ChevronLeftIcon} from 'lucide-react';

import {cn} from '@/shared/libs/utils';
import {Button, ButtonProps, Typography} from '@/shared/ui';
import {Progress} from '@/shared/ui/progress';

export interface Props extends Omit<ComponentPropsWithoutRef<'div'>, 'children'> {
  currentStep: number;

  totalSteps: number;

  hiddenPrevBtn?: boolean;

  btnProps?: ButtonProps;

  onClickPrev?: () => void;
}

export const OnboardingStepHeader = memo((props: Props) => {
  const { currentStep, totalSteps, hiddenPrevBtn, className, onClickPrev, btnProps, ...restProps } = props;

  const progressValue = (currentStep / totalSteps) * 100;

  return (
    <div className={cn('grid gap-5 px-5 pt-3 grid-cols-[40px_auto] ', className)} {...restProps}>
      <div className="h-[40px]">
        {!hiddenPrevBtn && (
          <Button onClick={onClickPrev} variant="outline" size="icon" {...btnProps}>
            <ChevronLeftIcon className="w-7" />
          </Button>
        )}
      </div>
      <div className="flex justify-between items-center gap-5">
        <Progress value={progressValue} />
        <Typography as="span" weight="medium" className="opacity-60 w-[40px] text-center text-primary-font">
          {currentStep}/{totalSteps}
        </Typography>
      </div>
    </div>
  );
});
