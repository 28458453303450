import { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { ErrorCatcher } from '@/widgets';
import { QueryClient } from '@tanstack/react-query';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { z } from 'zod';
import { useConnectUserToNotification } from '@/features/connect-user-to-notification';
import { RedirectProvider } from '@/features/redirect-provider';
import { type BrandPayloadModel } from '@/entities/brand';
import { useRedtrackClickId } from '@/entities/redtrack';
import { useReadyState } from '@/shared/services/ready-state';
import { DialogContainer, SplashScreen, ThemeProviderV2 } from '@/shared/ui';

interface RouteContext {
  queryClient: QueryClient;
  isPassOnboarding: () => boolean;
  brandConfig: BrandPayloadModel & {
    version: number;
  };
  featuresGetter: <T>(key: string, defaultValue?: T) => T;
}

const globalSearchSchema = z.object({
  auth: z.boolean().optional(),
});

export const Route = createRootRouteWithContext<RouteContext>()({
  component: RootRoute,
  errorComponent: ErrorCatcher,
  validateSearch: globalSearchSchema,
});

function RootRoute() {
  const {
    brandConfig: { features, theme },
  } = Route.useRouteContext();

  const isPending = useReadyState.use.isPending();

  useRedtrackClickId();
  useConnectUserToNotification();
  // useListenOnboardingStorage();
  // useListenSub();

  return (
    <Suspense fallback={<SplashScreen spinnerKey="heart-beat-3d" />}>
      {isPending ? (
        <SplashScreen spinnerKey="heart-beat-3d" />
      ) : (
        <RedirectProvider>
          <Helmet title={features?.app_name ?? 'AI'}>
            <link rel="icon" type="image/svg+xml" href={features?.logo?.favicon} />
            {features?.third_party?.red_track?.src && <script src={features?.third_party?.red_track?.src} />}
          </Helmet>
          <ThemeProviderV2 theme={theme as any}>
            <DialogContainer>
              <main className="h-full supports-dvh:h-dvh overflow-hidden bg-primary-gradient">
                <Outlet />
              </main>
            </DialogContainer>
            {/* <TanStackRouterDevtools position="bottom-right"  /> */}
            {/*<ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />*/}
          </ThemeProviderV2>
        </RedirectProvider>
      )}
    </Suspense>
  );
}
