export const isArray = (value: unknown): value is unknown[] => {
  return Array.isArray(value);
};

export const isEmptyArr = (arr: unknown[]) => {
  if (!isArray(arr)) return true;

  return arr.length === 0;
};

export function shuffleArr<T>(array: T[]) {
  let newArray = [...array];
  let currentIndex = array.length;

  while (currentIndex != 0) {
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [newArray[currentIndex], newArray[randomIndex]] = [newArray[randomIndex], newArray[currentIndex]];
  }

  return newArray;
}

export const extractCookieValue = (cookieName: string): string | null => {
  const match = document.cookie.match(new RegExp('(^|;\\s*)(' + cookieName + ')=([^;]*)'));
  return match ? decodeURIComponent(match[3]) : null;
};

export const mergeArray = <T>(array: T[], ...rest: T[]): T[] => {
  return [...array, ...rest];
};
