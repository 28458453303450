import {Controller} from 'react-hook-form';

import {useGetMeQuery} from '@/entities/viewer';

import {cn} from '@/shared/libs/utils';
import {Button, Input, SpinnerIcon} from '@/shared/ui';

import {PaymentGatewayFormControllerParams, usePaymentGatewayFormController} from './payment-gateway-form.controller';

export interface Props extends PaymentGatewayFormControllerParams {}

export const PaymentGatewayForm = (props: Props) => {
  const { onSubmitPayment, ...restProps } = props;
  const { data: viewer } = useGetMeQuery();

  const { control, errors, isPending, onSubmit } = usePaymentGatewayFormController({
    onSubmitPayment,
    email: viewer?.email ?? '',
  });

  return (
    <form
      className={cn('flex flex-col h-full justify-between items-center gap-4 pt-4')}
      onSubmit={onSubmit}
      {...restProps}
    >
      <Controller
        control={control}
        name="email"
        render={({ field }) => {
          return (
            <Input
              label="Email"
              placeholder="Enter your email"
              autoComplete="off"
              error={errors?.email?.message}
              {...field}
            />
          );
        }}
      />

      <Button disabled={isPending} type="submit" size="lg" className="w-full mt-4 gap-2">
        Buy Now {isPending && <SpinnerIcon />}
      </Button>
    </form>
  );
};
