import {useEffect} from 'react';

import {LocalStorageKey, localStorageService} from '@/core/services';
import type {routeTree} from '@/routeTree.gen';
import {createFileRoute, Outlet, redirect, type RoutePaths, useSearch} from '@tanstack/react-router';

import {useConnectSocket} from '@/features/connect-socket';
import {authModalAction} from '@/features/modals';
import {RootLayout} from '@/features/root-layout';

import {authStorageGetters, useWithAuth} from '@/entities/auth';
import {useGetMeQuery} from '@/entities/viewer';

import {useDisclosure} from '@/shared/hooks';

export const Route = createFileRoute('/_layout')({
  component: LayoutConversationComponent,
  beforeLoad: ({ context, location }) => {
    const system = context?.brandConfig?.features?.system;

    if (system && system.can_skip_onboarding) {
      localStorageService.setItem(LocalStorageKey.IsPassOnboarding, true);
      return;
    }

    if (!context.isPassOnboarding()) {
      throw redirect({
        to: '/onboarding',
        search: {
          redirect: location.href,
        },
      });
    }
  },
});

const excludedPathForAuth: RoutePaths<typeof routeTree>[] = ['/404'];

function LayoutConversationComponent() {
  const { data: viewer } = useGetMeQuery();

  const { 0: isShownAuth, 1: actionShowAuth } = useDisclosure();

  const isAuthenticated = authStorageGetters.getAuth().isAuthenticated;

  const isAuth = useWithAuth();

  const search = useSearch({
    strict: false,
  });

  useConnectSocket();

  const showAuth = () => {
    authModalAction.open();
    actionShowAuth.onOpen();
  };

  useEffect(() => {
    const pathname = window.location.pathname as RoutePaths<typeof routeTree>;

    if (!isAuth() || excludedPathForAuth.includes(pathname)) return;

    if (search?.auth && !viewer?.is_registered) {
      showAuth();
      return;
    }

    if (viewer?.is_subscribed && !isShownAuth && !isAuthenticated) {
      showAuth();
    }
  }, [viewer, search]);

  return (
    <RootLayout>
      <Outlet />
    </RootLayout>
  );
}
