import { memo, useMemo } from 'react';
import { useNavigate } from "@tanstack/react-router";

import NotAloneLogo from '@/assets/icons/not-alone.svg';

import { useBrandFeatures } from "@/entities/brand";

import { cn } from '@/shared/libs/utils';

import { useRootLayoutContext } from '../../../libs';

export const Header = memo(() => {
  const {isOpenSidebar} = useRootLayoutContext();
  const features = useBrandFeatures();
  const navigate = useNavigate();

  const rootLogo = features?.logo?.root ?? '/logo.png';
  const textLogo = useMemo(() => {
    return features?.logo?.img_text ?? NotAloneLogo;
  }, []);

  const handleNavigate = async () => {
    await navigate({ to: '/discover' });
  };

  return (
    <div className="flex items-center border-b border-grey-600/15 pb-2 gap-2">
      <img onClick={handleNavigate} alt="Logo" src={rootLogo} className="w-11 cursor-pointer"/>
      <img
        onClick={handleNavigate}
        src={textLogo}
        className={cn('min-w-[74px] cursor-pointer', {
          'opacity-0 invisible transition-opacity': !isOpenSidebar,
          'opacity-100 duration-500 visible transition-opacity': isOpenSidebar,
        })}
      />
    </div>
  );
});
