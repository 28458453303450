import type {CouponPack} from '@/entities/conversation';
import {ConstantposPack, FintmPack, InternalPackView} from '@/entities/subscription';

export type AvailablePack = FintmPack | CouponPack | ConstantposPack;

const isPackWithProductId = (pack: AvailablePack): pack is FintmPack | ConstantposPack => {
  return 'product_id' in pack;
};

const isPackWithId = (pack: AvailablePack): pack is CouponPack => {
  return 'id' in pack;
};

export const internalPackViewAdapter = (pack: AvailablePack) => {
  let result = {
    isBest: pack.is_best,
    salePercentage: pack.sale_percentage,
    value: pack.value,
    price: pack.price,
  } as InternalPackView;

  if (isPackWithProductId(pack) && pack.product_id) {
    result = {
      ...result,
      id: pack.product_id,
    };
  }
  if (isPackWithId(pack)) {
    result = {
      ...result,
      id: pack.id,
    };
  }

  return result;
};

export const internalPackViewListAdapter = (packs: AvailablePack[]) => {
  return packs.map(internalPackViewAdapter);
};
