import {FC, memo, useRef, useState} from 'react';

import Pause from '@/assets/icons/pause.svg?react';
import Play from '@/assets/icons/play.svg?react';

type AudioPlayerProps = {
  track?: string;
};

export const AudioPlayer: FC<AudioPlayerProps> = memo(({ track }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const audioSvgArray: { x: number; y1: number; y2: number }[] = [
    { x: 1, y1: 1, y2: 12 },
    { x: 4, y1: 4, y2: 9 },
    { x: 7, y1: 5.5, y2: 7.5 },
    { x: 10, y1: 2, y2: 11 },
    { x: 13, y1: 1, y2: 12 },
    { x: 16, y1: 4, y2: 9 },
    { x: 19, y1: 2.5, y2: 10.5 },
    { x: 22, y1: 2.5, y2: 10.5 },
    { x: 25, y1: 6, y2: 7 },
    { x: 28, y1: 2.5, y2: 10.5 },
    { x: 31, y1: 2.5, y2: 10.5 },
    { x: 34, y1: 1, y2: 12 },
    { x: 37, y1: 4.5, y2: 8.5 },
    { x: 40, y1: 4, y2: 9 },
    { x: 43, y1: 6, y2: 7 },
    { x: 46, y1: 6, y2: 7 },
    { x: 49, y1: 1, y2: 12 },
    { x: 52, y1: 3.5, y2: 9.5 },
    { x: 55, y1: 4, y2: 9 },
    { x: 58, y1: 4.5, y2: 8.5 },
    { x: 61, y1: 4.5, y2: 8.5 },
    { x: 64, y1: 4, y2: 9 },
    { x: 67, y1: 6, y2: 7 },
    { x: 70, y1: 6, y2: 7 },
    { x: 73, y1: 5, y2: 8 },
    { x: 76, y1: 2, y2: 11 },
    { x: 79, y1: 1, y2: 12 },
    { x: 82, y1: 3.5, y2: 9.5 },
    { x: 85, y1: 5.5, y2: 7.5 },
    { x: 88, y1: 4.5, y2: 8.5 },
    { x: 91, y1: 4.5, y2: 8.5 },
    { x: 94, y1: 4, y2: 9 },
    { x: 97, y1: 2, y2: 11 },
    { x: 100, y1: 1, y2: 12 },
    { x: 103, y1: 4, y2: 9 },
    { x: 106, y1: 4, y2: 9 },
    { x: 109, y1: 6, y2: 7 },
    { x: 112, y1: 6, y2: 7 },
    { x: 115, y1: 6, y2: 7 },
    { x: 118, y1: 5, y2: 8 },
    { x: 121, y1: 2, y2: 11 },
    { x: 124, y1: 3, y2: 10 },
    { x: 127, y1: 1, y2: 12 },
    { x: 130, y1: 4, y2: 9 },
    { x: 133, y1: 4, y2: 9 },
    { x: 136, y1: 6, y2: 7 },
    { x: 139, y1: 6, y2: 7 },
    { x: 142, y1: 6, y2: 7 },
    { x: 145, y1: 5, y2: 8 },
    { x: 148, y1: 2, y2: 11 },
    { x: 151, y1: 3, y2: 10 },
    { x: 154, y1: 4, y2: 9 },
    { x: 157, y1: 6, y2: 7 },
  ];

  const togglePlay = () => {
    if (!audioRef.current) return;

    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const updateProgress = () => {
    if (!audioRef.current) return;

    if (audioRef.current) {
      const currentTime = audioRef.current.currentTime;
      const duration = audioRef.current.duration;
      const progressPercentage = (currentTime / duration) * 100;
      setProgress(progressPercentage);
    }
  };

  return (
    <div className="flex items-center w-full gap-2">
      <audio ref={audioRef} src={track} onTimeUpdate={updateProgress} onEnded={() => setIsPlaying(false)} />
      <button onClick={togglePlay}>
        {isPlaying ? (
          <div className="rounded-xl w-[40px] h-[40px] bg-gradient-to-r from-gradientFrom to-gradientTo flex items-center justify-center">
            <Pause />
          </div>
        ) : (
          <div className="rounded-xl w-[40px] h-[40px] bg-gradient-to-r from-gradientFrom to-gradientTo flex items-center justify-center">
            <Play />
          </div>
        )}
      </button>
      <div className="w-[156px] h-[14px]">
        <svg width="100%" height="100%" viewBox={`0 0 156 14`} xmlns="http://www.w3.org/2000/svg">
          {audioSvgArray.map(({ x, y1, y2 }, i) => {
            const color = progress >= (i + 1) * (100 / audioSvgArray.length) ? '#ED6872' : '#878787';
            return <line key={i} x1={x} y1={y1} x2={x} y2={y2} stroke={color} strokeWidth="2" strokeLinecap="round" />;
          })}
        </svg>
      </div>
    </div>
  );
});
