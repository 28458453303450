import {HTMLAttributes, memo} from 'react';

import {cn} from '@/shared/libs/utils';
import {For, Typography} from '@/shared/ui';

export interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  name: string;

  age: number;

  interests: string[];
}

export const CharacterOverviewText = memo((props: Props) => {
  const { name, age, interests, className, ...restProps } = props;

  return (
    <div className={cn('flex flex-col gap-4', className)} {...restProps}>
      <div>
        <Typography as="h6" variant="4xl" weight="semibold" className="text-white">
          {name}
        </Typography>
        <Typography as="span" variant="2sm" weight="semibold" className="text-white/70">
          {age} years old
        </Typography>
      </div>

      <div className="flex flex-wrap gap-2 font-semibold text-sm text-white">
        <For
          each={interests}
          render={(interest) => {
            return (
              <Typography as="span" className="rounded-md blur-1 px-2 py-0.5 bg-white/25 text-white" key={interest}>
                {interest}
              </Typography>
            );
          }}
        />
      </div>
    </div>
  );
});
