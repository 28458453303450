import {HTMLAttributes, useEffect, useMemo, useState} from 'react';
import * as RadioGroup from '@radix-ui/react-radio-group';
import {DIALOG_KEYS, SubscriptionTier} from '@/core/constants';
import {useFeature} from "@repo/common/services/features-book";

import {DiscountBanner} from '@/features/discount-banner';
import {EmailForm} from '@/features/email-form';
import {ConstantPaymentsModal, PaymentGatewayModal, SubscriptionCheckoutModal} from '@/features/modals';
import {ConstantPaymentSchema} from '@/features/modals/form/constant-payments-form/constant-payments-form.controller';
import {PaymentGatewaySchema} from '@/features/modals/form/payment-gateway-form/payment-gateway-form.controller';

import {ModalPreviewImg} from '@/entities/modals';
import {
  type InternalSubscription,
  internalSubscriptionListAdapter,
  PriceRadioBtn,
  SUBSCRIPTION_PLAN,
  SubscriptionBenefitList,
  useCreateConstantposSubscriptionPayment,
  useCreateEpochPaymentIntent,
  useCreateFintmPayment,
  useCreatePaymentGateway,
  useGetConstantposSubscriptions,
  useGetEpochSubscriptions,
  useGetFintmSubscriptions,
  useGetPaymentGatewaySubscriptions,
  useGetSubscriptionList,
} from '@/entities/subscription';
import {useBrandFeatures} from "@/entities/brand";
import {useGetMeQuery} from '@/entities/viewer';

import {CURRENCY_CODE, PAYMENT_SYSTEM} from '@/shared/constants';
import {cn, submitForm} from '@/shared/libs/utils';
import {Analytic} from '@/shared/services/analytic';
import {Undefined} from '@/shared/types';
import {Alert, Button, dialog, For, SpinnerIcon, Typography} from '@/shared/ui';
import {CloseButton} from '@/shared/ui/close-button';

import {radioGroupStyles, subscriptionsContent, titleStyles} from './buy-subscription-modal.config';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  subscriptionVariant?: SubscriptionTier;

  imgSrc?: string;

  analyticData?: any;

  onSuccessPayment?: () => void;

  onFailedPayment?: (err: string | undefined) => void;

  isPaymentModal?: boolean;

  onSuccessOnboarding?: () => void;
}

export const BuySubscription = (props: Props) => {
  const {
    subscriptionVariant = SubscriptionTier.PRO,
    analyticData,
    imgSrc,
    onSuccessPayment,
    onFailedPayment,
    className,
    isPaymentModal,
    onSuccessOnboarding,
  } = props;

  const features = useBrandFeatures();

  const currentContent = subscriptionsContent(features)[subscriptionVariant];

  const currencyCode = features?.currency_code ?? CURRENCY_CODE.USD;

  const viewerQuery = useGetMeQuery();

  const subscriptionView = useFeature('subscription_paywall_view', 0);

  const stripeSubscriptionsQuery = useGetSubscriptionList(subscriptionVariant, {
    enabled: features?.payment_system === PAYMENT_SYSTEM.STRIPE,
  });

  const fintmSubscriptionsQuery = useGetFintmSubscriptions(
    {
      lookup_keys: [subscriptionVariant === SubscriptionTier.PRO ? SUBSCRIPTION_PLAN.PRO : SUBSCRIPTION_PLAN.PRO_PLUS],
    },
    {
      enabled: features?.payment_system === PAYMENT_SYSTEM.FIN_TM,
    },
  );

  const constantposSubscriptionsQuery = useGetConstantposSubscriptions(
    {
      lookup_keys: [subscriptionVariant === SubscriptionTier.PRO ? SUBSCRIPTION_PLAN.PRO : SUBSCRIPTION_PLAN.PRO_PLUS],
    },
    {
      enabled: features?.payment_system === PAYMENT_SYSTEM.CONSTANT_POS,
    },
  );

  const paymentGatewaySubscriptionsQuery = useGetPaymentGatewaySubscriptions(
    {
      lookup_keys: [subscriptionVariant === SubscriptionTier.PRO ? SUBSCRIPTION_PLAN.PRO : SUBSCRIPTION_PLAN.PRO_PLUS],
    },
    {
      enabled: features?.payment_system === PAYMENT_SYSTEM.PAYMENT_GATEWAY,
    },
  );

  const epochSubscriptionsQuery = useGetEpochSubscriptions(
    {
      lookup_keys: [subscriptionVariant === SubscriptionTier.PRO ? SUBSCRIPTION_PLAN.PRO : SUBSCRIPTION_PLAN.PRO_PLUS],
    },
    {
      enabled: features?.payment_system === PAYMENT_SYSTEM.EPOCH,
    },
  );

  const createFintmPayment = useCreateFintmPayment();
  const createConstantposPayment = useCreateConstantposSubscriptionPayment();
  const createPaymentGateway = useCreatePaymentGateway();
  const createEpoch = useCreateEpochPaymentIntent();

  const subscriptionList = useMemo(() => {
    if (features?.payment_system === PAYMENT_SYSTEM.FIN_TM) {
      return internalSubscriptionListAdapter(fintmSubscriptionsQuery.data ?? []);
    }
    if (features?.payment_system === PAYMENT_SYSTEM.CONSTANT_POS) {
      return internalSubscriptionListAdapter(constantposSubscriptionsQuery.data ?? []);
    }
    if (features?.payment_system === PAYMENT_SYSTEM.PAYMENT_GATEWAY) {
      return internalSubscriptionListAdapter(paymentGatewaySubscriptionsQuery.data ?? []);
    }
    if (features?.payment_system === PAYMENT_SYSTEM.EPOCH) {
      return internalSubscriptionListAdapter(epochSubscriptionsQuery.data ?? []);
    }
    return internalSubscriptionListAdapter(stripeSubscriptionsQuery.data ?? []);
  }, [
    stripeSubscriptionsQuery.data,
    fintmSubscriptionsQuery.data,
    constantposSubscriptionsQuery.data,
    paymentGatewaySubscriptionsQuery.data,
    epochSubscriptionsQuery.data,
  ]);

  const {0: selectedSubscription, 1: setSelectedSubscription} = useState<Undefined<InternalSubscription>>(() => {
    return subscriptionList.find((sub) => sub.isBest);
  });

  const {placement} = analyticData || {};

  const isBlurred = Boolean(imgSrc);

  const handleEmailOnSubmitted = () => {
    Analytic.checkoutEmailSentSuccess();
    dialog.close({key: DIALOG_KEYS.PAYMENT_SUCCESS});
    dialog.close({key: DIALOG_KEYS.BUY_SUBSCRIPTION});
  };

  const analyticSuccessfulPurchase = () => {
    if (selectedSubscription) {
      Analytic.purchaseSuccessful({
        item_id: selectedSubscription.id,
        currency: currencyCode,
        price: selectedSubscription.price / 100,
        type: 'subscription',
        placement: placement ?? 'main_screen',
        payment_provider: 'card',
      });
    }
  };

  const handleSuccessPayment = () => {
    analyticSuccessfulPurchase();

    dialog.close({key: DIALOG_KEYS.SUBSCRIPTION_CHECKOUT});
    dialog.open({
      key: DIALOG_KEYS.PAYMENT_SUCCESS,
      component: (
        <Alert type="success" title="Your payment was successful">
          <EmailForm
            onSubmitted={handleEmailOnSubmitted}
            onClick={onSuccessOnboarding}
            title="Enter your email to get access"
          />
        </Alert>
      ),
      beforeOpen: Analytic.checkoutSuccess,
      afterClose: () => {
        dialog.close({key: DIALOG_KEYS.BUY_SUBSCRIPTION});
        onSuccessPayment?.();
        onSuccessOnboarding?.();
      },
    });
  };

  const handleExternalSuccessPayment = (title: string) => {
    analyticSuccessfulPurchase();

    dialog.close({key: DIALOG_KEYS.CONSTANT_PAYMENTS_CHECKOUT});
    dialog.close({key: DIALOG_KEYS.BUY_SUBSCRIPTION});

    dialog.open({
      key: DIALOG_KEYS.PAYMENT_SUCCESS,
      component: <Alert type="success" title={title} closeDelay={5}/>,
    });
  };

  const handleFailedPayment = (error?: string | undefined) => {
    if (selectedSubscription) {
      Analytic.purchaseFailed({
        item_id: selectedSubscription.id,
        currency: 'usd',
        price: selectedSubscription.price / 100,
        type: 'subscription',
        placement: placement ?? 'main_screen',
        payment_provider: 'card',
        error_description: error ? error : '',
      });
    }

    dialog.close({key: DIALOG_KEYS.SUBSCRIPTION_CHECKOUT});
    dialog.close({key: DIALOG_KEYS.BUY_SUBSCRIPTION});
    dialog.open({
      key: DIALOG_KEYS.PAYMENT_FAILURE,
      component: <Alert type="error" title="Payment failed" description={error} closeDelay={10}/>,
      afterClose: () => {
        dialog.close({key: DIALOG_KEYS.PREVENT_PROGRESS_DELETE});
        dialog.close({key: DIALOG_KEYS.CONSTANT_PAYMENTS_CHECKOUT});
        dialog.close({key: DIALOG_KEYS.PAYMENT_GATEWAY_CHECKOUT});
        onFailedPayment?.(error);
      },
    });
  };

  const processPayment = async (paymentFunction: any, paymentData: any) => {
    if (!selectedSubscription) return;
    const persona_id = analyticData?.persona_id ?? {};

    try {
      const data = await paymentFunction.mutateAsync(paymentData);
      Analytic.addToCart({
        item_id: selectedSubscription.id,
        currency: currencyCode,
        price: selectedSubscription.price / 100,
        type: 'subscription',
        placement: placement ?? 'main_screen',
        ...persona_id,
      });
      if (data.url) window.open(data.url, '_self');
      setTimeout(() => onSuccessOnboarding?.(), 500);
      return data;
    } catch (error) {
      handleFailedPayment(String(error));
      console.log(error);
    }
  };

  const handleConstantPayment = async (data: ConstantPaymentSchema) => {
    if (!selectedSubscription) return;
    const response = (await processPayment(createConstantposPayment, {
      pack_id: selectedSubscription.id,
      email: data.email,
      account: data.account,
      type: 'upi',
      first_name: data.firstName,
      last_name: data.lastName,
    })) as Record<string, any>;
    submitForm(response.transaction.form);
  };

  const handleCreatePaymentGateway = async (data: PaymentGatewaySchema) => {
    if (!selectedSubscription) return;
    const response = (await processPayment(createPaymentGateway, {
      product_id: selectedSubscription.id,
      email: data.email,
    })) as Record<string, any>;
    if (response.order_url) {
      dialog.close({key: DIALOG_KEYS.PAYMENT_GATEWAY_CHECKOUT});
      dialog.close({key: DIALOG_KEYS.BUY_SUBSCRIPTION});
      window.open(response.order_url, '_self', '');
    } else {
      handleFailedPayment('Order url is null');
    }
  };

  const handleCreateEpochPaymentIntent = async () => {
    const response = (await processPayment(createEpoch, {
      pack: selectedSubscription?.id,
    })) as Record<string, any>;
    if (response.redirectUrl) {
      dialog.close({key: DIALOG_KEYS.BUY_SUBSCRIPTION});
      window.open(response.redirectUrl, '_self', '');
    } else {
      handleFailedPayment('Redirect url is null');
    }
  };

  const handleClickContinue = async () => {
    if (!selectedSubscription) return;

    Analytic.addToCart({
      item_id: selectedSubscription.id,
      currency: currencyCode,
      price: selectedSubscription.price / 100,
      type: 'subscription',
      placement: placement ?? 'main_screen',
    });

    if (features?.payment_system === PAYMENT_SYSTEM.FIN_TM) {
      await processPayment(createFintmPayment, {
        product_id: selectedSubscription.id,
        email: viewerQuery.data?.email ?? null,
      });
      return;
    }

    if (features?.payment_system === PAYMENT_SYSTEM.CONSTANT_POS) {
      dialog.open({
        key: DIALOG_KEYS.CONSTANT_PAYMENTS_CHECKOUT,
        component: (
          <ConstantPaymentsModal
            onSubmitPayment={handleConstantPayment}
            onSuccessPayment={() =>
              handleExternalSuccessPayment('Your order created. Login to your UPI app to continue the purchase')
            }
          />
        ),

        beforeOpen: () => {
          Analytic.beginCheckout({placement: placement ?? 'main_screen'});
        },
      });
      return;
    }

    if (features?.payment_system === PAYMENT_SYSTEM.PAYMENT_GATEWAY) {
      dialog.open({
        key: DIALOG_KEYS.PAYMENT_GATEWAY_CHECKOUT,
        component: <PaymentGatewayModal onSubmitPayment={handleCreatePaymentGateway}/>,

        beforeOpen: () => {
          Analytic.beginCheckout({placement: placement ?? 'main_screen'});
        },
      });
      return;
    }

    if (features?.payment_system === PAYMENT_SYSTEM.EPOCH) {
      Analytic.beginCheckout({placement: placement ?? 'main_screen'});
      handleCreateEpochPaymentIntent();
      return;
    }

    dialog.open({
      key: DIALOG_KEYS.SUBSCRIPTION_CHECKOUT,
      component: (
        <SubscriptionCheckoutModal
          onFailedPayment={handleFailedPayment}
          onSuccessPayment={handleSuccessPayment}
          priceId={selectedSubscription.id}
        />
      ),

      beforeOpen: () => {
        Analytic.beginCheckout({placement: placement ?? 'main_screen'});
      },
    });
  };

  const handleDeleteProgressAlert = () => {
    if (analyticData) {
      Analytic.paywallSkipClick(analyticData);
    }
    dialog.open({
      key: DIALOG_KEYS.PREVENT_PROGRESS_DELETE,
      component: (
        <Alert
          type="error"
          onOkTitle="No"
          onCancelTitle="Delete"
          title="Are you sure you want to delete all the progress?"
          onCancelClick={() => {
            dialog.close({key: DIALOG_KEYS.PREVENT_PROGRESS_DELETE});
            onSuccessOnboarding?.();
          }}
          onOkClick={() => dialog.close({key: DIALOG_KEYS.PREVENT_PROGRESS_DELETE})}
          withActions
        />
      ),
      beforeOpen: () => Analytic.quizDeleteProgressView(),
    });
  };

  const onChangeSelectedSubscription = (subscriptionId: string) => {
    const sub = subscriptionList.find((sub) => sub.id === subscriptionId);
    setSelectedSubscription(sub);
  };
  const isDisabledContinue =
    !selectedSubscription ||
    fintmSubscriptionsQuery.isFetching ||
    stripeSubscriptionsQuery.isFetching ||
    createFintmPayment.isPending ||
    createEpoch.isPending;

  useEffect(() => {
    if (analyticData) {
      Analytic.paywallScreenView(analyticData);
    }
  }, []);

  useEffect(() => {
    if (!selectedSubscription) {
      const sub = subscriptionList.find((sub) => sub.isBest);
      setSelectedSubscription(sub);
    }
  }, [subscriptionList]);

  return (
    <>
      <ModalPreviewImg
        imgSrc={imgSrc ?? currentContent.picture}
        isBlurred={isBlurred}
        className={cn(
          'absolute z-[1] w-full h-[calc(100%-370px)] sm:w-1/2 sm:h-full',
          {'relative grow h-full px-3 sm:px-0 pt-14 sm:pt-0': isBlurred},
          className,
        )}
      />
      {isPaymentModal && (
        <CloseButton className="w-4 h-6 p-0.5 z-10 opacity-70 mt-3 ml-3" onClick={handleDeleteProgressAlert}/>
      )}
      <div className={cn('basis-2/3 sm:basis-1/2 grow', {hidden: isBlurred})}/>
      <div
        className={cn(
          'relative z-[3] py-3 pt-2 sm:pt-6 -mt-11 sm:mt-0',
          'flex flex-col justify-between shrink-0 gap-5',
          'sm:justify-between sm:basis-1/2 sm:grow',
        )}
      >
        {isBlurred ? (
          <div className="hidden sm:block px-3">
            <Typography as="h6" weight="bold" variant="2xl" className="text-primary-font pb-2">
              {features?.modal_blurred_info.title}
            </Typography>
            <Typography as="span" weight="semibold" variant="base" className="text-secondary">
              {features?.modal_blurred_info.text}
            </Typography>
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <Typography as="h6" weight="bold" variant="3xl" className={cn(titleStyles({subscriptionView}))}>
              {currentContent.title[subscriptionView]}
            </Typography>
            {features?.discount_banner && currentContent.isDiscountBanner[subscriptionView] && <DiscountBanner/>}
            <SubscriptionBenefitList
              subscriptionView={subscriptionView}
              benefits={currentContent.benefits}
              className={cn('px-3',
                features?.discount_banner && currentContent.isDiscountBanner[subscriptionView] && 'grid grid-cols-2')}
            />
          </div>
        )}
        <div className="flex flex-col px-3 gap-2 shrink-0">
          <RadioGroup.Root
            defaultValue={selectedSubscription?.id}
            value={selectedSubscription?.id}
            orientation="vertical"
            className={cn(radioGroupStyles({subscriptionView}))}
            onValueChange={onChangeSelectedSubscription}
          >
            <For
              each={subscriptionList}
              render={(subscription) => {
                return (
                  <PriceRadioBtn
                    subscriptionView={subscriptionView}
                    discount={
                      features?.subscription_discount
                        ? features?.subscription_discount[subscription.subscriptionPeriod]
                        : subscription.salePercentage
                    }
                    currencyCode={currencyCode}
                    key={subscription.id}
                    value={subscription.id}
                    period={subscription.subscriptionPeriod}
                    price={subscription.price}
                    isBest={subscription.isBest}
                  />
                );
              }}
            />
          </RadioGroup.Root>
          <Button className="w-full" size="lg" onClick={handleClickContinue} disabled={isDisabledContinue}>
            {currentContent.button[subscriptionView]} {isDisabledContinue && <SpinnerIcon/>}
          </Button>
          <span className="px-3 text-muted-foreground text-xs text-center">
            {features?.subscription_bottom_text
              ? currentContent.bottomText
              : 'You can cancel your subscription at any time'}
          </span>
        </div>
      </div>
    </>
  );
};
