import {HTMLAttributes} from 'react';

import {cn} from '@/shared/libs/utils';

import './styles.css';

export interface Props extends HTMLAttributes<HTMLSpanElement> {
  count: number;

  maxCount?: number;
}

export const Badge = (props: Props) => {
  const { count, className, maxCount, ...restProps } = props;

  return (
    <div
      className={cn(
        'badge',
        'flex items-center justify-center px-2 min-h-5',
        {
          'hidden opacity-0': count <= 0,
        },
        className,
      )}
      {...restProps}
    >
      <span className="text-2sm font-semibold">{maxCount && count > maxCount ? `${maxCount}+` : count}</span>
    </div>
  );
};
