import {HTMLAttributes, memo} from 'react';

import {MessageContentType} from '@/core/constants';

import {cn} from '@/shared/libs/utils';
import {Nullable} from '@/shared/types';
import {Typography} from '@/shared/ui';

import {Message} from '../../libs';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  lastMessage: Nullable<Message>;

  hasSubscription: boolean;
}

export const ConversationLastMessage = memo((props: Props) => {
  const { lastMessage, className, hasSubscription, ...restProps } = props;

  if (!lastMessage) return null;

  if (lastMessage.type === MessageContentType.PICTURE) {
    return (
      <div className={cn('flex items-center gap-2', className)} {...restProps}>
        <img
          className="size-6 rounded-sm"
          alt="Conversation last message picture"
          src={lastMessage.meta?.is_NSFW && !hasSubscription ? lastMessage.meta?.blurUrl : lastMessage.meta?.thumbUrl}
        />
        <Typography lineClamp={2} as="span" weight="semibold" variant="2sm" className="text-secondary">
          Photo
        </Typography>
      </div>
    );
  }

  if (lastMessage.type === MessageContentType.VOICE) {
    return (
      <div className={cn(' flex items-center gap-2', className)} {...restProps}>
        <Typography lineClamp={2} as="span" weight="semibold" variant="2sm" className="text-secondary">
          Voice
        </Typography>
      </div>
    );
  }

  return (
    <div className={cn(className)} {...restProps}>
      <Typography lineClamp={2} weight="medium" className="text-secondary">
        {lastMessage.body}
      </Typography>
    </div>
  );
});
