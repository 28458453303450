import {useLayoutEffect} from 'react';

import {createScript} from '@/shared/libs/utils';

export const useRedditPixel = (id?: string) => {
  useLayoutEffect(() => {
    if (!id) return;

    const script = createScript(
      `
        !function(w,d){
          if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};
          p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js";t.async=!0;
          var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}
          (window,document);
          rdt('init', '${id}');
          rdt('track', 'PageVisit');
      `,
      'script',
      { async: true, id: 'reddit-pixel-script' },
    );

    script.mount();

    return () => {
      script.remove();
    };
  }, [id]);
};
