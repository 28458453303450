import {create} from 'zustand';

import {createSelectors} from '@/shared/libs/zustand-helpers';

type State = {
  isLoadingPage: boolean;
  isLoadingBody: boolean;
};

type Action = {
  toggleLoadingPage: (value?: boolean) => void;
  toggleLoadingBody: (value?: boolean) => void;
};

const defaultState: State = {
  isLoadingPage: false,
  isLoadingBody: false,
};

const usDiscoverPageStateBase = create<State & Action>()((set) => ({
  ...defaultState,
  toggleLoadingBody: (value) =>
    set((state) => ({
      ...state,
      isLoadingBody: value !== undefined ? value : !state.isLoadingBody,
    })),
  toggleLoadingPage: (value) =>
    set((state) => ({
      ...state,
      isLoadingPage: value !== undefined ? value : !state.isLoadingPage,
    })),
}));

export const useDiscoverPageState = createSelectors(usDiscoverPageStateBase);
