import {createFileRoute, redirect} from '@tanstack/react-router';

const availablePaths = [
  '/docs/terms_of_use',
  '/docs/privacy_policy',
  '/docs/dmca_policy',
  '/docs/warning',
  '/docs/exemption_statement',
];

export const Route = createFileRoute('/docs/$doc')({
  beforeLoad: ({ location }) => {
    if (!availablePaths.includes(location.pathname)) {
      throw redirect({ to: '/' });
    }
  },
});
