import {useMemo} from 'react';

import {queryClient} from '@/core/config/react-query';
import {LocalStorageKey, localStorageService} from '@/core/services';
import {routeTree} from '@/routeTree.gen';
import {useFeatures, useFeaturesBookStatus} from '@repo/common/services/features-book';
import {createRouter, RouterProvider} from '@tanstack/react-router';

import {AppScripts} from '@/app/app-scripts';

import {useListenInternetConnection} from '@/features/listen-internet-connection';
import {useWatchAuthStorageUpdating} from '@/features/watch-auth-storage';

import {type BrandPayloadModel, useSuspenseGetBrandConfig} from '@/entities/brand';

import {SpinnerIcon, SplashScreen} from '@/shared/ui';

export const router = createRouter({
  routeTree: routeTree,
  defaultPendingComponent: () => <SpinnerIcon className="m-auto text-primary"/>,
  context: {
    queryClient: queryClient,
    isPassOnboarding: () => localStorageService.getItem(LocalStorageKey.IsPassOnboarding, false),
    brandConfig: {} as BrandPayloadModel & {
      version: number;
    },
    featuresGetter: {} as any,
  },
  defaultPreloadStaleTime: 0,
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export const AppRouter = () => {
  const brandQuery = useSuspenseGetBrandConfig();
  const features = useFeatures();

  const status = useFeaturesBookStatus();

  const context = {
    brandConfig: useMemo(() => ({version: brandQuery.data.version, ...brandQuery.data.payload}), [brandQuery.data]),
    featuresGetter: <T, >(key: string, defaultValue?: T): T => {
      return features[key]?.value || defaultValue;
    },
  };

  useListenInternetConnection();
  useWatchAuthStorageUpdating();

  if (status !== 'success') {
    return <SplashScreen spinnerKey="heart-beat-3d"/>;
  }

  return (
    <AppScripts constants={context.brandConfig?.constants}>
      <RouterProvider router={router} context={context}></RouterProvider>
    </AppScripts>
  );
};
