import {BrandFeaturesModel} from '@/entities/brand';
import {NavItem} from '@/entities/layout';

import {Icon} from '@/shared/ui';

export const NAVIGATION_LINKS = (features: BrandFeaturesModel): NavItem[] => {
  const createAi: NavItem[] = features?.create_ai
    ? [
        {
          label: 'Create Ai',
          to: '/create-ai',
          icon: <Icon k="create" />,
        },
      ]
    : [];

  const result: NavItem[] = [
    {
      label: 'Discover',
      to: '/discover',
      icon: <Icon k="discover" />,
    },
    {
      label: 'Chats',
      to: '/conversations',
      icon: <Icon k="chats" />,
    },
    ...createAi,
    {
      label: 'Settings',
      to: '/settings',
      icon: <Icon k="settings" />,
    },
  ];
  return result;
};
