import {memo} from 'react';

import {cn} from '@/shared/libs/utils';

import {SpinnerProps} from '../types';
import './styles.css';

export const SimpleKey = 'simple';

export interface Props extends SpinnerProps {}

export const Simple = memo((props: Props) => {
  const { className, ...restProps } = props;

  return <span className={cn('simple border-t-brand-primary', className)} {...restProps}></span>;
});
