import {useMemo} from 'react';

import {useBrandConstants} from '@/entities/brand';

import {FirebaseParams, FirebaseService} from '@/shared/services/firebase';

export const useFirebase = () => {
  const constants = useBrandConstants();


  const firebaseInstance = useMemo(() => {
    const firebaseConfig: FirebaseParams = {
      apiKey: constants?.firebase_web_keys?.api_key,
      authDomain: constants?.firebase_web_keys?.auth_domain,
      projectId: constants?.firebase_web_keys?.project_id,
      storageBucket: constants?.firebase_web_keys?.storage_bucket,
      messagingSenderId: constants?.firebase_web_keys?.messaging_sender_id,
      appId: constants?.firebase_web_keys?.app_id,
      measurementId: constants?.firebase_web_keys?.measurement_id,
    };

    return new FirebaseService(firebaseConfig);
  }, [constants]);

  if (!constants?.firebase_web_keys || !Object.entries(constants?.firebase_web_keys).length) {
    return null;
  }

  return firebaseInstance;
};
