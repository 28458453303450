import {initializeApp} from 'firebase/app';
import {getMessaging, getToken} from 'firebase/messaging';

export type FirebaseParams = {
  apiKey?: string;
  authDomain?: string;
  projectId?: string;
  storageBucket?: string;
  messagingSenderId?: string;
  appId?: string;
  measurementId?: string;
};

export class FirebaseService {
  private messaging: any;


  constructor(private config: FirebaseParams) {
    this.config = config;
    this.initializeFirebase();
    this.registerServiceWorker();
  }

  public async getToken(vapidKey?: string) {
    try {
      const registration = await this.registerServiceWorker();

      if (registration.active?.state === 'activated') {
        const token = await getToken(this.messaging, {
          vapidKey: vapidKey,
          serviceWorkerRegistration: registration,
        });

        if (token) {
          return token;
        } else {
          console.log('No registration token available. Request permission to generate one.');
          return null;
        }
      }
    } catch (err) {
      console.error('An error occurred while retrieving token: ', err);
      throw err;
    }
  }

  private initializeFirebase() {
    try {
      if (!this.config.projectId) return;
      const app = initializeApp(this.config);
      this.messaging = getMessaging(app);
      console.log('Firebase initialized.');
    } catch (error) {
      console.error('Firebase initialization error: ', error);
      throw error;
    }
  }

  private async registerServiceWorker() {
    if ('serviceWorker' in navigator) {
      try {
        return await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      } catch (error) {
        console.error('Service Worker registration failed:', error);
        throw error;
      }
    } else {
      throw new Error('Service workers are not supported in this browser.');
    }
  }
}
