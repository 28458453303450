import {memo} from 'react';

import {WheelGesturesPlugin} from 'embla-carousel-wheel-gestures';

import {Character, CharacterCard} from '@/entities/character';

import {KeyboardMap} from '@/shared/constants';
import {Nullable} from '@/shared/types';
import {Carousel, CarouselOptions} from '@/shared/ui';
import {useCarousel} from '@/shared/ui/e-carousel/carousel.context';

export interface Props {
  characters: Character[];

  activeCharacterId?: Nullable<number>;

  onClickCharacter?: (character: Character) => void;
}

const _CharactersCarousel = (props: Props) => {
  const {characters, activeCharacterId, onClickCharacter} = props;

  const {carouselApi} = useCarousel();

  const handleClickSlide = (character: Character, idx: number) => {
    onClickCharacter?.(character);
    carouselApi?.scrollTo(idx);
  };

  return (
    <Carousel.SlidesContainer className="pl-5 pr-5 py-1">
      {characters.map((character, idx) => {
        const isActive = character.id === activeCharacterId;
        return (
          <Carousel.Slide
            key={character.id}
            onClick={() => handleClickSlide(character, idx)}
            onKeyDown={(event) => {
              if (event.key === KeyboardMap.Enter) {
                handleClickSlide(character, idx);
              }
            }}
          >
            <CharacterCard
              isActive={isActive}
              name={character.name}
              imgSrc={character.picture_url}
              perspective="circle"
              role="button"
              tabIndex={0}
            />
          </Carousel.Slide>
        );
      })}
    </Carousel.SlidesContainer>
  );
};

export const CharactersCarousel = memo((props: Props) => {
  const OPTIONS: CarouselOptions = {loop: false};

  const PLUGINS = [WheelGesturesPlugin()];
  return (
    <div>
      <Carousel dragFree plugins={PLUGINS} options={OPTIONS} align="center">
        <_CharactersCarousel {...props} />
      </Carousel>
    </div>
  );
});
