import {HTMLAttributes} from 'react';

import {cn} from '@/shared/libs/utils';

export interface Props extends HTMLAttributes<HTMLDivElement> {}

export const DrawerHeader = (props: Props) => {
  const { className, ...restProps } = props;

  return (
    <div
      className={cn(
        'sticky top-0 flex z-[10] bg-primary-gradient gap-2 border-b border-grey-600 p-3 basis-[--drawer-header-height] h-[--drawer-header-height]',
        className,
      )}
      {...restProps}
    />
  );
};
