import {Controller} from 'react-hook-form';

import {useEmailFormController} from '@/features/email-form/email-form-controller';

import {useGetMeQuery} from '@/entities/viewer';

import {Button, Input} from '@/shared/ui';

export interface Props {
  title?: string;

  onClick?: () => void;

  onSubmitted?: () => void;
}

export const EmailForm = (props: Props) => {
  const { title, onClick, onSubmitted } = props;

  const { data: viewer } = useGetMeQuery();

  const { control, isDisabledSendBtn, onSubmit, errors } = useEmailFormController({
    onSubmitted,
  });

  if (viewer?.email) return null;

  return (
    <form className="flex flex-col gap-6 w-full" onSubmit={onSubmit}>
      {title && <p className="text-muted-foreground text-sm font-medium text-center">{title}</p>}
      <div className="relative w-full">
        <p className="text-muted-foreground text-sm font-semibold mb-1">E-mail*</p>
        <Controller
          name="email"
          control={control}
          render={({ field }) => <Input className="pr-10" placeholder="your@email.com" {...field} />}
        />
        {errors.email && (
          <p className="absolute text-red-500 text-xs left-0 -bottom-5 text-center">{errors.email.message}</p>
        )}
      </div>

      <Button onClick={onClick} disabled={isDisabledSendBtn} className="w-full" type="submit" size="md">
        Submit
      </Button>
    </form>
  );
};
