import {HTMLAttributes, memo} from 'react';

import {cn} from '@/shared/libs/utils';
import {Typography} from '@/shared/ui';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  name: string;

  createdDate?: string;
}

export const ConversationItemInfo = memo((props: Props) => {
  const { name, createdDate, className, ...restProps } = props;

  const date = createdDate ? new Date(createdDate).toLocaleDateString([], { month: 'numeric', day: 'numeric' }) : null;

  return (
    <div className={cn('flex items-center justify-between', className)} {...restProps}>
      <Typography
        as="h5"
        variant="base"
        lineClamp={1}
        weight="semibold"
        className="text-primary-font leading-none break-all"
      >
        {name}
      </Typography>
      {date && (
        <Typography as="span" variant="2sm" weight="medium" className="leading-none text-secondary">
          {date}
        </Typography>
      )}
    </div>
  );
});
