import {type HTMLAttributes, type Ref, useEffect} from 'react';

import {cn, mergeComponents} from '@/shared/libs/utils';

import {SplashScreen} from '../splash-screen';
import {Body} from './body';
import {Header} from './header';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  innerRef?: Ref<HTMLDivElement>;

  onMount?: () => void;

  onUnmount?: () => void;

  isLoading?: boolean;
}

const SectionViewComponent = (props: Props) => {
  const { className, innerRef, isLoading, children, onUnmount, onMount, ...restProps } = props;

  useEffect(() => {
    onMount?.();
    return () => onUnmount?.();
  }, []);

  return (
    <div
      className={cn('relative section-view  overflow-hidden flex flex-col', className)}
      ref={innerRef}
      {...restProps}
    >
      {isLoading ? <SplashScreen className="absolute" spinnerKey="line-wobble" /> : children}
    </div>
  );
};

export const SectionView = mergeComponents(SectionViewComponent, {
  Header,
  Body,
});
