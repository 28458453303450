import {useCallback, useEffect, useMemo, useState} from 'react';

import {DIALOG_KEYS} from '@/core/constants';
import {AnimatePresence, motion} from 'framer-motion';

import {resetPasswordModalAction} from '@/features/modals';

import {renderFromTemplate} from '@/shared/libs/parsers';
import {randomUuid} from '@/shared/libs/utils';
import {Analytic} from '@/shared/services/analytic';
import {CardWithImage, type CloseDialogArgs, dialog, Icon, Modal, type OpenDialogArgs, Typography} from '@/shared/ui';

import {SignInForm} from '../../sign-in-form';
import {SignUpForm} from '../../sign-up-form';
import {useBrandFeatures} from "@/entities/brand";

export enum AuthType {
  LOGIN = 'LOGIN',
  SIGNUP = 'SIGNUP',
}

export interface Props {
  variant?: AuthType;

  analyticData?: any;

  imgSrc?: string;

  onSuccess?: () => void;

  onError?: () => void;
}

export const AuthModal = (props: Props) => {
  const {
    imgSrc = '/onbg-chat-about-step-bg.jpg',
    variant = AuthType.SIGNUP,
    analyticData,
    onSuccess,
    onError,
  } = props;

  const {0: authVariant, 1: setAuthVariant} = useState<AuthType>(variant);

  const handleClickSwitchAuth = useCallback(() => {
    setAuthVariant((prevState) => (prevState === AuthType.SIGNUP ? AuthType.LOGIN : AuthType.SIGNUP));
  }, []);

  const features = useBrandFeatures();

  const modalTitle = authVariant === AuthType.SIGNUP ? 'Create Account' : 'Log In';

  const description = features?.auth_modal?.description?.[authVariant] ?? null;

  const handleOnSuccess = async () => {
    onSuccess?.();
    dialog.close({key: DIALOG_KEYS.AUTH});
  };

  useEffect(() => {
    if (authVariant === AuthType.SIGNUP) Analytic.signUpScreenView(analyticData);
    if (authVariant === AuthType.LOGIN) Analytic.loginScreenView(analyticData);
  }, [authVariant]);

  const authForm = useMemo(() => {
    switch (authVariant) {
      case AuthType.LOGIN:
        return (
          <SignInForm
            onClickForgotPassword={() => resetPasswordModalAction.open()}
            analyticData={analyticData}
            onSuccess={handleOnSuccess}
            onError={onError}
          />
        );
      case AuthType.SIGNUP:
        return <SignUpForm analyticData={analyticData} onSuccess={handleOnSuccess} onError={onError}/>;
    }
  }, [authVariant]);

  const templateDescription = renderFromTemplate(
    {vars: {}, value: description?.text ?? ''},
    {
      span: (el) => (
        <Typography
          key={randomUuid()}
          weight="semibold"
          className="bg-brand-default px-2 py-1 mx-1 rounded-md text-sm text-brand-default-content flex items-center"
        >
          {description?.icon && <Icon k={description?.icon} className="text-xl pr-1"/>}
          {el}
        </Typography>
      ),
    },
  );

  return (
    <Modal.Root bodyClassName="h-full sm:h-auto grow sm:grow-0">
      <Modal.CloseBtn variant="grey" size="sm" className="absolute sm:max-w-5 top-4 left-4 sm:left-auto sm:right-4 bg-white/20"/>
      <Modal.Content className="w-screen sm:max-w-[740px] grow sm:grow-0">
        <CardWithImage
          imgSrc={features?.auth_modal?.[authVariant] ?? imgSrc}
          label={modalTitle}
          description={description ? templateDescription : null}
          className="h-full sm:h-auto"
        >
          <AnimatePresence mode="wait" initial={false}>
            <motion.div
              key={authVariant}
              initial={{transform: 'translateX(-100%)'}}
              animate={{
                transform: 'translateX(0)',
              }}
              exit={{
                transform: 'translateX(120%)',
              }}
              className="flex flex-col justify-between grow gap-4 overflow-hidden pt-4"
            >
              <div className="transition-all">{authForm}</div>
              <div className="text-center">
                <div className="bg-grey-600 h-px w-full"/>
                <Typography variant="2sm" className="text-secondary pt-3 2xs:pt-2">
                  {authVariant === AuthType.SIGNUP ? 'Already have an account yet?' : "Don't have an account yet?"}{' '}
                  <button className="text-primary-font" onClick={handleClickSwitchAuth}>
                    {authVariant === AuthType.SIGNUP ? 'Log In' : 'Sign Up'}
                  </button>
                </Typography>
              </div>
            </motion.div>
          </AnimatePresence>
        </CardWithImage>
      </Modal.Content>
    </Modal.Root>
  );
};

const openAuthModal = (props?: Props, other?: Omit<Partial<OpenDialogArgs>, 'component'>) => {
  dialog.open({
    key: DIALOG_KEYS.AUTH,
    component: <AuthModal {...(props || {})} />,
    ...other,
  });
};

const closeAuthModal = (other?: CloseDialogArgs) => {
  dialog.close({key: DIALOG_KEYS.AUTH, ...other});
};

export const authModalAction = {
  open: openAuthModal,
  close: closeAuthModal,
} as const;
