import {HTMLAttributes, memo} from 'react';

import * as Avatar from '@radix-ui/react-avatar';
import {CharacterStatus} from '@/core/constants';

import {useCharacterById} from '@/entities/character';
import {ConversationItemInfo, ConversationItemStatus, ConversationLastMessage, Message,} from '@/entities/conversation';
import {useHasSubscription} from '@/entities/viewer';

import {cn} from '@/shared/libs/utils';
import {Nullable} from '@/shared/types';
import {Badge, PremiumBadge} from '@/shared/ui';
import {useBrandFeatures} from "@/entities/brand";

export interface Props extends HTMLAttributes<HTMLDivElement> {
  status: CharacterStatus;

  characterId: number;

  lastMessage: Nullable<Message>;

  unreadCount: number;
}

export const ConversationItem = memo((props: Props) => {
  const {className, characterId, lastMessage, status, unreadCount, ...restProps} = props;

  const {character} = useCharacterById(characterId);
  const userHasSubscription = useHasSubscription();

  const features = useBrandFeatures();

  const premiumBadgeIcon =
    typeof features?.premium_badge_content !== 'string' ? features?.premium_badge_content?.icon : undefined;
  const premiumBadgeLabel =
    typeof features?.premium_badge_content === 'string' ? features?.premium_badge_content : undefined;

  const name = character?.name ?? '';

  return (
    <div
      className={cn(
        'grid w-full grid-cols-[60px_1fr] gap-2.5 px-3 py-2 relative',
        'before:absolute before:right-0 before:bottom-0 before:w-[calc(100%-82px)] before:h-[1px] before:bg-grey-600',
        className,
      )}
      {...restProps}
    >
      <Avatar.Root className="flex flex-col items-center justify-center rounded-avatar size-[60px] overflow-hidden">
        <Avatar.Image className="h-full w-full  object-cover" src={character?.picture_url ?? ''} alt={name}/>
        <Avatar.Fallback className="">{name.slice(0, 2).toUpperCase()}</Avatar.Fallback>
      </Avatar.Root>

      <div className="flex flex-col gap-1 grow h-full justify-between">
        <ConversationItemInfo name={character?.name ?? ''} createdDate={lastMessage?.created_at}/>
        <div className="flex items-center gap-2 grow shrink-0 ">
          <div className="flex items-center gap-2 grow">
            {status > CharacterStatus.ONLINE ? (
              <ConversationItemStatus status={status}/>
            ) : (
              <ConversationLastMessage hasSubscription={userHasSubscription} lastMessage={lastMessage}/>
            )}
          </div>

          <div>
            {!userHasSubscription && character?.is_premium ? (
              <PremiumBadge iconKey={premiumBadgeIcon} label={premiumBadgeLabel}/>
            ) : (
              <Badge count={unreadCount} maxCount={99}/>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
