import {useEffect, useMemo} from 'react';

import * as Sentry from '@sentry/react';

import {Analytic} from '@/shared/services/analytic';
import {Button, Typography} from '@/shared/ui';

export interface Props {
  error: unknown;
}

export const ErrorCatcher = (props: Props) => {
  const { error } = props;

  const err = useMemo(() => {
    if (error instanceof Error) {
      return error;
    }
    return new Error(String(error));
  }, [error]);

  useEffect(() => {
    if (err) {
      Analytic.somethingWentWrong(err.message);

      Sentry.captureException(err);

      if (err.message.includes('Failed to fetch dynamically imported module')) {
        window.location.reload();
      }
    }
  }, [err]);

  return (
    <div className="flex flex-col items-center justify-center h-dvh text-center">
      <div className="flex flex-col gap-3 max-w-[60vw]">
        <Typography weight="semibold">Something went wrong</Typography>
        <pre className="text-wrap text-primary-font px-1 py-1.5 rounded-md border border-grey-500 bg-grey-700">
          {err.message}
        </pre>
        <Button onClick={() => window.location.reload()}>Reload</Button>
      </div>
    </div>
  );
};
