import {HTMLAttributes} from 'react';

import {cn} from '@/shared/libs/utils';
import {SpinnerIcon} from '@/shared/ui';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  isLoading?: boolean;
}

export const Content = (props: Props) => {
  const { className, isLoading, children, ...restProps } = props;

  return (
    <div className={cn('flex flex-col grow bg-inherit shrink-0', className)} {...restProps}>
      {isLoading ? <SpinnerIcon className="m-auto text-brand-primary" /> : children}
    </div>
  );
};
