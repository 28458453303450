import {ChangeEventHandler, useCallback, useDeferredValue} from 'react';
import {useForm} from 'react-hook-form';

import {DIALOG_KEYS, SubscriptionPlan, SubscriptionTier} from '@/core/constants';
import onboardingJSON from '@/onboarding.json';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';

import {authModalAction, BuySubscriptionModal} from '@/features/modals';

import {authStorageGetters, useWithAuth} from '@/entities/auth';
import {
  CharacterAdapted,
  UpdateCharacterDto,
  UpdateCharacterSchema,
  useUpdateCharacterMutation,
  useUploadCharacterPhotoMutation,
} from '@/entities/character';
import {OnboardingStage, onboardingStageFactory} from '@/entities/onboarding';
import {useGetSubscriptionPlan} from '@/entities/viewer';

import {isBlob} from '@/shared/libs/guards';
import {Nullable} from '@/shared/types';
import {dialog} from '@/shared/ui';

const EditFormSchema = UpdateCharacterSchema.omit({ picture_url: true }).extend({
  picture_url: z.string().or(z.instanceof(Blob)),
});

export type EditFormValues = z.infer<typeof EditFormSchema>;

const onboardingStages = onboardingStageFactory.createStages(onboardingJSON.stages as OnboardingStage[]);

export const interests = onboardingStages.find(onboardingStageFactory.isBadgeStage)?.options ?? [];

export const characterOptions = onboardingStages.find(onboardingStageFactory.isSlidersStage)?.options ?? [];

const defaultCharacterValue = characterOptions.reduce((acc: UpdateCharacterDto['character'], option) => {
  acc[option.meter] = 5;
  return acc;
}, {});

const createDefaultValues = (character: Nullable<CharacterAdapted>) => {
  return {
    name: character?.name ?? '',
    age: character?.age ?? 18,
    bio: character?.bio ?? '',
    picture_url: character?.picture_url ?? '',
    interests: character?.interests?.map((interest) => interest.trim()) ?? [],
    public_pic_urls: character?.public_pic_urls ?? [],
    character: character?.character ?? defaultCharacterValue,
  };
};

export const useEditFormController = (character: Nullable<CharacterAdapted>, chatId?: string) => {
  const {
    control,
    watch,
    getValues,
    setValue,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm<EditFormValues>({
    resolver: zodResolver(EditFormSchema),
    defaultValues: createDefaultValues(character),
  });

  const subscriptionPlan = useGetSubscriptionPlan();

  const { mutateAsync: uploadPhoto, isPending: isPendingUpload } = useUploadCharacterPhotoMutation();

  const { mutateAsync: updateCharacter, isPending: isPendingUpdate } = useUpdateCharacterMutation();

  const deferredIsDirty = useDeferredValue(isDirty);

  const hasAccessToUpdate = subscriptionPlan === SubscriptionPlan.PRO_PLUS;

  const isAuth = useWithAuth();

  const isAuthenticated = authStorageGetters.getAuth().isAuthenticated;

  const handleClickInterest = (interest: string) => {
    const interests = getValues('interests');

    const newValue = interests.includes(interest) ? interests.filter((i) => i !== interest) : [...interests, interest];

    setValue('interests', newValue, { shouldDirty: true });
  };

  const onSubmitPicture: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    const file = event.target.files?.[0] ?? null;

    if (!file) return;

    setValue('picture_url', file, { shouldDirty: true });
  }, []);

  const onSubmit = handleSubmit(async (dto) => {
    if (!chatId) return Promise.reject();

    const { picture_url, ...restDto } = dto;

    let img: string;

    if (isBlob(picture_url)) {
      img = (await uploadPhoto(picture_url)).photoUrl;
    } else {
      img = picture_url;
    }

    await updateCharacter(
      { chatId, character: { ...restDto, picture_url: img } },
      {
        onSuccess: () => [reset(dto)],
      },
    );
  });

  const onOpenSubscription = (withSubmit = true) => {
    dialog.open({
      key: DIALOG_KEYS.BUY_SUBSCRIPTION,
      component: (
        <BuySubscriptionModal
          subscriptionVariant={SubscriptionTier.PRO_PLUS}
          onSuccessPayment={() => {
            withSubmit && onSubmit();
          }}
          analyticData={{ placement: 'edit', persona_id: character?.id, persona_name: character?.name }}
        />
      ),
    });
  };

  const onClickSubmitBtn = () => {
    if (hasAccessToUpdate) return;

    if (subscriptionPlan === SubscriptionPlan.BASIC && !isAuthenticated && isAuth()) {
      authModalAction.open(
        {
          onSuccess: () => onOpenSubscription(),
          analyticData: { persona_id: character?.id, persona_name: character?.name },
        },
        {
          afterClose: () => onOpenSubscription(false),
        },
      );
      return;
    }

    onOpenSubscription();
  };

  const isSubmitButtonDisabled = !isDirty || isPendingUpdate || isPendingUpload;

  const observedValues = {
    interests: watch('interests'),
    character: watch('character'),
    picture: watch('picture_url'),
  };

  return {
    control,
    observedValues,
    isDirty: deferredIsDirty,
    isSubmitButtonDisabled,
    hasAccessToUpdate,
    handleClickInterest,
    onClickSubmitBtn,
    onSubmit,
    onSubmitPicture,
  } as const;
};
