import {createEventEmitter} from '@/shared/services/event-emitter';
import {StorageService} from '@/shared/services/storage';
import type {Nullable} from '@/shared/types';

export interface IAuthStorageValue {
  token: Nullable<string>;
  sub: Nullable<string>;
  isAuthenticated: boolean;
}

interface AuthEvents {
  onAuthChange: Partial<IAuthStorageValue>;
}

const AuthStorageEventEmitter = createEventEmitter<AuthEvents>();

export enum AuthStorageKey {
  Auth = 'auth',
}

interface IAuthStorage {
  [AuthStorageKey.Auth]: IAuthStorageValue;
}

export const authStorage = new StorageService<IAuthStorage>(window.localStorage);

const defaultAuth: IAuthStorageValue = {
  token: null,
  sub: null,
  isAuthenticated: false,
};

export const authStorageGetters = {
  getAuth() {
    return authStorage.getItem(AuthStorageKey.Auth, defaultAuth);
  },
  getSub() {
    return authStorageGetters.getAuth().sub;
  },
  getToken() {
    return authStorageGetters.getAuth().token;
  },
};

export const authStorageSetters = {
  setAuth(auth: Partial<IAuthStorageValue>) {
    const currentAuth = authStorageGetters.getAuth();

    AuthStorageEventEmitter.emit('onAuthChange', auth);

    authStorage.setItem(AuthStorageKey.Auth, { ...currentAuth, ...auth });
  },
  setSub(sub: string) {
    authStorageSetters.setAuth({ sub });
  },
  setToken(token: string) {
    authStorageSetters.setAuth({ token });
  },
  clear() {
    authStorageSetters.setAuth(defaultAuth);
  },
};

export const authStorageEvents = {
  onAuthChange: (listener: (data: Partial<IAuthStorageValue>) => void) =>
    AuthStorageEventEmitter.on('onAuthChange', listener),
};
