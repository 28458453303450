import {HTMLAttributes, type ReactNode} from 'react';

import {cva, VariantProps} from 'class-variance-authority';

import {cn} from '@/shared/libs/utils';

import './styles.css';

const blurCover = cva('', {
  variants: {
    intent: {
      blur: '',
      gradient: '',
      none: '',
    },
    dropCover: {
      true: '',
    },
    blur: {
      md: '',
      lg: '',
      xl: '',
    },
  },

  defaultVariants: {
    intent: 'blur',
    dropCover: false,
    blur: 'lg',
  },
});

export interface Props extends HTMLAttributes<HTMLDivElement>, VariantProps<typeof blurCover> {
  coverProps?: Omit<HTMLAttributes<HTMLDivElement>, 'className'>;
  coverClassName?: string;
  blurContent?: ReactNode;
}

export const BlurCover = (props: Props) => {
  const {
    className,
    blur = 'md',
    intent = 'blur',
    dropCover = false,
    children,
    coverClassName,
    coverProps,
    blurContent,
    ...restProps
  } = props;

  return (
    <div className={cn('relative overflow-hidden', className)} {...restProps}>
      <div
        data-intent={intent}
        data-drop-cover={dropCover}
        data-blur={blur}
        className={cn('blur-cover', coverClassName)}
        {...coverProps}
      >
        <div />
        {blurContent && (
          <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center">
            {blurContent}
          </div>
        )}
      </div>
      {children}
    </div>
  );
};
