export enum CharacterStatus {
  UNPAID = 1,
  OFFLINE = 2,
  ONLINE = 3,
  TYPING = 4,
  RECORDING_VOICE = 5,
  SENDING_PHOTO = 6,
  RECORDING_VIDEO = 7,
}

export enum MessageContentType {
  TEXT = 1,
  VOICE = 2,
  PICTURE = 3,
}

export enum MessageSenderType {
  OUTGOING = 1,
  INCOMING = 2,
}

export enum SubscriptionTier {
  PRO = 'PRO_MONTHLY,PRO_ANNUAL',
  PRO_PLUS = 'PRO_PLUS_MONTHLY,PRO_PLUS_ANNUAL',
}

export enum SubscriptionType {
  PRO_MONTHLY = 'PRO_MONTHLY',
  PRO_ANNUAL = 'PRO_ANNUAL',
  PRO_PLUS_MONTHLY = 'PRO_PLUS_MONTHLY',
  PRO_PLUS_ANNUAL = 'PRO_PLUS_ANNUAL',
}

export enum SubscriptionLabel {
  PRO_MONTHLY = 'Premium',
  PRO_ANNUAL = 'Premium',
  PRO_PLUS_MONTHLY = 'VIP',
  PRO_PLUS_ANNUAL = 'VIP',
}

export enum SubscriptionPlan {
  BASIC = 1,
  PRO = 2,
  PRO_PLUS = 3,
}

export enum SubscriptionInterval {
  MONTH = 'month',
  YEAR = 'year',
}

export enum SocketStatusMessage {
  CONNECTED = 'connected',
  TOKE_REQUIRED = 'token required',
  AUTHENTICATED = 'authenticated',
}
