import { Controller } from 'react-hook-form';

import { useDisclosure } from '@/shared/hooks';
import { Button, Icon, Input, SpinnerIcon, Typography } from '@/shared/ui';

import { type SignUpFormControllerParams, useSignUpFormController } from './sign-up-form.controller';

export interface Props extends SignUpFormControllerParams {}

export const SignUpForm = (props: Props) => {
  const { onSuccess, onError, analyticData } = props;

  const { control, isPending, errors, onSubmit } = useSignUpFormController({
    onSuccess,
    onError,
    analyticData,
  });

  const { 0: isHiddenPass, 1: action } = useDisclosure(true);

  return (
    <form onSubmit={onSubmit} className="px-1">
      <div className="flex flex-col gap-4">
        <Controller
          control={control}
          name="email"
          render={({ field }) => {
            return (
              <Input
                label="Email"
                placeholder="Enter your email"
                autoComplete="off"
                error={errors?.email?.message}
                {...field}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="password"
          render={({ field }) => {
            return (
              <Input
                label="Password"
                placeholder="Enter your password"
                autoComplete="off"
                error={errors?.password?.message}
                type={isHiddenPass ? 'password' : 'text'}
                chevron={
                  <button type="button" className="bg-transparent" onClick={action.onToggle}>
                    <Icon k={isHiddenPass ? 'eye-hide' : 'eye-view'} />
                  </button>
                }
                {...field}
              />
            );
          }}
        />
      </div>

      <div className="pt-8 2xs:pt-4 text-center flex flex-col gap-2">
        <Button disabled={isPending}>
          <span className="inline-flex gap-2">Create Account {isPending && <SpinnerIcon />}</span>
        </Button>
        <Typography as="span" variant="xs" className="text-secondary">
          By signing up, you agree to our
          <Typography variant="xs" as="span" weight="semibold" className="pl-0.5">
            Terms of Use
          </Typography>
        </Typography>
      </div>
    </form>
  );
};

/* By signing up, you agree to our Terms of Use */
