import {Controller} from 'react-hook-form';

import {Button, Input, SpinnerIcon} from '@/shared/ui';

import {
  type ResetPasswordFormControllerParams,
  useResetPasswordFormController,
} from './reset-password-form.controller';

export interface Props extends ResetPasswordFormControllerParams {
}

export const ResetPasswordForm = (props: Props) => {
  const {onSuccess, onError} = props;

  const {control, errors, isPendingResetPassword, onSubmit} = useResetPasswordFormController({onSuccess, onError});

  return (
    <form onSubmit={onSubmit} className="px-1">
      <div className="flex flex-col gap-4">
        <Controller
          control={control}
          name="email"
          render={({field}) => {
            return (
              <Input
                label="Email"
                placeholder="Enter your email"
                autoComplete="off"
                error={errors?.email?.message}
                {...field}
              />
            );
          }}
        />
      </div>
      <div className="pt-8">
        <Button disabled={isPendingResetPassword}>
          <span className="inline-flex gap-2">Send {isPendingResetPassword && <SpinnerIcon/>}</span>
        </Button>
      </div>
    </form>
  );
};
